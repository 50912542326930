import css from "./what_brings_luck_list.module.css";
import {
  FortuneTodayUnseMenu,
  FortuneTodayUnseCategory,
} from "@Network/request/api_get_fortune_today_unse";
import { Grid, Box } from "@mui/material";
import { ReactElement } from "react";

function Keyword(text: string, key: number): ReactElement {
  return (
    <Grid item key={key}>
      <Box className={`${css.grid_item}`}>{text}</Box>
    </Grid>
  );
}

const WhatBringsLuckList = (menu: FortuneTodayUnseMenu) => {
  const rawList: [FortuneTodayUnseCategory | undefined, number][] = [
    [menu.category1, 1],
    [menu.category2, 2],
    [menu.category3, 3],
    [menu.category4, 4],
    [menu.category5, 5],
    [menu.category6, 6],
  ];

  const lucksList = rawList
    .filter((cat) => cat[0] !== undefined)
    .map(function mapping(cat): [string, number] {
      return [cat[0]!.depth1.text, cat[1]];
    });

  return (
    <div className={`${css.fill_width}`}>
      <div className={`${css.v_stack}`}>
        <span className={`${css.headline}`}>{menu.title}</span>
        <Grid container rowSpacing="10px" columnSpacing="10px">
          {lucksList.map((luck) => Keyword(luck[0], luck[1]))}
        </Grid>
      </div>
    </div>
  );
};

export default WhatBringsLuckList;
