import {
  JeomsinRequest,
  JeomsinDomain,
  JeomsinEndPoint,
  RequestProps,
  JeomsinMethod,
} from "@Network/network";

interface Talisman {
  page_code: string;
  t_title: string;
  t_content: string;
  t_shop_url: string;
  t_img: string;
  v_title1: string;
  v_id1: string;
  v_img1: string;
  v_title2: string;
  v_id2: string;
  v_img2: string;
}

interface Response {
  talisman_list: Talisman[];
  result: boolean;
  TTL: string;
}

const apiGetTalismanPage = async (): Promise<Response | undefined> => {
  let request: RequestProps = {
    method: JeomsinMethod.Post,
    domain: JeomsinDomain.Jeomsin,
    endpoint: JeomsinEndPoint.GetTalismanPage,
    params: {},
  };

  try {
    const inferred = (await JeomsinRequest(request)) as Response;
    return inferred;
  } catch (e) {
    return undefined;
  }
};

export default apiGetTalismanPage;
export type { Response as TalismanPageResponse, Talisman };
