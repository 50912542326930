import { JeomsinMethod, JeomsinDomain, JeomsinEndPoint, JeomsinEndPointType, JeomsinReturnValueType } from "@Network/network";
import { XMLParser } from 'fast-xml-parser';

var debugMode:boolean = true;
type RequestProps = {
    method?:JeomsinMethod,
    domain:JeomsinDomain,
    endpoint:JeomsinEndPoint,
    params?:any
}

async function JeomsinRequest( props:RequestProps ) {
    if(props.method === JeomsinMethod.Get) {
        return Get(props)
    } else {
        return Post(props);
    }
};

const Get = ( props:RequestProps ) => {
    console.log("JeomsinRequest Get start");
    var domain:string|undefined; 
    
    if(debugMode) {
        domain = props.domain.debug;
    }else {
        domain = props.domain.release;
    }

    domain += props.domain.value;

    var endPoint:string = props.endpoint.value; 
    var params:string|undefined;

    if(props.endpoint.type === JeomsinEndPointType.Param) {
        params = "?" + props.endpoint.paramKey + "=" + props.endpoint.paramValue;
    }

    Object.entries(props.params).forEach(([key, value]) => {
        var connnector = (params === undefined) ? "?" : "&"
        params += connnector + key + "=" + value;
    });

    var url = domain + endPoint + params;
    console.log("get url : " + url);
    fetch(url, {
        method: JeomsinMethod.Get,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    ).then(
        (response) => response.json()
    );
}

const Post = (props:RequestProps) => {
    console.log("JeomsinRequest Post start");
    var domain:string|undefined; 
    
    if(debugMode) {
        domain = props.domain.debug;
    }else {
        domain = props.domain.release;
    }

    domain += props.domain.value;

    var endPoint:string = props.endpoint.value; 

    if(props.endpoint.type === JeomsinEndPointType.Param) {
        props.params[props.endpoint.paramKey] = props.endpoint.paramValue;
    }

    var url = domain + endPoint;
    console.log("post url : " + url);
    console.log("post props.params : " + JSON.stringify(props.params));
    return new Promise((resolve, reject) => { 
        fetch(url, {
            method: JeomsinMethod.Post,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: new URLSearchParams(props.params),
        }
        ).then(
            async response => {
                if(!response.ok) {
                    throw Error("could not fetch the data that resource");
                }

                if(props.endpoint.returnValueType == JeomsinReturnValueType.XML) {
                    const xmlText = await response.text();
                    const parser = new XMLParser();
                    const jsonObj = parser.parse(xmlText);
                    return jsonObj;
                } else{
                    return response.json();
                }
            }
        ).then(data => {
            console.log("data : " + JSON.stringify(data));
            resolve(data);

        }).catch(error => {
            console.log("error msg : " + error);
            reject(error);
        });
    });
}

const xmlToJson = (response:string) => {
    const parser = new XMLParser();
    const jsonObj = parser.parse(response);

    return "";
}

export { JeomsinRequest };
export type { 
    RequestProps 
};
