import { Line } from "react-chartjs-2";
import { ScriptableContext } from "chart.js";
import { FortuneTodayNumResponse } from "@Network/request/api_get_fortune_today_num";
import css from "./custom_line_chart.module.css";
import "@CSS/common.css";

const lineChartData = (labels: string[], data: number[]) => {
  return {
    labels: labels,
    datasets: [
      {
        label: "Steps",
        data: data,
        borderColor: "#21CDD3",
        borderWidth: 2,
        fill: "start",
        tension: 0.4,
        backgroundColor: (ctx: ScriptableContext<"line">) => {
          const canvas = ctx.chart.ctx;
          const gradient = canvas.createLinearGradient(0, -160, 0, 120);

          gradient.addColorStop(0, "rgba(26, 212, 221, 1)");
          gradient.addColorStop(0.5, "rgba(26, 212, 221, 0.56)");
          gradient.addColorStop(1, "rgba(26, 212, 221, 0)");

          return gradient;
        },
        pointBackgroundColor: "#fff",
        pointBorderColor: "rgba(75,192,192,1)",
        pointRadius: (ctx: ScriptableContext<"line">) => {
          // Only show the radius for the middle point
          const totalPoints = ctx.chart.data.datasets[0].data.length;
          const middleIndex = Math.floor(totalPoints / 2);
          return ctx.dataIndex === middleIndex ? 5 : 0;
        },
      },
    ],
  };
};

const CustomLineChart = (numInfo: FortuneTodayNumResponse) => {
  const data = [
    numInfo.drmwork_data.menu1.num,
    numInfo.drmwork_data.menu2.num,
    numInfo.drmwork_data.menu3.num,
    numInfo.drmwork_data.menu4.num,
    numInfo.drmwork_data.menu5.num,
  ];
  const labels = ["그제", "어제", "오늘", "내일", "모레"];
  const options = {
    responsive: true,
    scales: {
      x: {
        border: {
          display: false,
        },
        grid: {
          borderColor: "transparent",
          color: "transparent",
        },
        ticks: {
          display: false,
        },
      },
      y: {
        display: false,
        suggestedMin: 0,
        suggestedMax: 120,
        ticks: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return (
    <div className={`${css.vertical_stack}`}>
      <div
        className={`${css.absolute_positioning}`}
        style={{
          top: `${(1 - data[2] / 120) * 100 - 18}%`,
        }}
      >
        <div className={`${css.z_stack_container}`}>
          <img
            className={`${css.adjustable_image}`}
            src="images/fortune_report/3.0x/report_today_unse_chart_bubble.png"
            alt="bubble"
          />
          <span className={`ts11 bold8 color-white ${css.text_on_top}`}>
            {data[2]}
          </span>
        </div>
      </div>
      <Line options={options} data={lineChartData(labels, data)} />
      <div className={`${css.horizontal_stack}`}>
        {labels.map((text) => (
          <span key={text} className={`ts11 ${css.teal_colored}`}>
            {text}
          </span>
        ))}
      </div>
    </div>
  );
};

export default CustomLineChart;
