import CookieKeys from "@Storage/cookie_keys";
import CookieManager from "@Storage/cookie_manager";
import { UserInfo } from "@Storage/data_types";

const FontScales = {
  big: 1.2,
  medium: 1,
  small: 0.8,
} as const;

type FontScale = keyof typeof FontScales;

function getCurrentFontScale(): FontScale {
  const scaleString = document.documentElement.style.getPropertyValue("--font-scale");
  const enumeratable: [FontScale, number][] = [["big", FontScales.big], ["medium", FontScales.medium], ["small", FontScales.small]];
  const result = enumeratable.filter((check) => check[1] === Number(scaleString)).at(0);

  if (result) {
    return result[0];
  } else {
    return "medium";
  }
}

function initializeFontScale() {
  const scale = CookieManager.GetString(CookieKeys.fontScale);
  if (scale) {
    document.documentElement.style.setProperty("--font-scale", scale);
  }
}

function setFontScale(fontScale: FontScale) {
  const scale = FontScales[fontScale];
  document.documentElement.style.setProperty("--font-scale", scale.toString());
  CookieManager.SetString(CookieKeys.fontScale, scale.toString());
}

function getToday() {
  const now = new Date();
  // Remember, Month is zero-indexed.
  return `${now.getFullYear()}-${now.getMonth() + 1}-${now.getDate()}`;
}

function getMyCalendar(userInfo: UserInfo): string {
  return userInfo.calendarType === 0 ? "0" : "1";
}

function getMyLeap(userInfo: UserInfo): string {
  return userInfo.calendarType === 2 ? "1" : "0";
}

export type { FontScale };

export {
  getMyCalendar,
  getMyLeap,
  getToday,
  initializeFontScale,
  setFontScale,
  FontScales,
  getCurrentFontScale,
};
