import {
  JeomsinRequest,
  JeomsinDomain,
  JeomsinEndPoint,
  RequestProps,
  JeomsinMethod,
} from "@Network/network";

interface Response {
  info: CounselorInfo;
  counselor_list: Counselor[];
  result: boolean;
  TTL: string;
}

export interface CounselorInfo {
  mem_nick: string;
  use_count: string;
  coupon_check_state: boolean;
  is_tab_mental_new: string;
}

export interface Counselor {
  idx: number;
  MENU_ID: number;
  CO_NO: number;
  CO_Name: string;
  CO_Url: string;
  CO_History: string;
  CO_Info: string;
  ARS_NO: string;
  ARS_NO2: string;
  yn_freecall: string;
  freecall_cnt: string,
  conn_state: number;
  CO_Field: string;
  recomm: string;
  yn_popular: boolean,
  price: number,
  CO_badge: number;
  CO_gen: string;
  rv_cnt: number;
  r_cnt: number,
  yn_new: string;
  push_state: string;
  coupon_time: string;
  yn_favorite: string;
  download_able: string;
  coupon_point: string;
  counselor_coupon_no: string;
  coupon_s_date: string;
  coupon_e_date: string;
  coupon_name: string;
  rv_avg: string,
  score1: number;
  score2: number;
  score3: number;
  score4: number;
  score5: number;
  rv_avg_v2: string,
  total_counseling_cnt: number;
  total_time: string;
  re_counseling_rate: string;
  ars_record_sample_url: string;
  ars_greeting: string;
}

const apiGetCounselorNewV3 = async (
  page: number,
  version_type: number, // 0 for pro & 1 for non-pro
  yn_popular: boolean,
  device_id: string
): Promise<Response | undefined> => {
  let request: RequestProps = {
    method: JeomsinMethod.Post,
    domain: JeomsinDomain.Jeomsin,
    endpoint: JeomsinEndPoint.GetCounselorNewV3,
    params: {
      page: page,
      type: version_type,
      yn_popular: yn_popular ? "Y" : "N",
      device_id: device_id,
    },
  };

  try {
    const inferred = (await JeomsinRequest(request)) as Response;
    return inferred;
  } catch (e) {
    return undefined;
  }
};

export default apiGetCounselorNewV3;
