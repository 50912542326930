import useSharedInfoStore from "@Network/shared_info";
import { Talisman } from "@Network/request/api_get_talisman_page";
import { ReactElement } from "react";
import css from "./bujeok_view.module.css";

const BujeokView = (pageCode: string = "A20320") => {
  const { talismanPageResponse } = useSharedInfoStore();

  const talismanList = talismanPageResponse?.talisman_list ?? [];
  const bujeok = talismanList
    .filter((talisman) => talisman.page_code === pageCode)
    .at(0);

  function bujeokSalesView(bujeok: Talisman | undefined): ReactElement {
    if (bujeok) {
      return (
        <div className={css.inner_bujeock_h_stack}>
          <img
            className={css.image_height_adjust}
            src={bujeok.t_img}
            alt="Bujeok"
          />
          <div className={css.outer_shell}>
            <div className={css.spaced_v_stack}>
              <div className={css.bujeok_title_and_description_v_stack}>
                <span className={css.product_text}>{bujeok.t_title}</span>
                <span className={css.description_text}>{bujeok.t_content}</span>
              </div>
              <div className={css.puchase_button_container}>
                <div className={css.purchase_button}>
                  <span>구매하기</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return <></>;
    }
  }

  return (
    <div className={css.outer_v_stack}>
      <div className={css.inner_suggestion_v_stack}>
        <span className={`${css.less_text}`}>지금 내 상황에 꼭 필요한</span>
        <div className={css.relative_positioned}>
          <div className={css.yellow_and_round} />
          <div className={css.above}>
            <span className={`${css.bolded} ${css.accentuated}`}>
              점신 부적
            </span>
            <span className={`${css.accentuated} ${css.slighted_bolded}`}>
              은?
            </span>
          </div>
        </div>
      </div>
      {bujeokSalesView(bujeok)}
    </div>
  );
};

export default BujeokView;
