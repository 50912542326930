import css from "./coordi_fashion_advice.module.css";
import { FortuneTodayUnseMenu } from "@Network/request/api_get_fortune_today_unse";

const CoordiFashionAdvice = (menu: FortuneTodayUnseMenu) => {
  const headline = menu.title;
  const title = menu.category1.depth1.headcopy ?? "";
  const description = menu.category1.depth1.text;

  return (
    <div className={`${css.v_stack} ${css.gap_24} ${css.padded}`}>
      <span className={`${css.headline}`}>{headline}</span>
      <div className={`${css.v_stack} ${css.gap_16}`}>
        <span className={`${css.title}`}>{title}</span>
        <span className={`${css.description}`}>{description}</span>
      </div>
    </div>
  );
};

export default CoordiFashionAdvice;
