import { FortuneTodayUnseDepth } from "@Network/request/api_get_fortune_today_unse";
import css from "./title_and_description.module.css";

const TitleAndDescription = (depth: FortuneTodayUnseDepth) => {
  return (
    <div className={`${css.v_stack}`}>
      <span className={`${css.title}`}>{depth.title}</span>
      <span className={`${css.description}`}>{depth.text}</span>
    </div>
  );
};

export default TitleAndDescription;
