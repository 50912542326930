import "../css/saju_analyzing.css";
import "@CSS/common.css";
import SajuAnalyzingCard from "./saju_analyzing_card";
import { ContentType } from "./content_type";
import { Stack, Button } from "@mui/material";
import React, { FC, ReactElement, useEffect, useState } from "react";
import {
  JeomsinBottomNavigation,
  BottomNavigationType,
  BottomNavigationProps,
  JeomsinPageType,
  HeaderProps,
} from "@Components/compose";

import SajuAnalyzingLoadingBubble from "./saju_analyzing_loading_bubble";
import apiGetSajuLoadingText from "@Network/request/api_get_saju_loading_text";
import apiGetTodayVisit from "@Network/request/api_get_today_visit";
import {
  apiRequestCoupangAdReco,
  CoupangAdRecoResponse,
} from "@Network/request/api_request_coupang_ad_reco";
import CookieManager from "@Storage/cookie_manager";
import CookieKeys from "@Storage/cookie_keys";
import { UserInfo } from "@Storage/data_types";
import { useLocation, useNavigate } from "react-router";
import { JeomsinPagePath } from "@Routes/PageRoutes";
import {
  checkAdStatus,
  AdStatus,
  userRejectedCoupangAd,
  removeCoupangRejectionSavedTime,
  removeCoupangAcceptSavedTime,
  userAcceptedCoupangAd,
} from "@Storage/coupang_ad_time_manager";

enum SajuAnaylsisViewCase {
  showCoupangAd,
  showGenericAds,
  showNoAd,
}

const numberWithCommas = (x: number) =>
  x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

const SajuAnalyzingAds = (
  index: number,
  viewCase: SajuAnaylsisViewCase,
  adData: CoupangAdRecoResponse,
  closeButtonTimer: number = 0
) => {
  switch (viewCase ?? SajuAnaylsisViewCase.showCoupangAd) {
    case SajuAnaylsisViewCase.showCoupangAd:
      return (
        <SajuAnalyzingCard
          index={index}
          contentType={ContentType.coupangAd}
          content={adData.data.productImage}
          closeButtonClicked={() => {
            userRejectedCoupangAd();
            window.history.go(-2); // TODO: Replace the logic to check the history before going back
          }}
          adClicked={() => {
            window.open(adData.data.productUrl);
          }}
          closeButtonTimer={closeButtonTimer}
        />
      );
    case SajuAnaylsisViewCase.showGenericAds:
      return (
        <>
          <SajuAnalyzingCard
            index={index}
            contentType={ContentType.ad320x100}
          />
          <SajuAnalyzingCard
            index={index + 100}
            contentType={ContentType.ad320x250}
          />
        </>
      );
    case SajuAnaylsisViewCase.showNoAd:
      return (
        <SajuAnalyzingCard
          index={index}
          contentType={ContentType.adFreeBanner}
        />
      );
  }
};

interface SajuAnalyzingProps {
  headerType: React.Dispatch<React.SetStateAction<HeaderProps>>;
  bottomNavParam: React.Dispatch<React.SetStateAction<BottomNavigationProps>>;
  viewCase?: SajuAnaylsisViewCase;
}

// background: var(--Point-point-color-01, #FFF854);

const SajuAnalyzing: FC<SajuAnalyzingProps> = ({
  headerType,
  bottomNavParam,
}) => {
  const navigate = useNavigate();
  const userInfo: UserInfo = useLocation().state;
  const [data, setData] = useState<
    ([ContentType, ReactElement] | ReactElement)[]
  >([]);
  const [closeButtonTimer, setCloseButtonTimer] = useState<number>(5);

  useEffect(() => {
    headerType({ type: JeomsinPageType.None });
    bottomNavParam({ type: BottomNavigationType.None });
  }, [headerType, bottomNavParam]);

  const [cardDisplayIndex, setCardDisplayIndex] = useState(0);
  const [progression, setProgression] = useState(0);

  useEffect(() => {
    if (data.length === 0) {
      return;
    }

    const timer = setInterval(() => {
      if (progression >= 100) {
        return;
      }

      setProgression((progress) => progress + 1);
    }, 50);

    return () => {
      clearInterval(timer);
    };
  }, [data.length, progression]);

  useEffect(() => {
    if (data.length === 0) {
      return;
    }

    const timer = setInterval(() => {
      if (cardDisplayIndex >= data.length) {
        return;
      }

      setCardDisplayIndex((index) => index + 1);
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, [cardDisplayIndex, data.length]);

  useEffect(() => {
    if (data.length === 0) {
      return;
    }

    const timer = setInterval(() => {
      if (closeButtonTimer < 0) {
        return;
      }

      setCloseButtonTimer((leftTime) => leftTime - 1);
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [data.length, closeButtonTimer]);

  const isInitial = cardDisplayIndex === 0;
  const isEnd = cardDisplayIndex === data.length && data.length > 0;

  const [tipMessage, setTipMessage] = useState("");
  const [adData, setAdData] = useState<CoupangAdRecoResponse | undefined>();
  const [visits, setVisits] = useState<string | undefined>();

  useEffect(() => {
    const initializeData = async () => {
      const res = await apiGetSajuLoadingText();
      const tipMessage = (res?.content ?? "")
        .replaceAll("@comma", ",")
        .replaceAll("@double", '"');
      const visitNumber = await apiGetTodayVisit();
      // TODO: ***** 쿠팡 광고 잠시 꺼둠. 라이브하기 전 꼭 다시 킬것! *****
      const turnOnCoupandAd = false;
      const adData = turnOnCoupandAd
        ? await apiRequestCoupangAdReco()
        : {
            rCode: "",
            rMessage: "",
            data: {
              productId: 0,
              productName: "테스트",
              productPrice: 0,
              productImage: "img/coupang_placeholder_320x320.png",
              productUrl: "https://www.google.com",
              categoryName: "테스트",
              isRocket: false,
              isFreeShipping: false,
            },
          };

      let visits = "";
      if (visitNumber) {
        visits = numberWithCommas(visitNumber);
      }

      setTipMessage(tipMessage);
      setAdData(adData);
      setVisits(visits);
    };

    initializeData();
  }, []);

  useEffect(() => {
    const initializeVisitsAndTipMessage = async () => {
      let displayables: ([ContentType, ReactElement] | ReactElement)[] = [
        <></>,
        [
          ContentType.message,
          <span>
            오늘 <span className="bold7">{visits}</span>명이 운세를 확인했어요!
          </span>,
        ],
        [ContentType.tip, <span>{tipMessage}</span>],
        [
          ContentType.message,
          checkAdStatus() === AdStatus.showCoupangAd ? (
            <span>
              이 포스팅은 쿠팡 파트너스 활동의 일환으로 이에 따른 일정액의
              수수료를 제공받습니다.
              <br />
              상품보기를 원치 않으시면 'X'버튼을 눌러주세요.
            </span>
          ) : (
            <span>사주분석이 완료되었습니다.😘</span>
          ),
        ],
      ];

      if (adData) {
        switch (checkAdStatus()) {
          case AdStatus.showCoupangAd:
            displayables[0] = SajuAnalyzingAds(
              0,
              SajuAnaylsisViewCase.showCoupangAd,
              adData,
              closeButtonTimer
            );
            break;
          case AdStatus.showNetworkAd:
            displayables[0] = SajuAnalyzingAds(
              0,
              SajuAnaylsisViewCase.showGenericAds,
              adData
            );
            break;
          case AdStatus.showDetailsWithoutInterstitialAd:
            displayables[0] = SajuAnalyzingAds(
              0,
              SajuAnaylsisViewCase.showGenericAds,
              adData
            );
            break;
          case AdStatus.adFree:
            break;
        }
      }

      setData(displayables);
    };

    initializeVisitsAndTipMessage();
  }, [tipMessage, adData, visits, closeButtonTimer]);

  const goToResultViewButton = () => {
    return (
      <div className="sj_result_card footer">
        {progression >= 100 ? (
          <div
            className="sj_see_result sj_progression_background"
            onClick={() => {
              if (checkAdStatus() === AdStatus.showCoupangAd) {
                const productURL = adData?.data.productUrl;
                if (productURL) {
                  window.open(productURL);
                }

                userAcceptedCoupangAd();
              }

              navigate(JeomsinPagePath.TodayUnse, {
                state: userInfo,
              });
            }}
          >
            <div className="sj_growable" />
            <span>
              {checkAdStatus() === AdStatus.showCoupangAd ? (
                <>
                  <span className="bold4">쿠팡 방문하고 </span>
                  <span>결과보기</span>
                </>
              ) : (
                "결과보기"
              )}
            </span>
            <div className="sj_growable" />
          </div>
        ) : (
          <div className="sj_progression_wrapper">
            <div
              className="sj_progression_background"
              style={{
                width: `${progression}%`,
              }}
            />
            <div className="sj_progression_title">
              <div className="sj_growable" />
              <span className="bold4 ts15">
                <span className="bold6">운세</span>를 풀이하고 있어요 ...{" "}
                {`${progression}`}
              </span>
              <div className="sj_growable" />
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className={`sj_centered_wrapper jeomsin-bgcolor-default-background`}>
      <Stack
        className={`ts13 relative-position sj_main_content`}
        direction="column"
        spacing="15px"
      >
        <div className="sj_header">
          <div className="sj_growable" />
          <span className="ts17">
            <b>{userInfo.name}</b>님의 사주분석이 완료되었어요.
          </span>
          <div className="sj_growable" />
        </div>
        {data.slice(0, cardDisplayIndex).map((eachContent, index) => {
          if (eachContent instanceof Array) {
            return (
              <SajuAnalyzingCard
                index={index}
                contentType={eachContent[0]}
                content={eachContent[1]}
              />
            );
          } else {
            return eachContent;
          }
        })}

        {isInitial ? <div className="growable" /> : <></>}
        {SajuAnalyzingLoadingBubble(isInitial, isEnd)}
        {isInitial ? <div className="growable" /> : <></>}
      </Stack>
      {goToResultViewButton()}
    </div>
  );
};

export default SajuAnalyzing;
