import { Stack, Box } from "@mui/material";
import { JeomsinPageUIProps } from "@Routes/PageRoutes";
import { useEffect, useState } from "react";
import { BottomNavigationType, JeomsinPageType } from "@Components/compose";
import { useLocation } from "react-router-dom";
import { SajuMyungsikInfo } from "@Storage/data_model/saju_myungsik_data";
import css from "../css/destiny_chart_specifics_interpretation.module.css";
import { AdStatus, checkAdStatus } from "@Storage/coupang_ad_time_manager";
import { BottomButton } from "@BasicComponents/basic";

const DestinyChartSpecificsInterpretation = ({
  headerType,
  bottomNavParam,
}: JeomsinPageUIProps) => {
  const info:SajuMyungsikInfo = useLocation().state;
  const [isScrollable, setIsScrollable] = useState(checkAdStatus() === AdStatus.showDetailsWithoutInterstitialAd);

  useEffect(() => {
    headerType({type: JeomsinPageType.Sub, title:info.detailTitle});
    bottomNavParam({ type: BottomNavigationType.None });
  }, [bottomNavParam, headerType]);

  return (
    <>
      <Box className={`${css.detail_wrap} header-type-sub pr20 pl20 jeomsin-bgcolor-default-background ${isScrollable ? "" : css.prevent_scroll}`}>
        <Stack spacing="30px">
          <Stack
            className="p30 bgcolor-white"
            sx={{
              borderRadius: "20px",
              boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.1)",
            }}
            spacing="40px"
          >
            <Stack spacing="10px">
              <span className="ts17 bold6">단어 뜻</span>
              <span className="ts14">{info.description ?? ""}</span>
            </Stack>
            <Stack spacing="10px">
              <span className="ts17 bold6">특징</span>
              <span className="ts14">
                {info.characteristics ?? ""}
              </span>
            </Stack>
          </Stack>

          <Box className="pl20 pr20">
            <Stack direction="column" spacing="20px">
              <span className="ts20">
                <Stack direction="row" spacing="4px">
                  <span className="bold6">상세</span>
                  <span>해석</span>
                </Stack>
              </span>
              <span className={`${css.detail_text} "ts14"`}>{info.detailResult ?? ""}</span>
            </Stack>
          </Box>
        </Stack>
      </Box>
      {
        <div className={`${css.fill_width} ${isScrollable ? css.invisible : css.visible}`}>
          <BottomButton title="결과보기" onClick={() => {
            // TODO: Show interstitial ads
            setIsScrollable(true);
          }} />
        </div>
      }
    </>
  );
};

export default DestinyChartSpecificsInterpretation;
