// images/saju_analyzing/3.0x/bubble_first_loading.png
// images/saju_analyzing/3.0x/bubble_normal_loading.png

import { useEffect, useState } from "react";
import css from "../css/saju_analyzing_loading_bubble.module.css";

const SajuAnalyzingLoadingBubble = (isFirst: boolean, hidden: boolean) => {
  const [progressionIndex, setProgressionIndex] = useState(0);

  const indicatorImage = () => {
    if (isFirst) {
      return (
        <img
          className={`${css.force_downsize_image}`}
          src="images/saju_analyzing/3.0x/bubble_first_loading.png"
          alt="loading indicator"
        />
      );
    } else {
      return (
        <img
          className={`${css.force_downsize_image}`}
          src="images/saju_analyzing/3.0x/bubble_normal_loading.png"
          alt="loading indicator"
        />
      );
    }
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setProgressionIndex((index) => {
        return (index + 1) % 3;
      });
    }, 150);

    return () => {
      clearInterval(timer);
    };
  }, [progressionIndex]);

  const indicator = (index: number, equivalency: number) => (
    <div
      className={`${
        index === equivalency
          ? css.indicator_significant
          : css.indicator_insignificant
      }`}
    />
  );

  return (
    <div className={`${css.centered_wrapper} ${hidden ? css.is_hidden : ""}`}>
      <div className={`${css.sized_wrapper}`}>
        {indicatorImage()}
        <div className={`${css.indicator_container}`}>
          <div className={`${css.indicator_stack}`}>
            {indicator(progressionIndex, 0)}
            {indicator(progressionIndex, 1)}
            {indicator(progressionIndex, 2)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SajuAnalyzingLoadingBubble;
