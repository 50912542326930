import {
  JeomsinRequest,
  JeomsinDomain,
  JeomsinEndPoint,
  RequestProps,
  JeomsinMethod,
} from "@Network/network";

interface Response {
  today_visit: number;
  result: boolean;
  TTL: string;
}

const apiGetTodayVisit = async (
  pageKind?: number
): Promise<number | undefined> => {
  const request: RequestProps = {
    method: JeomsinMethod.Post,
    domain: JeomsinDomain.Jeomsin,
    endpoint: JeomsinEndPoint.GetTodayVisit,
    params: {},
  };

  const response: any = await JeomsinRequest(request);

  try {
    const inferred = response as Response;
    return inferred.today_visit;
  } catch (e) {
    return undefined;
  }
};

export default apiGetTodayVisit;
