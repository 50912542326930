import { Appbar, AppbarRightType, TitleBar, TitlebarType, } from "@BasicComponents/basic";
import { Box } from '@mui/material';

export type HeaderProps = {
    type: JeomsinPageType;
    title?: string;
    pageBackEvent?: () => void;
    fontSizeButtonPressed?: () => void;
    shareButtonPressed?: () => void;
}

const JeomsinHeader:React.FC<HeaderProps>  = ( props ) => {

    let headerInfo = getPageHeaderInfo(props.type, props.title);
    console.log(`let me see ${props.fontSizeButtonPressed}`);

    return (
        <Box>
            <Appbar showAppbar={headerInfo.showAppbar} appbarRightType={headerInfo.appbarRightType}></Appbar>
            <TitleBar type={headerInfo.titlebarType} title={headerInfo.title} pageBackEvent={props.pageBackEvent} fontSizeButtonPressed={props.fontSizeButtonPressed} shareButtonPressed={props.shareButtonPressed}></TitleBar>
        </Box>
    );
}

interface IAppbarBarInfoProp {
    showAppbar : Boolean;
    appbarRightType : AppbarRightType;
    titlebarType : TitlebarType;
    title : string;
}

// Page 추가 시 작업
enum JeomsinPageType {
    Home,
    Fortune,
    SajuInput,
    Sub,
    UnseResult,
    None
}

// Page 추가 시 PageInfo 추가 추가
const getPageHeaderInfo = (type:JeomsinPageType, titleText?:string) => {
    let result: IAppbarBarInfoProp | null;

    switch(type){
        case JeomsinPageType.Fortune:
            result = { showAppbar:true, appbarRightType:AppbarRightType.Fortune,  titlebarType:TitlebarType.Fortune, title: "운세" };
            break;
        case JeomsinPageType.SajuInput:
            result = { showAppbar:false, appbarRightType:AppbarRightType.None,  titlebarType:TitlebarType.SubPageNoButton, title: "점신 시작하기" };
            break;
        case JeomsinPageType.Sub:
            result = { showAppbar:false, appbarRightType:AppbarRightType.None,  titlebarType:TitlebarType.SubPageNoButton, title: titleText ?? "" };
            break;
        case JeomsinPageType.UnseResult:
            result = { showAppbar:false, appbarRightType:AppbarRightType.None,  titlebarType:TitlebarType.SubPageTwoButton, title: titleText ?? "" };
            break;
        case JeomsinPageType.None:
        default:
            result = { showAppbar:false, appbarRightType:AppbarRightType.None,  titlebarType:TitlebarType.None, title: "" };
            break;
    }

    return result;
}

export {
    JeomsinHeader,
    JeomsinPageType
};