import {
  JeomsinRequest,
  JeomsinDomain,
  JeomsinEndPoint,
  RequestProps,
  JeomsinMethod,
} from "@Network/network";

interface SajuLoadingText {
  id: string;
  category: string;
  content: string;
}

interface Response {
  saju_loading_text: SajuLoadingText;
  result: boolean;
  TTL: string;
}

const apiGetSajuLoadingText = async (
  pageKind?: number
): Promise<SajuLoadingText | undefined> => {
  let request: RequestProps = {
    method: JeomsinMethod.Post,
    domain: JeomsinDomain.Jeomsin,
    endpoint: JeomsinEndPoint.GetSajuLoadingText,
  };

  if (pageKind) {
    request.params = {
      kind: pageKind,
    };
  } else {
    request.params = {};
  }

  const response: any = await JeomsinRequest(request);

  try {
    const inferred = response as Response;
    return inferred.saju_loading_text;
  } catch (e) {
    return undefined;
  }
};

export default apiGetSajuLoadingText;
