import { JeomsinRequest, JeomsinDomain, JeomsinEndPoint } from "@Network/network";
import { UserInfo } from "@Storage/data_types";

const apiInfoNew = async (props:UserInfo) => {
  const response:any = await JeomsinRequest({
    domain: JeomsinDomain.JeomsinXml,
    endpoint: JeomsinEndPoint.InfoNew,
    params: {
      cate:2,
      myname:props.name,
      myyear:props.birthYear,
      mymonth:props.birthMonth,
      myday:props.birthDay,
      myhour:props.birthTime,
      btime:props.birthMinute,
      mycalendar:props.calendarType,
      myleap:props.isLeapMonth ? '1' : '0',
      mygender:props.gender
    },
  });

  console.log("apiInfoNew response : " + JSON.stringify(response));
  
  if(response) {
    return response;
  } else {
    return false;
  }

}

export default apiInfoNew;
// const apiGetTodayVisit = async (
//     pageKind?: number
//   ): Promise<number | undefined> => {
// cate	2	
// myname	홍길동	이름
// myyear	1988	출생년
// mymonth	11	출생월
// myday	15	출생일
// myhour	13	출생시
// btime	30	출생분
// mycalendar	0 : 양력 / 1 : 음력	양/음력
// myleap	0 : 평달 / 1 : 윤달	평/윤달
// mygender	0 : 남성 / 1 : 여성	성별
// wedding	0 : 미혼 / 1 : 기혼	미/기혼

// {"?xml":"","drmwork_data":{"menu":{"title":"사주 속 기본 능력","depth1":{"title":"천간","num1":8,"ohang1":4,"text1":"壬","num2":8,"ohang2":4,"text2":"壬","num3":8,"ohang3":4,"text3":"壬","num4":6,"ohang4":3,"text4":"庚"},"depth2":{"title":"지지","num1":8,"ohang1":3,"text1":"申","num2":0,"ohang2":4,"text2":"子","num3":10,"ohang3":2,"text3":"戌","num4":0,"ohang4":4,"text4":"子"},"depth3":{"title":"나의오행","num":4,"text":"수(水)"},"sub_depth":{"depth1":{"title":"천간십성","num1":0,"num2":0,"num3":10,"num4":8},"depth2":{"title":"지지십성","num1":8,"num2":1,"num3":6,"num4":1},"depth3":{"title":"십이운성","num1":0,"num2":4,"num3":2,"num4":4},"depth4":{"title":"십이신살-천간","num1":9,"num2":7,"num3":5,"num4":7},"depth5":{"title":"십이신살-지지","num1":"","num2":1,"num3":"","num4":1}}}}}