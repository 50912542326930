import {
  JeomsinRequest,
  JeomsinDomain,
  JeomsinEndPoint,
  RequestProps,
  JeomsinMethod,
} from "@Network/network";

interface Response {
  list: Array<{
    idx: string;
    dp_kind: string;
    title: string;
    url: string;
    app: string;
    app_ios: string;
    image: string;
    reg_date: string;
    aos_version: string;
    version_kind: string;
    ios_version: string;
    landing_type: string;
    yn_display_aos_free: string;
    yn_display_aos_pro: string;
    yn_display_ios_free: string;
    yn_display_ios_pro: string;
    yn_dpdate: string;
    dp_sdate: null | string;
    dp_edate: null | string;
  }>;
  result: boolean;
  TTL: string;
}

const apiGetTodayUnseBannerList = async (): Promise<Response | undefined> => {
  let request: RequestProps = {
    method: JeomsinMethod.Post,
    domain: JeomsinDomain.Jeomsin,
    endpoint: JeomsinEndPoint.GetTodayUnseBannerList,
    params: {
      ver: "0.0.0" //앱 버전, Web은 어떻게?
    },
  };

  let response: Response | undefined;
  try {
    response = (await JeomsinRequest(request)) as Response;
  } catch (_) {
    return undefined;
  }

  return response;
};

export default apiGetTodayUnseBannerList;
export type {
  Response as TodayBannerListResponse,
};