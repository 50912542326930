import { styled } from "@mui/system";
import Button from "@mui/material/Button";

interface CustomButtonProps {
  maxWidth?: string;
  bottom?: string;
  width?: string;
  height?: string;
  color?: string;
  backgroundColor?: string;
  fontSize?: string;
}

const CustomButton = styled(Button)<{
  styleProps?: CustomButtonProps;
  disabled?: boolean;
  clickHandler?: () => void;
}>(({ styleProps, disabled, clickHandler }) => ({
  "&.MuiButtonBase-root": {
    maxWidth: styleProps?.maxWidth ?? "480px",
    bottom: styleProps?.bottom ?? "0",
    width: styleProps?.width ?? "100%",
    minWidth: styleProps?.width ?? "375px",
    height: styleProps?.height ?? "56px",
    color: disabled ? "#BCC3D3" : styleProps?.color ?? "#000000",
    backgroundColor: disabled
      ? "#E9EBF4"
      : styleProps?.backgroundColor ?? "#FFF854",
    fontSize: styleProps?.fontSize ?? "16px",
    onclick: (_: any) => {
      if (clickHandler) {
        clickHandler();
      }
    },
  },
}));

export default CustomButton;
