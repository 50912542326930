import { Box, Stack, Divider, Grid } from "@mui/material";
import "@CSS/common.css";
import {
  BottomNavigationType,
  JeomsinPageType,
  RowOfTexts,
} from "@Components/compose";
import { ReactElement, useEffect, useRef, useState } from "react";
import { Chart as ChartJS } from "chart.js/auto";
import {
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import apiGetTodayDayastro from "@Network/request/api_get_today_dayastro";
import apiGetTodayUnseBannerList, { TodayBannerListResponse } from "@Network/request/api_get_today_unse_banner_list";
import apiGetCelebBirth, {
  CelebBirthResponse,
  EmptyCelebBirthResponse,
} from "@Network/request/api_get_celeb_birth";
import apiGetDayJiji2022 from "@Network/request/api_get_day_jiji_2022";
import apiFortuneTodayUnse, {
  EmptyFortuneTodayUnseResponse,
  FortuneTodayUnseResponse,
} from "@Network/request/api_get_fortune_today_unse";
import apiGetCounselorNewV3, { Counselor, CounselorInfo } from "@Network/request/api_get_counselor_new_v3";
import apiFortuneTodayNum from "@Network/request/api_get_fortune_today_num";
import CustomLineChart from "./custom_line_chart";
import {
  FortuneTodayNumResponse,
  EmptyFortuneTodayNumResponse,
} from "@Network/request/api_get_fortune_today_num";
import CustomHexagonGraph from "./custom_hexagon_graph";
import TopSummaryView from "./top_summary_view";
import css from "./today_unse.module.css";
import TitleAndDescription from "./title_and_description";
import WhatBringsLuckList from "./what_brings_luck_list";
import CoordiFashionAdvice from "./coordi_fashion_advice";
import CelebrityBornDay from "./celebrity_born_day";
import CelebritySimilarSaju from "./celebrity_similar_saju";
import { UserInfo } from "@Storage/data_types";
import { useLocation, useNavigate } from "react-router-dom";
import { FontScales, getToday, initializeFontScale, setFontScale } from "@Util/utility";
import CounselorView from "./counselor_view";
import ShareBanner from "./share_banner";
import { JeomsinPagePath, JeomsinPageUIProps } from "@Routes/PageRoutes";
import { DepthAKAPage } from "@Storage/data_model/depth_aka_page";
import BujeokView from "./bujeok_view";
import { BottomButton, ModalPopup, ModalPopupType } from "@BasicComponents/basic";
import UnseCircleView, { TimeType } from "./unse_circle_view";
import apiGetCrystalBallSubList, { CrystalBallListResponse } from "@Network/request/api_get_crystalball_sub_list copy";
import { AdStatus, checkAdStatus, checkCoupangAcceptTimePassedSince } from "@Storage/coupang_ad_time_manager";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const totalUnseTab = {
  tabTodayTotal: 1,
  tabTodayMoring: 2,
  tabTodayAfternoon: 3,
  tabTodayNight: 4
}

function TodayUnse({ headerType, bottomNavParam }: JeomsinPageUIProps) {
  const navigate = useNavigate();
  const [unseTodayNums, setUnseTodayNums] = useState<FortuneTodayNumResponse | undefined>();
  const [todayBannerList, setTodayBannerList] = useState<TodayBannerListResponse | undefined>();
  const [todayUnse, setTodayUnse] = useState<FortuneTodayUnseResponse | undefined>();
  const [celeb, setCeleb] = useState<CelebBirthResponse | undefined>();
  const [crystalballSubList, setCrystalballSubList] = useState<CrystalBallListResponse | undefined>();
  const [counselorInfo, setCounselorInfo] = useState<CounselorInfo | undefined>();
  const [counselors, setCounselors] = useState<Counselor[] | undefined>();
  const [showFontSettings, setShowFontSettings] = useState<boolean>(false);
  const [totalUnseTabIndex, setTotalUnseTabIndex] = useState(totalUnseTab.tabTodayTotal);
  const [isScrollable, setIsScrollable] = useState(checkAdStatus() === AdStatus.showDetailsWithoutInterstitialAd);
  
  const userInfo: UserInfo = useLocation().state;

  // TODO: Each page must distinctly designate the page number using "DepthAKAPage" enum
  const depthAKAPage = DepthAKAPage.TodayUnse;
  
  const divRef = useRef<HTMLDivElement>(null);

  initializeFontScale();
 
  useEffect(() => {
    if (divRef.current) {
      const width = divRef.current.offsetWidth;
      divRef.current.style.setProperty('max-width', width+"px");
    }
  }, []);

  useEffect(() => {
    console.log("totalUnseTab : " + totalUnseTabIndex);
  }, [totalUnseTabIndex]);

  useEffect(() => {
    headerType({ 
      type: JeomsinPageType.UnseResult, 
      title: "오늘의 운세", 
      pageBackEvent:()=> {
        navigate(JeomsinPagePath.Home);
      },
      fontSizeButtonPressed: () => {
        setShowFontSettings(true);
      },
    });
    bottomNavParam({ type: BottomNavigationType.None });

    const getData = async () => {
      // TODO: Jiji (띠) Setting must be here!

      // const data = await apiGetTodayDayastro(3, 9, 1, 2024, 9, 9);
      setTodayBannerList(await apiGetTodayUnseBannerList());

      // TODO: If the page is TomorrowUnse or DesignatedDateUnse, we must select date other than today.
      let date = new Date();
      switch (depthAKAPage) {
        case DepthAKAPage.TodayUnse as number:
          break;
        case DepthAKAPage.TomorrowUnse as number:
          date = new Date(
            date.getFullYear(),
            date.getMonth(),
            date.getDate() + 1
          );
          break;
        case DepthAKAPage.DesignatedDateUnse as number:
          // TODO: Get a specific year, month, and day from outside.
          break;
      }

      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      setCeleb(
        await apiGetCelebBirth(
          userInfo.saju_info!.chunganJijiOhangNodes[4].num.toString(),
          userInfo.saju_info!.chunganJijiOhangNodes[5].num.toString(),
          userInfo.saju_info!.chunganJijiOhangNodes[6].num.toString(),
          getToday()
        )
      );

      const data4 = await apiGetDayJiji2022(2, 1);
      setTodayUnse(
        await apiFortuneTodayUnse(`${year}-${month}-${day}`, userInfo)
      );
      const counselorsResponse = await apiGetCounselorNewV3(
        depthAKAPage,
        1,
        true,
        "0" // TODO: 빈값의 경우 아무것도 안줘 0으로 우선 전달.
      );

      setCrystalballSubList(await apiGetCrystalBallSubList());

      setCounselorInfo(counselorsResponse?.info); 
      setCounselors(counselorsResponse?.counselor_list); 

      setUnseTodayNums(await apiFortuneTodayNum(userInfo));

      console.log(`Jiji is ${JSON.stringify(data4)}`);
      console.log(`Counselors are ${JSON.stringify(counselors)}`);
      console.log(`Celebs are ${JSON.stringify(celeb)}`);
    };

    getData();
  }, []);

  const nonnullTodayUnse = todayUnse ?? EmptyFortuneTodayUnseResponse();
  const menu2 = nonnullTodayUnse.drmwork_data.menu2;
  const menu3 = nonnullTodayUnse.drmwork_data.menu3;
  const menu4 = nonnullTodayUnse.drmwork_data.menu4;
  const categories = [
    menu2.category1,
    menu2.category2,
    menu2.category3,
    menu2.category4,
    menu2.category5,
    menu2.category6,
  ]
    .filter((category) => category !== undefined)
    .map((category) => category!);

  const topbanner = todayBannerList?.list
  .filter((value) => value.dp_kind == "1" && value.yn_display_aos_free == "Y") //dp_kind와 노출여부 체크
  .at(0);

  const nonnullCeleb = celeb ?? EmptyCelebBirthResponse();
  console.log("todayBannerList : " + JSON.stringify(topbanner));
  return (
    <Stack className={`header-type-sub ${isScrollable ? "" : css.prevent_scroll}`} ref={divRef} direction="column" sx={{ width: "100%", alignItems: "center" }}>
      <div className={`${css.v_stack}`}>
        <hr style={{ width: "100%", opacity: "5%" }} />
        <Stack
          className={`${css.unse_category_selection_stack}`}
          direction="row"
          spacing="10%"
        >
          <Box
            sx={{
              position: "relative",
            }}
          >
            <Box
              sx={{
                fontWeight: 600,
                color: "black",
                background: "linear-gradient(#FFF854, #FFF854)",
                backgroundSize: "auto 40%",
                backgroundPositionY: "100%",
                backgroundRepeat: "no-repeat",
              }}
            >
              <span>오늘의 운세</span>
            </Box>
          </Box>
          <span>띠운세</span>
          <span>별자리 운세</span>
        </Stack>
        <hr style={{ width: "100%", opacity: "5%" }} />
        {TopSummaryView(nonnullTodayUnse)}
        {
          topbanner ?
          <img
          style={{ objectFit: "contain", width: "100%", paddingTop:"40px"}}
          src={topbanner.image}
          alt="탑배너"
          /> 
          : 
          <img
          style={{ objectFit: "contain", width: "100%", paddingTop:"40px"}}
          src="img/unse_top_banner_test.jpg"
          alt="인맥보고서 소개"
          />
        }
        <div className={css.tab_container}>
          <div className={`${css.tab} ${(totalUnseTabIndex == totalUnseTab.tabTodayTotal) ? css.active : ""}`} onClick={()=> {setTotalUnseTabIndex(totalUnseTab.tabTodayTotal)}}>총운</div>
          <div className={`${css.tab} ${(totalUnseTabIndex == totalUnseTab.tabTodayMoring) ? css.active : ""}`} onClick={()=> {setTotalUnseTabIndex(totalUnseTab.tabTodayMoring)}}>오전</div>
          <div className={`${css.tab} ${(totalUnseTabIndex == totalUnseTab.tabTodayAfternoon) ? css.active : ""}`} onClick={()=> {setTotalUnseTabIndex(totalUnseTab.tabTodayAfternoon)}}>오후</div>
          <div className={`${css.tab} ${(totalUnseTabIndex == totalUnseTab.tabTodayNight) ? css.active : ""}`} onClick={()=> {setTotalUnseTabIndex(totalUnseTab.tabTodayNight)}}>밤</div>
        </div>
        {
          totalUnseTabIndex == totalUnseTab.tabTodayTotal ? 
          <Box className={`${css.v_stack}`}>
            <Box>
              <Box sx={{ width: "25%" }}>
                <Box sx={{ width: "50%", transform: "translateX(50%)" }}>
                  <Divider sx={{ height: "1.5px", backgroundColor: "black" }} />
                </Box>
              </Box>
              <Divider />
            </Box>
            {CustomHexagonGraph(nonnullTodayUnse)}
            {TitleAndDescription(
              nonnullTodayUnse.drmwork_data.menu1.category1.depth1
            )}
            {
              <div style={{ width: "100%" }}>
                {CustomLineChart(unseTodayNums ?? EmptyFortuneTodayNumResponse())}
              </div>
            }
            {categories.map((category) => TitleAndDescription(category.depth1))}
            {WhatBringsLuckList(menu3)}
            {CoordiFashionAdvice(menu4)}
            {CelebrityBornDay(nonnullCeleb)}
            {CelebritySimilarSaju(nonnullCeleb)}
            {ShareBanner()}
            <Box className={css.counselors_wrap}>
              <div className={css.text_wrap}>
                <span className="ts14 pt40">아직 궁금한 게 남았다면?</span>
                <span className="pt10">
                  <b>실시간 상담으로</b> 속 시원하게 해결하세요
                </span>
              </div>
              <div className={`${css.scroll_x_container} pt20`}>
                {counselors?.map((counselor, index) => (
                  <div className={`${css.scroll_item} ${index == 0 ? "pl30" : ""}`}>
                    <CounselorView conunselorInfo={counselorInfo!} counselor={counselor} onClick={(c) => console.log(`Counselor is `)}/>
                  </div>
                ))}
              </div>
            </Box>
            {BujeokView()}
          </Box>
          : <></>
        }

        {
          totalUnseTabIndex == totalUnseTab.tabTodayMoring ? 
          <Box className={`${css.v_stack}`}>
            <div className={css.circle_area}>
              <UnseCircleView unseScore={todayUnse?.drmwork_data.menu1.category1.depth2?.num} timeType={TimeType.Moring}></UnseCircleView>
              <UnseCircleView unseScore={todayUnse?.drmwork_data.menu1.category1.depth3?.num} timeType={TimeType.Afternoon}></UnseCircleView>
              <UnseCircleView unseScore={todayUnse?.drmwork_data.menu1.category1.depth4?.num} timeType={TimeType.Night}></UnseCircleView>
            </div>
            <div className={css.daily_unse_text}>
              {todayUnse?.drmwork_data.menu1.category1.depth2?.text}
            </div>
            {ShareBanner()}
            <div className={css.crystalball_container}>
              <div className={css.crystalball_title_wrap}>
                <div className={css.yellow_highlight} />
                <span className={css.crystalball_title}>
                  고민 있으세요?
                </span>
              </div>
              <div className={css.cards}>
                  {crystalballSubList?.list_sub.map((value, index) => (
                    <div key={index} className={css.card}>
                        <img src={value.img_url} alt="crystalball"/>
                    </div>
                  ))}
              </div>
              <button className={css.crystalball_button}>고민구슬 보러가기</button>
            </div>

            <Box className={css.counselors_wrap}>
              <div className={css.text_wrap}>
                <span className="ts14 pt40">아직 궁금한 게 남았다면?</span>
                <span className="pt10">
                  <b>실시간 상담으로</b> 속 시원하게 해결하세요
                </span>
              </div>
              <div className={`${css.scroll_x_container} pt20`}>
                {counselors?.map((counselor, index) => (
                  <div className={`${css.scroll_item} ${index == 0 ? "pl30" : ""}`}>
                    <CounselorView conunselorInfo={counselorInfo!} counselor={counselor} onClick={(c) => console.log(`Counselor is `)}/>
                  </div>
                ))}
              </div>
            </Box>
            {BujeokView()}
          </Box>
          : <></>
        }
        {
          totalUnseTabIndex == totalUnseTab.tabTodayAfternoon ? 
          <Box className={`${css.v_stack}`}>
            <div className={css.circle_area}>
              <UnseCircleView unseScore={todayUnse?.drmwork_data.menu1.category1.depth2?.num} timeType={TimeType.Moring}></UnseCircleView>
              <UnseCircleView unseScore={todayUnse?.drmwork_data.menu1.category1.depth3?.num} timeType={TimeType.Afternoon}></UnseCircleView>
              <UnseCircleView unseScore={todayUnse?.drmwork_data.menu1.category1.depth4?.num} timeType={TimeType.Night}></UnseCircleView>
            </div>
            <div className={css.daily_unse_text}>
              {todayUnse?.drmwork_data.menu1.category1.depth3?.text}
            </div>
            {ShareBanner()}
            <Box className={css.counselors_wrap}>
              <div className={css.text_wrap}>
                <span className="ts14 pt40">아직 궁금한 게 남았다면?</span>
                <span className="pt10">
                  <b>실시간 상담으로</b> 속 시원하게 해결하세요
                </span>
              </div>
              <div className={`${css.scroll_x_container} pt20`}>
                {counselors?.map((counselor, index) => (
                  <div className={`${css.scroll_item} ${index == 0 ? "pl30" : ""}`}>
                    <CounselorView conunselorInfo={counselorInfo!} counselor={counselor} onClick={(c) => console.log(`Counselor is `)}/>
                  </div>
                ))}
              </div>
            </Box>
            {BujeokView()}
          </Box>
          : <></>
        }
        {
          totalUnseTabIndex == totalUnseTab.tabTodayNight ? 
          <Box className={`${css.v_stack}`}>
            <div className={css.circle_area}>
              <UnseCircleView unseScore={todayUnse?.drmwork_data.menu1.category1.depth2?.num} timeType={TimeType.Moring}></UnseCircleView>
              <UnseCircleView unseScore={todayUnse?.drmwork_data.menu1.category1.depth3?.num} timeType={TimeType.Afternoon}></UnseCircleView>
              <UnseCircleView unseScore={todayUnse?.drmwork_data.menu1.category1.depth4?.num} timeType={TimeType.Night}></UnseCircleView>
            </div>
            <div className={css.daily_unse_text}>
              {todayUnse?.drmwork_data.menu1.category1.depth4?.text}
            </div>
            {ShareBanner()}
            <Box className={css.counselors_wrap}>
              <div className={css.text_wrap}>
                <span className="ts14 pt40">아직 궁금한 게 남았다면?</span>
                <span className="pt10">
                  <b>실시간 상담으로</b> 속 시원하게 해결하세요
                </span>
              </div>
              <div className={`${css.scroll_x_container} pt20`}>
                {counselors?.map((counselor, index) => (
                  <div className={`${css.scroll_item} ${index == 0 ? "pl30" : ""}`}>
                    <CounselorView conunselorInfo={counselorInfo!} counselor={counselor} onClick={(c) => console.log(`Counselor is `)}/>
                  </div>
                ))}
              </div>
            </Box>
            {BujeokView()}
          </Box>
          : <></>
        }
      </div>
      {
        ModalPopup({
          isOpen: showFontSettings,
          type: ModalPopupType.FontSizeChange,
          onClose: () => {
            setShowFontSettings(false);
          }
        })
      }
      {
        <div className={`${css.fill_width} ${isScrollable ? css.invisible : css.visible}`}>
          <BottomButton title="결과보기" onClick={() => {
            // TODO: Show interstitial ads
            setIsScrollable(true);
          }} />
        </div>
      }
    </Stack>
  );
}

export default TodayUnse;
