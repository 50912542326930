import { Stack } from "@mui/material";
import {
  CelebBirthCelebInfo,
  CelebBirthResponse,
} from "@Network/request/api_get_celeb_birth";
import { ReactElement } from "react";
import css from "./celebrity_similar_saju.module.css";

interface SimilarSajuCeleb {
  name: string;
  description: string;
}

function SimilarSajuCelebView(fromCeleb: SimilarSajuCeleb): ReactElement {
  return (
    <Stack
      direction="row"
      sx={{
        display: "flex",
        alignItems: "center",
        borderRadius: "13px",
        borderColor: "#EAEEF7",
        borderStyle: "solid",
        borderWidth: "1px",
      }}
      spacing="14px"
      className="pr19 pl19 pt15 pb15"
    >
      <span className="ts17 bold6">{fromCeleb.name}</span>
      <span className="ts15 bold4" style={{ color: "#818C9A" }}>
        {fromCeleb.description}
      </span>
    </Stack>
  );
}

function CelebritySimilarSaju(celebResponse: CelebBirthResponse): ReactElement {
  const celebs = celebResponse.celeb_info.map(function mapToUIData(
    info: CelebBirthCelebInfo
  ): SimilarSajuCeleb {
    return {
      name: info.c_name,
      description: info.c_job,
    };
  });

  return (
    <div className={`${css.fill_width}`}>
      <Stack className="p20" spacing="10px">
        <span className="ts19 bold6 pb15">비슷한 사주의 유명인</span>
        {celebs.map((celeb) => SimilarSajuCelebView(celeb))}
      </Stack>
    </div>
  );
}

export default CelebritySimilarSaju;
