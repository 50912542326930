import {
  JeomsinRequest,
  JeomsinDomain,
  JeomsinEndPoint,
  RequestProps,
  JeomsinMethod,
} from "@Network/network";

interface CelebInfo {
  idx: string;
  c_year: string;
  c_month: string;
  c_day: string;
  c_job: string;
  c_name: string;
  depth1: string;
  depth2: string;
  depth3: string;
}

interface Response {
  celeb_info: CelebInfo[];
  today_celeb: CelebInfo[];
  result: boolean;
  TTL: string;
}

function EmptyCelebBirthResponse(): Response {
  return {
    celeb_info: [],
    today_celeb: [],
    result: false,
    TTL: "",
  };
}

const apiGetCelebBirth = async (
  nyunJi: string,
  wolJi: string,
  ilJi: string,
  dayRegDate: string | undefined
): Promise<Response | undefined> => {
  let request: RequestProps = {
    method: JeomsinMethod.Post,
    domain: JeomsinDomain.Jeomsin,
    endpoint: JeomsinEndPoint.GetCelebBirth,
    params: {
      depth1: nyunJi,
      depth2: wolJi,
      depth3: ilJi,
    },
  };

  if (dayRegDate) {
    request.params.sparam = dayRegDate;
  }

  try {
    const inferred = (await JeomsinRequest(request)) as Response;
    return inferred;
  } catch (e) {
    return undefined;
  }
};

export default apiGetCelebBirth;
export type {
  Response as CelebBirthResponse,
  CelebInfo as CelebBirthCelebInfo,
};
export { EmptyCelebBirthResponse };
