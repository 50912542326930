import { Stack, Box, Divider } from "@mui/material";
import { ReactElement } from "react";
import {
  CelebBirthResponse,
  CelebBirthCelebInfo,
} from "@Network/request/api_get_celeb_birth";
import { info } from "console";
import css from "./celebrity_born_day.module.css";

interface Celeb {
  birthYear: string;
  name: string;
  description: string;
}

function CelebInfo(celeb: Celeb, showDivider: boolean): ReactElement {
  return (
    <Stack>
      <div className={`${css.h_stack_celeb_info}`}>
        <div className={`${css.centered}`}>
          <div className={`${css.padded_info_cell}`}>
            <Box style={{ display: "flex", alignItems: "center" }}>
              <span className="ts15">{celeb.birthYear}</span>
            </Box>
          </div>
        </div>
        {/** top padding of 1 pixel + 1 pixel of border width, resulting in pt2 */}
        <Box>
          <Stack spacing="5px">
            <span>
              <b>{celeb.name}</b>
            </span>
            <span className="ts14" style={{ color: "#818C9A" }}>
              {celeb.description}
            </span>
          </Stack>
        </Box>
      </div>

      {showDivider ? (
        <Stack>
          <Box className="p10" />
          <Divider />
          <Box className="p10" />
        </Stack>
      ) : (
        <></>
      )}
    </Stack>
  );
}

const CelebrityBornDay = (
  celebResponse: CelebBirthResponse,
) => {
  const celebs = celebResponse.today_celeb.map(function infoToCeleb(
    info: CelebBirthCelebInfo
  ): Celeb {
    return {
      birthYear: info.c_year,
      name: info.c_name,
      description: info.c_job,
    };
  });

  return (
    <div className={`${css.fill_width}`}>
      <div className={`${css.padded}`}>
        <div className={`${css.v_stack_main}`}>
          <span className="bold6">오늘 태어난 유명인</span>
          <Stack className={`${css.celeb_v_stack}`}>
            {celebs.map((celeb, index, _) =>
              CelebInfo(celeb, index !== celebs.length - 1)
            )}
          </Stack>
        </div>
      </div>
    </div>
  );
};

export default CelebrityBornDay;
