import { useNavigate } from "react-router";
import "./titlebar.css"
import { Box } from '@mui/material';
import CookieManager from "@Storage/cookie_manager";
import CookieKeys from "@Storage/cookie_keys";
import { UserInfo } from "@Storage/data_types";
import useDimmedStore from "@Storage/titlebar_actions";
import { JeomsinPagePath } from "@Routes/PageRoutes";


type TitlebarProps = {
    type: TitlebarType,
    title: string,
    pageBackEvent?:()=> void,
    fontSizeButtonPressed?: () => void,
    shareButtonPressed?: () => void,
}

const TitleBar: React.FC<TitlebarProps> = (
    props
) => {
    var returnValue:JSX.Element;
    const navigate = useNavigate();
    const histroyBack = () => {
        props.pageBackEvent ? props.pageBackEvent() : navigate(-1);
    }
    const { setDimLayerOpen } = useDimmedStore();
    
    switch(props.type) {
        case TitlebarType.Fortune:
            const userInfoJson = CookieManager.GetJSON(CookieKeys.representative_user_info);
            console.log(`UserInfo : ${JSON.stringify(userInfoJson)}`);
            returnValue = (
                <Box className="titlebar main">
                    <span className="title">{props.title}</span>
                    <div className="tab-right" onClick={()=> { userInfoJson ? navigate(JeomsinPagePath.SajuMyeongsik) : setDimLayerOpen(true); }}>
                        <div className="tab-right-text">
                            <span className="mr5">선택</span>
                            <span className="mr5">{userInfoJson ? userInfoJson.name : "게스트"}님</span>
                        </div>
                        <img src='img/ic_saju_list_change.png'/>
                    </div>
                </Box>  
            );
            break;
        case TitlebarType.SubPageNoButton:
            returnValue = (
                <Box className='titlebar sub'>
                    <div className='left-icon' onClick={histroyBack}>
                        <img src='img/arrow.left.png'></img>
                    </div> 
                    <span>{props.title}</span>
                    <Box className='right-icon'>
                    </Box>
                </Box>
            );
            break;
        case TitlebarType.SubPageOneButton:
            returnValue = (
                <Box className='titlebar sub'>
                    <div className='left-icon' onClick={histroyBack}>
                        <img src='img/arrow.left.png'></img>
                    </div> 
                    <span>{props.title}</span>
                    <Box className='right-icon'>
                        <img src='img/arrow.left.png'></img>
                    </Box>
                </Box>
            );
            break;
        case TitlebarType.SubPageTwoButton:
            returnValue = (
                <Box className='titlebar sub'>
                    <div className='left-icon' onClick={histroyBack}>
                        <img src='img/arrow.left.png'></img>
                    </div> 
                    <span>{props.title}</span>
                    <Box className='right-icon'>
                        <div className="trailing-icon" onClick={() => {
                            if (props.fontSizeButtonPressed) {
                                props.fontSizeButtonPressed();
                            }
                        }}>
                            <img src='img/font.png' alt="font size change"/>
                        </div>
                        <div className="trailing-icon" onClick={() => {
                            if (props.shareButtonPressed) {
                                props.shareButtonPressed();
                            }
                        }}>
                            <img src='img/app_bar_share.png' alt="share contents"/>
                        </div>
                    </Box>
                </Box>
            );
            break;
        default:
            returnValue = (<span></span>);
            break;

    }

    return returnValue;
};

enum TitlebarType {
    Fortune,
    SubPageNoButton,
    SubPageOneButton,
    SubPageTwoButton,
    None
}

export {
    TitleBar,
    TitlebarType
};