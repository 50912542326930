import "./appbar.css"
import { Box } from '@mui/material';

type AppbarBarProp = {
    showAppbar : Boolean,
    appbarRightType? : AppbarRightType
}

const Appbar: React.FC<AppbarBarProp> = ({
    showAppbar,
    appbarRightType = AppbarRightType.None,
}) => {
    return (
        <Box className={showAppbar ? "statusbar" : " none"}>
            <Box className='statusbar-wrap'>
                <Box className="statusbar-left">
                    {/* TODO 기상정보도 prop 처리 필요한지 확인 필요  */}
                    <span className="statusbar-left-text">서울 강남구</span>
                    <span className="statusbar-left-text">미세먼지 · 맑음</span>
                    <span className="statusbar-left-text">32'C</span>
                    <Box>
                        <img src='img/weather_small13.png'></img>
                    </Box>
                </Box>
                {AppbarType(appbarRightType)}
            </Box>
        </Box>
    );
}

enum AppbarRightType {
    Fortune,
    None
}

const AppbarType = (type:AppbarRightType) => {
    var returnValue:JSX.Element;
    
    switch(type) {
        case AppbarRightType.Fortune:
            returnValue = (
                <Box className="statusbar-right">
                    <img src='img/ic_app_alarm.png'></img>
                    <img className='' src='img/profile.png'></img>
                </Box>
            );
            break;
        default:
            returnValue = (<span></span>);
            break;

    }

    return returnValue;
};

export {
    Appbar,
    AppbarRightType
};