import { useRef, useState } from 'react';
import './select_box.css';

type SelectBoxProps = {
    className?: string;
    options: SelectOptionsProps[];
    width?: number;
    height?: number;
    gap?: number;
    fontClass?: string;
    onSelect?: (event:React.MouseEvent<HTMLButtonElement, MouseEvent>, index?:number) => void;
}

type SelectOptionsProps = {
    label: string;
    value: string;
}

const space_text = " ";

const JeomsinSelectBox:React.FC<SelectBoxProps> = (
    props
) => {
    const [selectIndex, setSelectIndex] = useState<number | null>(null);

    let classNm = props.className ? space_text+props.className : "";
    let fontClsNm = props.fontClass ? props.fontClass : "ts16 bold6";

    return (
        <section className={"select-box-wrap" + classNm}>
            <div 
                className="select-content"
                style={{gap:props.gap}}
            >
            { 
                props.options.map((option, index) => (
                    <button
                    key={index}
                    className={fontClsNm + ((selectIndex == index) ? space_text + "selected" : "")}
                    style={{width:props.width, height:props.height}}
                    value={option.value}
                    onClick={(event) => {
                        setSelectIndex(index);
                        if(props.onSelect){
                            props.onSelect(event, index);
                        }
                    }}>{option.label}</button>
                ))
            }
            </div>
        </section>
    );
};


export default JeomsinSelectBox;