const CookieKeys = {
  representative_user_info: "representative_user_info",
  representative_fortune_info: "representative_user_info",
  user_info_list: "user_info_list",
  user_fortune_info_list: "user_fortune_info_list",
  ttl_version: "ttl_version",
  coupangAdRejectionTime: "coupangAdRejectionTime",
  coupangAdAcceptTime: "coupangAdAcceptTime",
  fontScale: "fontScale",
} as const;

export default CookieKeys;
