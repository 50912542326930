import {
  JeomsinRequest,
  JeomsinDomain,
  JeomsinEndPoint,
  RequestProps,
  JeomsinMethod,
} from "@Network/network";

interface Response {
  cnt_list: CountEntry[];
  result: boolean;
  TTL: string;
}

interface CountEntry {
  cnt_code: string;
  cnt: string;
}

const apiGetSNSContentCount = async (): Promise<Response | undefined> => {
  let request: RequestProps = {
    method: JeomsinMethod.Post,
    domain: JeomsinDomain.Jeomsin,
    endpoint: JeomsinEndPoint.GetSNSContentCount,
    params: {},
  };

  let result: Response | undefined;
  try {
    result = (await JeomsinRequest(request)) as Response;
  } catch (_) {
    return undefined;
  }

  return result;
};

export default apiGetSNSContentCount;
export type { Response as SNSContentCountResponse };
