import CookieKeys from "./cookie_keys";

type CookieKey = keyof typeof CookieKeys;

const CookieManager = {
  SetString: setString,
  GetString: getString,
  SetJSON: setJSON,
  GetJSON: getJSON,
  RemoveKeyValue: removeKeyValue,
};

function isLocalStorageAvailable(): boolean {
  var checker = "sm_checker_local_storage";
  try {
    localStorage.setItem(checker, checker);
    localStorage.removeItem(checker);
    return true;
  } catch (e) {
    return false;
  }
}

function setString(key: CookieKey, value: string) {
  if (isLocalStorageAvailable() === false) {
    return;
  }
  localStorage.setItem(CookieKeys[key], value);
}

function getString(key: CookieKey): string | undefined {
  if (isLocalStorageAvailable() === false) {
    return undefined;
  }
  const nullable = localStorage.getItem(CookieKeys[key]);
  if (nullable) {
    return nullable;
  } else {
    return undefined;
  }
}

function setJSON(key: CookieKey, jsonValue: object) {
  if (isLocalStorageAvailable() === false) {
    return;
  }
  localStorage.setItem(CookieKeys[key], JSON.stringify(jsonValue));
}

function getJSON(key: CookieKey): any | undefined {
  if (isLocalStorageAvailable() === false) {
    return undefined;
  }
  const rawString = localStorage.getItem(CookieKeys[key]);
  if (rawString) {
    try {
      return JSON.parse(rawString);
    } catch (e) {
      console.error("Error parsing JSON from localStorage", e);
      return undefined;
    }
  } else {
    return undefined;
  }
}

function removeKeyValue(key: CookieKey) {
  if (isLocalStorageAvailable() === false) {
    return;
  }
  localStorage.removeItem(CookieKeys[key]);
}

export default CookieManager;
