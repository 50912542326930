import {
  JeomsinRequest,
  JeomsinDomain,
  JeomsinEndPoint,
  RequestProps,
  JeomsinMethod,
} from "@Network/network";
import { UserInfo } from "@Storage/data_types";
import { getMyCalendar, getMyLeap } from "@Util/utility";

interface Response {
  drmwork_data: DrmworkData;
}

interface DrmworkData {
  menu1: MenuDetail;
  menu2: MenuDetail;
  menu3: MenuDetail;
  menu4: MenuDetail;
  menu5: MenuDetail;
}

interface MenuDetail {
  date: string;
  num: number;
}

function EmptyFortuneTodayNumResponse(): Response {
  return {
    drmwork_data: {
      menu1: {
        date: "",
        num: 0,
      },
      menu2: {
        date: "",
        num: 0,
      },
      menu3: {
        date: "",
        num: 0,
      },
      menu4: {
        date: "",
        num: 0,
      },
      menu5: {
        date: "",
        num: 0,
      },
    },
  };
}

const apiFortuneTodayNum = async (
  userInfo: UserInfo
): Promise<Response | undefined> => {
  let request: RequestProps = {
    method: JeomsinMethod.Post,
    domain: JeomsinDomain.JeomsinXml,
    endpoint: JeomsinEndPoint.GetFortuneTodayNum,
    params: {
      cate: "1",
      myname: userInfo.name,
      myyear: userInfo.birthYear,
      mymonth: userInfo.birthMonth,
      myday: userInfo.birthDay,
      myhour: userInfo.birthTime,
      btime: userInfo.birthMinute,
      mycalendar: getMyCalendar(userInfo),
      myleap: getMyLeap(userInfo),
      mygender: userInfo.gender,
    },
  };

  try {
    const inferred = (await JeomsinRequest(request)) as Response;
    return inferred;
  } catch (e) {
    return undefined;
  }
};

export default apiFortuneTodayNum;
export type { Response as FortuneTodayNumResponse };
export { EmptyFortuneTodayNumResponse };
