import { JeomsinSelectBox } from '@BasicComponents/basic';
import css from './modal_popup.module.css'
import { ReactNode, useEffect, useRef, useState } from 'react';
import { CSSTransition } from "react-transition-group";
import { apiLunarCalendar } from '@Network/network';
import IosStylePicker from '@Components/ios_style_picker/ios_style_picker';
import { ColoredRectangleButton } from '@BasicComponents/colored_rectangle_button/colored_rectangle_button';
import { ClassNames } from '@emotion/react';
import { FontScales, FontScale, getCurrentFontScale, setFontScale } from '@Util/utility';

type ModalPopupProps = {
  isOpen: boolean;
  type: ModalPopupType;
  title?: string;
  contents?: any;
  helptext?: string;
  data?:any;
  firstButtonTitle?: string;
  onClickFirstButton?: () => void;
  secondButtonTitle?: string;
  onClickSecondButton?: () => void;
  onClose: (returnData?:any) => void;
}

const space_text = " ";

const ModalPopup:React.FC<ModalPopupProps>  = ( props ) => {
  const popupRef = useRef(null);
  const [returnData, setReturnData] = useState<any>();
  const [isPopupClose, setPopupClose] = useState(false);
  
  useEffect(() => {
    if(isPopupClose)
      props.onClose(returnData);
  }, [isPopupClose]);

  return (
    <CSSTransition
      nodeRef={popupRef}
      in={props.isOpen}
      timeout={{
        enter: 400,
        exit: 400,
      }}
      classNames="fade"
      mountOnEnter
      unmountOnExit
    >
      <section className={css.modal}>
        <div
          ref={popupRef} 
          className={css.dim}
          onClick={() => setPopupClose(true)}
        />
        {PopupType(props, setReturnData, setPopupClose)}
      </section>
    </CSSTransition>
  )
};

export const ModalPopupType = {
  Normal: "Normal",
  BirthTypeSelect: "CalenderTypeSelect",
  SajuInfoSelect: "SajuInfoSelect",
  FontSizeChange: "FontSizeChange",
} as const;
export type ModalPopupType = (typeof ModalPopupType)[keyof typeof ModalPopupType];

const PopupType = (
  props: ModalPopupProps,
  setReturnData: React.Dispatch<React.SetStateAction<any>>,
  setPopupClose: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const [isShowHelpText, setShowHelpText] = useState(false);
  const [chosenFontScale, setChosenFontScale] = useState<FontScale>(getCurrentFontScale());

  let returnValue: ReactNode;

  useEffect(() => {
    setPopupClose(false);
  }, [props.isOpen])

  switch (props.type) {
    case ModalPopupType.Normal:
      returnValue = (
        <div className={`${css.modal_content} ${css.help_content} ${css.cornerRadius5}`}>
          <div className={css.title}>{props.title}</div>
          <JeomsinSelectBox 
            options={props.contents} 
            width={100} 
            gap={20}
          />
          <div className={`${css.help_text} ${isShowHelpText ? "" : space_text+"none"}`}>{props.helptext}</div>
        </div>
      );
      break;
    case ModalPopupType.BirthTypeSelect:
      returnValue = (
        <div className={`${css.modal_content} ${css.help_content} ${css.cornerRadius5}`}>
          <div className={css.title}>음력 선택</div>
          <JeomsinSelectBox 
            options={[{label:"평달", value:"0"},{label:"윤달", value:"1"}]} 
            width={100} 
            gap={20}
            onSelect={async (event, index) => {
              console.log("index : " + index);
              if(event.currentTarget.value == "1") { //평달 0, 윤달 1
                const isLeapMonth = await apiLunarCalendar(props.data);
                console.log("isLeapMonth :", isLeapMonth);
                if(isLeapMonth == "N") {
                  setShowHelpText(true);
                } else {
                  setShowHelpText(false);
                  setReturnData({value:event.currentTarget.value, text:"(윤달)"});
                  setPopupClose(true);
                }
              } else {
                setShowHelpText(false);
                setReturnData({value:event.currentTarget.value, text:"(평달)"});
                setPopupClose(true);
              }
            }}
          />
          <div className={`${css.help_text} ${isShowHelpText ? "" : space_text+"none"}`}>입력한 날짜는 윤달 선택이 불가해요.</div>
        </div>
      );
      break;
      case ModalPopupType.SajuInfoSelect:
        const data = props.data;
        if (data && Array.isArray(data)) {

          returnValue = (
            <div className={`${css.modal_content} ${css.saju_info_select} ${css.cornerRadius25}`}>
              <div className={`${css.spacer}`} />
              {IosStylePicker(
                data,
                (selection) => {
                  setReturnData(selection);
                },
                props.isOpen
              )}
              <div className={`${css.spacer}`} />
              <ColoredRectangleButton title="확인" cornerRadius={true} height="50px" width="100%" clickHandler={() => {
                  setPopupClose(true);
              }} />
              <div className={`ts14 pt7 ${css.opacity40}`} onClick={()=> { 
                if(props.onClickFirstButton)
                  props.onClickFirstButton();
              }}>사주정보 추가하기</div>
            </div>
          );
        } else {
          returnValue = (
            <></>
          );
        }

        break;
    case ModalPopupType.FontSizeChange:
      returnValue = (
        <div className={`${css.font_size_change_modal_content}`}>
          <div className={`${css.font_size_change_description_stack}`}>
            <span className={`${css.font_size_change_title}`}>글자 크기</span>
            <div className={`${css.spacer_height_30}`} />
            <span>사주분석 결과 페이지의</span>
            <span>글자 크기가 변경돼요.</span>
            <div className={`${css.font_size_change_title}`} />
            <div className={`${css.spacer_height_40}`} />
            <div className={`${css.font_size_change_font_chooser_h_stack}`}>
              <div onClick={() => {
                  const scale: FontScale = "small";
                  setFontScale(scale);
                  setChosenFontScale(scale);
                }} className={`${css.font_size_change_font_chooser_v_stack}`}>
                <div className={`${css.font_size_change_button}`}>
                  <span className={`${css.font_size_change_font_small_example}`}>Aa</span>
                </div>
                <div className={`${css.font_size_change_checkbox_and_description_container}`}>
                  <img className={`${css.font_size_change_checkbox_button_image}`}
                    src={`images/member_integrated/member/3.0x/checkbox_24${chosenFontScale === "small" ? "_on" : ""}.png`}
                    alt="checkbox"
                  />
                  <span className={`${css.font_size_change_description}`}>작게</span>
                </div>
              </div>
              <div onClick={() => {
                  const scale: FontScale = "medium";
                  setFontScale(scale);
                  setChosenFontScale(scale);
                }} className={`${css.font_size_change_font_chooser_v_stack}`}>
                <div className={`${css.font_size_change_button}`}>
                  <span className={`${css.font_size_change_font_medium_example}`}>Aa</span>
                </div>
                <div className={`${css.font_size_change_checkbox_and_description_container}`}>
                  <img className={`${css.font_size_change_checkbox_button_image}`}
                    src={`images/member_integrated/member/3.0x/checkbox_24${chosenFontScale === "medium" ? "_on" : ""}.png`}
                    alt="checkbox"
                  />
                  <span className={`${css.font_size_change_description}`}>보통</span>
                </div>
              </div>
              <div onClick={() => {
                  const scale: FontScale = "big";
                  setFontScale(scale);
                  setChosenFontScale(scale);
                }} className={`${css.font_size_change_font_chooser_v_stack}`}>
                <div className={`${css.font_size_change_button}`}>
                  <span className={`${css.font_size_change_font_big_example}`}>Aa</span>
                </div>
                <div className={`${css.font_size_change_checkbox_and_description_container}`}>
                  <img className={`${css.font_size_change_checkbox_button_image}`}
                    src={`images/member_integrated/member/3.0x/checkbox_24${chosenFontScale === "big" ? "_on" : ""}.png`}
                    alt="checkbox"
                  />
                  <span className={`${css.font_size_change_description}`}>크게</span>
                </div>
              </div>
            </div>
            <div className={`${css.spacer_height_30}`} />
            <div onClick={() => {
              if (props.onClose) {
                props.onClose();
              }
            }} className={`${css.confirmation_button}`}>
                <span>확인</span>
            </div>
          </div>
        </div>
      );
      break;
    default:
      returnValue = <></>;
      break;
  }

  return returnValue;
};

export default ModalPopup;