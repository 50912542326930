import { Stack, Box } from "@mui/material";
import { FC } from "react";
import { ColoredRectangleButton } from "@BasicComponents/colored_rectangle_button/colored_rectangle_button";

const DestinyChartSpecifics = (
  title?: string,
  definition?: string,
  characteristics?: string,
  buttonTitle: string = "자세히보기",
  clickAction?: () => void,
  closeAction?: () => void
) => {
  return (
    <Stack className="pt30 pr30 pl30" direction="column" spacing="35px">
      <div
        style={{
          width: "100%",
          height: "100%",
          position: "relative",
          display: "inline-flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <span className="ts23 bold6">{title ?? ""}</span>
        <img
          style={{
            position: "absolute",
            top: "50%",
            right: "0%",
            transform: "translateY(-50%)",
            width: "30px",
            height: "30px",
            objectFit: "contain",
          }}
          src="images/mypage/3.0x/app_bar_close.png"
          alt="close button"
          onClick={() => {
            if (closeAction) {
              closeAction();
            }
          }}
        />
      </div>

      <Stack
        className="pl20 pr20"
        spacing="10px"
        sx={{ alignItems: "flex-start" }}
      >
        <span className="ts20 bold6">{characteristics ? "단어 뜻" : ""}</span>
        <span className="ts17">{definition ?? ""}</span>
      </Stack>

      {
      characteristics ?
        <Stack
          className="pl20 pr20"
          spacing="10px"
          sx={{ alignItems: "flex-start" }}
        >
          <span className="ts20 bold6">특징</span>
          <span className="ts17">{characteristics}</span>
        </Stack>
        : <></>
      }

      <div
        style={{
          display: "inline-flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        onClick={() => {
          if (clickAction) {
            clickAction();
          }
        }}
      >
        <ColoredRectangleButton
          title={buttonTitle}
          cornerRadius={true}
          width="100%"
          height="65px"
        />
      </div>
    </Stack>
  );
};

export default DestinyChartSpecifics;
