import { useRef, ReactNode } from "react";
import css from "./dim_layer.module.css";
import "@CSS/animation/fade.css";
import { CSSTransition } from "react-transition-group";

type DimLayerProps = {
  isOpen: boolean;
  children?: ReactNode;
  onLayerEnter?:() => void;
  onLayerExit?:() => void;
  onClose?: () => void;
  blurred?: boolean;
};

const DimLayer: React.FC<DimLayerProps> = (props) => {
  const dimLayerRef = useRef(null);

  return (
    <CSSTransition
      nodeRef={dimLayerRef}
      in={props.isOpen}
      timeout={{
        enter: 400,
        exit: 400,
      }}
      classNames="fade"
      mountOnEnter
      unmountOnExit
      onEnter={props.onLayerEnter}
      onExit={props.onLayerExit}
    >
      <section
        ref={dimLayerRef}
        className={`${css.dim} ${
          props.blurred ?? false ? css.blurred : css.unblurred
        }`}
        onClick={props.onClose}
      >
        {props.children}
      </section>
    </CSSTransition>
  );
};

export default DimLayer;
