import {
  JeomsinRequest,
  JeomsinDomain,
  JeomsinEndPoint,
  RequestProps,
  JeomsinMethod,
} from "@Network/network";

import { UserInfo } from "@Storage/data_types";
import { getMyCalendar, getMyLeap } from "@Util/utility";

interface Response {
  drmwork_data: DrmworkDataContent;
}

interface DrmworkDataContent {
  menu1: Menu;
  menu2: Menu;
  menu3: Menu;
  menu4: Menu;
}

interface Menu {
  title: string;
  category1: Category;
  category2?: Category;
  category3?: Category;
  category4?: Category;
  category5?: Category;
  category6?: Category;
}

interface Category {
  title: string;
  depth1: Depth;
  depth2?: Depth;
  depth3?: Depth;
  depth4?: Depth;
}

interface Depth {
  title: string;
  num?: number;
  text: string;
  headcopy?: string;
}

function EmptyDepth(): Depth {
  return {
    title: "",
    num: 0,
    text: "",
    headcopy: "",
  };
}

function EmptyCategory(): Category {
  return {
    title: "",
    depth1: EmptyDepth(),
    depth2: EmptyDepth(),
    depth3: EmptyDepth(),
    depth4: EmptyDepth(),
  };
}

function EmptyMenu(): Menu {
  return {
    title: "",
    category1: EmptyCategory(),
    category2: EmptyCategory(),
    category3: EmptyCategory(),
    category4: EmptyCategory(),
    category5: EmptyCategory(),
    category6: EmptyCategory(),
  };
}

function EmptyDrmworkDataContent(): DrmworkDataContent {
  return {
    menu1: EmptyMenu(),
    menu2: EmptyMenu(),
    menu3: EmptyMenu(),
    menu4: EmptyMenu(),
  };
}

function EmptyFortuneTodayUnseResponse(): Response {
  return {
    drmwork_data: EmptyDrmworkDataContent(),
  };
}

const apiFortuneTodayUnse = async (
  regData: string,
  userInfo: UserInfo
): Promise<Response | undefined> => {
  let request: RequestProps = {
    method: JeomsinMethod.Post,
    domain: JeomsinDomain.JeomsinXml,
    endpoint: JeomsinEndPoint.GetFortuneToday,
    params: {
      cate: 0,
      sparam: regData,
      myname: userInfo.name,
      myyear: userInfo.birthYear,
      mymonth: userInfo.birthMonth,
      myday: userInfo.birthDay,
      myhour: userInfo.birthTime,
      btime: userInfo.birthMinute,
      mycalendar: getMyCalendar(userInfo),
      myleap: getMyLeap(userInfo),
      mygender: userInfo.gender,
    },
  };

  try {
    const inferred = (await JeomsinRequest(request)) as Response;
    return inferred;
  } catch (e) {
    return undefined;
  }
};

export default apiFortuneTodayUnse;
export type {
  Response as FortuneTodayUnseResponse,
  Depth as FortuneTodayUnseDepth,
  Menu as FortuneTodayUnseMenu,
  Category as FortuneTodayUnseCategory,
};
export { EmptyFortuneTodayUnseResponse };
