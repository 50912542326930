import { SNSContentCountResponse } from "@Network/request/api_get_sns_content_count";
import { create } from "zustand";
import { TalismanPageResponse } from "./request/api_get_talisman_page";

export interface SharedInfo {
  snsCount: SNSContentCountResponse | undefined;
  setSnsCount: (value: SNSContentCountResponse | undefined) => void;

  talismanPageResponse: TalismanPageResponse | undefined;
  setTalismanPageResponse: (value: TalismanPageResponse | undefined) => void;
}

const useSharedInfoStore = create<SharedInfo> ((set) => ({
  snsCount: undefined,
  setSnsCount: (value:SNSContentCountResponse | undefined) => set(({ snsCount: value  })),
  
  talismanPageResponse: undefined,
  setTalismanPageResponse: (value) => set(({ talismanPageResponse: value })),
}));

export default useSharedInfoStore;
