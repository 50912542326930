import { Box, Stack, Button } from "@mui/material";
import { FC, ReactElement } from "react";
import { ContentType } from "./content_type";
import { CharacterType } from "./character_type";
import "../css/saju_analyzing_card.css";
import "@CSS/common.css";

type CloseClickCallback = () => void;

export interface SajuAnalyzingCardProps {
  index: number;
  content?: ReactElement | string;
  contentType: ContentType;
  className?: string;
  closeButtonClicked?: CloseClickCallback;
  closeButtonTimer?: number;
  adClicked?: () => void;
}

const SajuAnalyzingCard: FC<SajuAnalyzingCardProps> = ({
  index,
  content,
  contentType,
  className,
  closeButtonClicked,
  closeButtonTimer = 0,
  adClicked,
}) => {
  switch (contentType) {
    case ContentType.adFreeBanner:
      return (
        <Box key={index}>
          <img
            className="img-scaled-to-fit"
            src="img/psychology_test_banner.png"
            alt="adfree banner"
            style={{ width: "100%" }}
          />
        </Box>
      );
    case ContentType.ad320x100:
      return (
        <Box key={index}>
          <Box className="ad-320-100 bgcolor-white rounded10">
            <span className={`${className}`}>ad320x100</span>
          </Box>
        </Box>
      );
    case ContentType.ad320x250:
      return (
        <Box key={index}>
          <Box className="ad-320-250 bgcolor-white rounded10">
            <span className={`${className}`}>ad320x250</span>
          </Box>
        </Box>
      );
    case ContentType.coupangAd:
      return (
        <div key={index}>
          <Box className="bgcolor-white p20 rounded20 relative-position">
            <Stack direction="column" spacing="20px">
              <img
                onClick={() => {
                  if (adClicked) {
                    adClicked();
                  }
                }}
                className="img-scaled-to-fit"
                src="img/jeomsin_coupang_partner_icon.png"
                alt="jeomsin-coupang partnership"
                height="16px"
              />
              {(() => {
                if (typeof content === "string") {
                  return (
                    <img
                      onClick={() => {
                        if (adClicked) {
                          adClicked();
                        }
                      }}
                      className="img-scaled-to-fit"
                      src={content}
                      alt="coupang product"
                      height="160px"
                    />
                  );
                } else {
                  return <></>;
                }
              })()}
            </Stack>
            <Box className="stick-to-top-right-corner">
              <Box className="pt9">
                {closeButtonTimer < 0 ? (
                  <div className="saju-analyzing-card-padded">
                    <img
                      onClick={() => {
                        if (closeButtonClicked) {
                          closeButtonClicked();
                        }
                      }}
                      className="img-scaled-to-fit"
                      src="img/x_button.png"
                      alt="x-button"
                      width="22px"
                      height="22px"
                    />
                  </div>
                ) : (
                  <div className="saju-analyzing-card-padded">
                    <div
                      className={`saju-analyaing-card-close-timer-container`}
                    >
                      <div className={`saju-analyzing-card-centered`}>
                        <span className="color-white bold5">
                          {closeButtonTimer}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
              </Box>
            </Box>
          </Box>
        </div>
      );
    case ContentType.message:
      return (
        <Box key={index}>
          <Box className="bgcolor-white p15 rounded10">
            <span className={`${className}`}>{content}</span>
          </Box>
        </Box>
      );
    case ContentType.tip:
      return (
        <Box key={index}>
          <Box className="bgcolor-white p15 rounded10">
            <span className={`${className}`}>
              <img
                className="img-scaled-to-fit"
                src="img/tip_icon.png"
                alt="tip icon"
                height="13px"
              />{" "}
              {content}
            </span>
          </Box>
        </Box>
      );
  }
};

export default SajuAnalyzingCard;
