import create from 'zustand';

export type SajuInfoModel = {
    shareXmlContent: string, //용도 불명
    myOhangNum: any,
    myOhangText: any,
    chunganJijiOhangNodes: ChunganJijiOhang[],
    chunGan10Sungs: number[],
    jiji10Sungs: number[],
    unSungs: number[]
}

type ChunganJijiOhang = {
  num:number,
  text:string,
  ohang:number
}

interface SajuInfoState {
  sajuInfo: SajuInfoModel|undefined;
  setSajuInfo: (value: SajuInfoModel) => void;
}

export const useSajuInfoStore = create<SajuInfoState> ((set) => ({
    sajuInfo: undefined,
    setSajuInfo: (value:SajuInfoModel) => set(() => ({ sajuInfo: value  }))
}));

export const SajuInfo = ( json:any, shareContent?:string ) => {
  const jsonData = json["drmwork_data"];

  const result:SajuInfoModel = ({
    shareXmlContent: (shareContent) ? shareContent : '',
    myOhangNum: jsonData["menu"]["depth3"]["num"],
    myOhangText: jsonData["menu"]["depth3"]["text"],
    chunganJijiOhangNodes: getChunganJijiOhangNodes(jsonData),
    chunGan10Sungs: getChungan10SungNodes(jsonData),
    jiji10Sungs: getJiji10SungNodes(jsonData),
    unSungs: get12UnsungNodes(jsonData),
  });

  return result;
}

const getChunganJijiOhangNodes = (json:any) => {
  const nodes:ChunganJijiOhang[] = [
    {num: json["menu"]["depth1"]["num1"], text: json["menu"]["depth1"]["text1"], ohang: json["menu"]["depth1"]["ohang1"]},
    {num: json["menu"]["depth1"]["num2"], text: json["menu"]["depth1"]["text2"], ohang: json["menu"]["depth1"]["ohang2"]},
    {num: json["menu"]["depth1"]["num3"], text: json["menu"]["depth1"]["text3"], ohang: json["menu"]["depth1"]["ohang3"]},
    {num: json["menu"]["depth1"]["num4"], text: json["menu"]["depth1"]["text4"], ohang: json["menu"]["depth1"]["ohang4"]},
    {num: json["menu"]["depth2"]["num1"], text: json["menu"]["depth2"]["text1"], ohang: json["menu"]["depth2"]["ohang1"]},
    {num: json["menu"]["depth2"]["num2"], text: json["menu"]["depth2"]["text2"], ohang: json["menu"]["depth2"]["ohang2"]},
    {num: json["menu"]["depth2"]["num3"], text: json["menu"]["depth2"]["text3"], ohang: json["menu"]["depth2"]["ohang3"]},
    {num: json["menu"]["depth2"]["num4"], text: json["menu"]["depth2"]["text4"], ohang: json["menu"]["depth2"]["ohang4"]},
  ];

  return nodes;
}

const getChungan10SungNodes = (json:any) => {
  const nodes:number[] = [
    json["menu"]["sub_depth"]["depth1"]["num1"],
    json["menu"]["sub_depth"]["depth1"]["num2"],
    json["menu"]["sub_depth"]["depth1"]["num3"],
    json["menu"]["sub_depth"]["depth1"]["num4"]
  ]

  return nodes;
}

const getJiji10SungNodes = (json:any) => {
  const nodes:number[] = [
    json["menu"]["sub_depth"]["depth2"]["num1"],
    json["menu"]["sub_depth"]["depth2"]["num2"],
    json["menu"]["sub_depth"]["depth2"]["num3"],
    json["menu"]["sub_depth"]["depth2"]["num4"]
  ]

  return nodes;
}

const get12UnsungNodes = (json:any) => {
  const nodes:number[] = [
    json["menu"]["sub_depth"]["depth3"]["num1"],
    json["menu"]["sub_depth"]["depth3"]["num2"],
    json["menu"]["sub_depth"]["depth3"]["num3"],
    json["menu"]["sub_depth"]["depth3"]["num4"]
  ]

  return nodes;
}

export default SajuInfo;

//   {
//     "?xml": "",
//     "drmwork_data":
//     {
//         "menu":
//         {
//             "title": "사주 속 기본 능력",
//             "depth1":
//             {
//                 "title": "천간",
//                 "num1": 8,
//                 "ohang1": 4,
//                 "text1": "壬",
//                 "num2": 8,
//                 "ohang2": 4,
//                 "text2": "壬",
//                 "num3": 8,
//                 "ohang3": 4,
//                 "text3": "壬",
//                 "num4": 6,
//                 "ohang4": 3,
//                 "text4": "庚"
//             },
//             "depth2":
//             {
//                 "title": "지지",
//                 "num1": 8,
//                 "ohang1": 3,
//                 "text1": "申",
//                 "num2": 0,
//                 "ohang2": 4,
//                 "text2": "子",
//                 "num3": 10,
//                 "ohang3": 2,
//                 "text3": "戌",
//                 "num4": 0,
//                 "ohang4": 4,
//                 "text4": "子"
//             },
//             "depth3":
//             {
//                 "title": "나의오행",
//                 "num": 4,
//                 "text": "수(水)"
//             },
//             "sub_depth":
//             {
//                 "depth1":
//                 {
//                     "title": "천간십성",
//                     "num1": 0,
//                     "num2": 0,
//                     "num3": 10,
//                     "num4": 8
//                 },
//                 "depth2":
//                 {
//                     "title": "지지십성",
//                     "num1": 8,
//                     "num2": 1,
//                     "num3": 6,
//                     "num4": 1
//                 },
//                 "depth3":
//                 {
//                     "title": "십이운성",
//                     "num1": 0,
//                     "num2": 4,
//                     "num3": 2,
//                     "num4": 4
//                 },
//                 "depth4":
//                 {
//                     "title": "십이신살-천간",
//                     "num1": 9,
//                     "num2": 7,
//                     "num3": 5,
//                     "num4": 7
//                 },
//                 "depth5":
//                 {
//                     "title": "십이신살-지지",
//                     "num1": "",
//                     "num2": 1,
//                     "num3": "",
//                     "num4": 1
//                 }
//             }
//         }
//     }
// }