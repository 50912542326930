import {
  JeomsinRequest,
  JeomsinDomain,
  JeomsinEndPoint,
  RequestProps,
  JeomsinMethod,
} from "@Network/network";

interface CrystalBallModel {
  idx: string;
  num: string;
  title: string;
  cate_code: string;
  table_code: string;
  table_ver: string;
  table_csv_link: string;
  img_url: string;
}

interface Response {
  list_sub: CrystalBallModel[];
  result: boolean;
  TTL: string;
}

const apiGetCrystalBallSubList = async (): Promise<Response | undefined> => {
  let request: RequestProps = {
    method: JeomsinMethod.Post,
    domain: JeomsinDomain.Jeomsin,
    endpoint: JeomsinEndPoint.GetCrystalballSubList,
    params: {},
  };

  try {
    const inferred = (await JeomsinRequest(request)) as Response;
    return inferred;
  } catch (e) {
    return undefined;
  }
};

export default apiGetCrystalBallSubList;
export type {
  Response as CrystalBallListResponse,
};
