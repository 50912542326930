export const JeomsinMethod = {
    Get:"GET",
    Post:"POST",
} as const
export type JeomsinMethod = typeof JeomsinMethod[keyof typeof JeomsinMethod];

export const JeomsinDomain = {
    Jeomsin: {release:"https://api1.", debug:"https://a74-www.", value:"jeomsin.co.kr/office/"},
    Stats: {release:"https://stat.", debug:"https://a74-www.", value:"jeomsin.co.kr/"},
    CoupangAdWeb: {release:"", debug:"", value:"https://api1.jeomsin.co.kr/"},
    Weather: {release:"", debug:"", value:"https://weather.jeomsin.co.kr/office/"},
    JeomsinXml: {release:"", debug:"", value:"https://www55.jeomsin.co.kr/"},
    OddConcepts: {release:"", debug:"", value:"https://api.pxl.ai/v3/"},
} as const;
export type JeomsinDomain = typeof JeomsinDomain[keyof typeof JeomsinDomain];

export const JeomsinEndPointType = {
    Param: "param",
    Page: "page",
} as const;
export type JeomsinEndPointType = typeof JeomsinEndPointType[keyof typeof JeomsinEndPointType];

export const JeomsinReturnValueType = {
    JSON: "JSON",
    XML: "XML",
} as const;
export type JeomsinReturnValueType = typeof JeomsinReturnValueType[keyof typeof JeomsinReturnValueType];

export const JeomsinEndPoint = {
    GetBannerListV3: {type:JeomsinEndPointType.Param, value:"api.free.php", paramKey:"method", paramValue:"get.banner.list_v3", returnValueType:JeomsinReturnValueType.JSON},
    GetWeather: {type:JeomsinEndPointType.Param, value:"api.free.php", paramKey:"method", paramValue:"get.weather", returnValueType:JeomsinReturnValueType.JSON},
    GetLunarCalendar: {type:JeomsinEndPointType.Param, value:"api.free.php", paramKey:"method", paramValue:"get.lunarcalendar", returnValueType:JeomsinReturnValueType.JSON},
    SetStatsPageV2: {type: JeomsinEndPointType.Param, value: "api.stats.free.php", paramKey: "method", paramValue: "set.stats.page.v2", returnValueType:JeomsinReturnValueType.JSON},
    GetSajuLoadingText: {type: JeomsinEndPointType.Param, value: "api.free.php", paramKey: "method", paramValue: "get.saju.loading.text.rand", returnValueType:JeomsinReturnValueType.JSON},
    GetTodayVisit: {type: JeomsinEndPointType.Param, value: "api.free.php", paramKey: "method", paramValue: "get.today.visit", returnValueType:JeomsinReturnValueType.JSON},
    RequestCoupangAdReco: {type: JeomsinEndPointType.Page, value: "coupangAdReco.php", paramKey: "", paramValue: "", returnValueType:JeomsinReturnValueType.JSON},
    InfoNew: {type: JeomsinEndPointType.Page, value: "info_new.jsp", paramKey: "", paramValue: "", returnValueType:JeomsinReturnValueType.XML},
    FortuneTodayDayastro: {type: JeomsinEndPointType.Page, value: "dayastro2.jsp", paramKey: "", paramValue: "", returnValueType: JeomsinReturnValueType.XML},
    GetTodayUnseBannerList: {type: JeomsinEndPointType.Param, value: "api.free.php", paramKey: "method", paramValue: "get.today.unse.banner.list", returnValueType: JeomsinReturnValueType.JSON},
    GetCelebBirth: {type: JeomsinEndPointType.Param, value: "api.free.php", paramKey: "method", paramValue: "get.celeb.birth", returnValueType: JeomsinReturnValueType.JSON},
    GetDayJiji2022: {type: JeomsinEndPointType.Page, value: "dayjiji_2022.jsp", paramKey: "", paramValue: "", returnValueType: JeomsinReturnValueType.XML},
    GetFortuneToday: {type: JeomsinEndPointType.Page, value: "timesaju_shuffle.jsp", paramKey: "", paramValue: "", returnValueType: JeomsinReturnValueType.XML},
    GetCounselorNewV3: {type: JeomsinEndPointType.Param, value: "api.free.php", paramKey: "method", paramValue: "get.counselor.new.v3", returnValueType: JeomsinReturnValueType.JSON},
    GetFortuneTodayNum: {type: JeomsinEndPointType.Page, value: "todaynum.jsp", paramKey: "", paramValue: "", returnValueType: JeomsinReturnValueType.XML},
    GetSNSContentCount: {type: JeomsinEndPointType.Param, value: "api.free.php", paramKey: "method", paramValue: "get.sns.content.cnt", returnValueType: JeomsinReturnValueType.JSON},
    GetTalismanPage: {type: JeomsinEndPointType.Param, value: "api.free.php", paramKey: "method", paramValue: "get.talisman.page", returnValueType: JeomsinReturnValueType.JSON},
    GetCrystalballSubList: {type: JeomsinEndPointType.Param, value: "api.free.php", paramKey: "method", paramValue: "get.crystalball_sub.list", returnValueType: JeomsinReturnValueType.JSON},
} as const;
export type JeomsinEndPoint = typeof JeomsinEndPoint[keyof typeof JeomsinEndPoint];