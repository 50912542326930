import { Route, Routes } from "react-router-dom";
import { BottomNavigationProps, HeaderProps } from "@Components/compose";
import Fortune from "@Pages/fortune/views/fortune";
import SajuInfoInput from "@Pages/member_integrated/views/saju_info_input";
import SajuInfoModify from "@Pages/member_integrated/views/saju_info_modify";
import DestinyChartBasic from "@Pages/destiny_chart/views/destiny_chart_basic";
import DestinyChartSpecificsInterpretation from "@Pages/destiny_chart/views/destiny_chart_specifics_interpretation";
import SajuAnalyzing from "@Pages/saju_analysis_loading/views/saju_analyzing";
import TodayUnse from "@Pages/today_unse/today_unse";
import WhatIsOhangGuide from "@Pages/what_is_ohang_guide/what_is_ohang_guide";

export type JeomsinPageUIProps = {
  headerType: React.Dispatch<React.SetStateAction<HeaderProps>>;
  bottomNavParam: React.Dispatch<React.SetStateAction<BottomNavigationProps>>;
  titlebarIconClick?: React.Dispatch<React.SetStateAction<(() => void) | undefined>>;
};

export const JeomsinPagePath = {
    Home: "/",
    SajuInput: "/sajuinput",
    SajuModify: "/sajumodify",
    SajuMyeongsik: "/sajumyeongsik",
    SajuMyeongsikDetail: "/sajumyeongsikdetail",
    SajuAnalyzing: "/sajuanalyzing",
    TodayUnse: "/todayunse",
    WhatIsOhangGuide: "/whatisohangguide",
} as const;
export type JeomsinPagePath =
  (typeof JeomsinPagePath)[keyof typeof JeomsinPagePath];

function JeomsinPageRoutes( props: JeomsinPageUIProps) {
    return (
        <Routes>
            <Route path={JeomsinPagePath.Home} element={<Fortune headerType={props.headerType} bottomNavParam={props.bottomNavParam} titlebarIconClick={props.titlebarIconClick}/>}/>
            <Route path={JeomsinPagePath.SajuInput} element={<SajuInfoInput headerType={props.headerType} bottomNavParam={props.bottomNavParam} />}/>
            <Route path={JeomsinPagePath.SajuModify} element={<SajuInfoModify headerType={props.headerType} bottomNavParam={props.bottomNavParam} />}/>
            <Route path={JeomsinPagePath.SajuMyeongsik} element={<DestinyChartBasic headerType={props.headerType} bottomNavParam={props.bottomNavParam} />} />
            <Route path={JeomsinPagePath.SajuMyeongsikDetail} element={<DestinyChartSpecificsInterpretation headerType={props.headerType} bottomNavParam={props.bottomNavParam} />} />
            <Route path={JeomsinPagePath.SajuAnalyzing} element={<SajuAnalyzing headerType={props.headerType} bottomNavParam={props.bottomNavParam} />} />
            <Route path={JeomsinPagePath.TodayUnse} element={<TodayUnse headerType={props.headerType} bottomNavParam={props.bottomNavParam} />} />
            <Route path={JeomsinPagePath.WhatIsOhangGuide} element={<WhatIsOhangGuide headerType={props.headerType} bottomNavParam={props.bottomNavParam} />} />
        </Routes>
    );
}

export default JeomsinPageRoutes;
