import '../css/saju_info_input.css';

import Box from '@mui/material/Box';
import { BottomNavigationType, ExtraButtonType, InputBoxType, JeomsinInputBox, JeomsinPageType } from '@Components/compose';
import { JeomsinPagePath, JeomsinPageUIProps } from '@Routes/PageRoutes';
import { useEffect, useRef, useState } from 'react';
import { BottomPopup, BottomPopupType } from '@BasicComponents/basic';
import apiInfoNew from '@Network/request/api_info_new';
import CookieKeys from '@Storage/cookie_keys';
import CookieManager from '@Storage/cookie_manager'
import DimLayer from '@BasicComponents/dim_layer/dim_layer';
import { useNavigate } from 'react-router-dom';
import { UserInfo } from '@Storage/data_types';
import SajuInfo, { useSajuInfoStore } from '@Storage/data_model/saju_info_model';


function SajuInfoInput({ headerType,bottomNavParam }: JeomsinPageUIProps) {
  const firstStep = 1;
  const lastStep = 4;
  
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState<UserInfo>({
    name: "",
    gender: "",
    birthYear: "",
    birthMonth: "",
    birthDay: "",
    birthTime: "",
    birthMinute: "",
    calendarType: -1,
    calendarTypeText: "",
    isLeapMonth: false,
    isLeapMonthText: "",
  });
  
  const defualtNextButtonTitle = '다음';
  const [nextButtonTitle, setNextButtonTitle] = useState(defualtNextButtonTitle);
  const [isNextButtonDisabled, setNextButtonDisabled] = useState(true);
  const [isNextButtonHidden, setNextButtonHidden] = useState(false);
  const [stepNumber, setStepNumber] = useState(firstStep);

  const [isDimLayerOpen, setDimLayerOpen] = useState(false);
  const [isOpenPopup, setOpenPopup] = useState(false);
  const [isSNSPopupOpen, setSNSPopupOpen] = useState(false);

  const [isFirstStepValidate, setFirstStepValidate] = useState(false);
  const isSecondStepValidate = useRef(false);
  const [isThirdStepValidate, setThirdStepValidate] = useState(false);
  const [isForthStepValidate, setForthStepValidate] = useState(false);

  // const {setSajuInfo} = useSajuInfoStore();

  const calendarType = useRef(-1);
  const isLeapMonth = useRef(false);

  useEffect(() => {
    console.log(`useEffect ${isNextButtonHidden}`);
    headerType({type: JeomsinPageType.SajuInput});
    bottomNavParam({
      type:BottomNavigationType.Button, 
      title:nextButtonTitle,
      hide:isNextButtonHidden,
      onClick() { 
          nextStep(stepNumber); 
      },
      buttonDisable: isNextButtonDisabled
    });
  }, [
    nextButtonTitle,
    isNextButtonHidden,
    isNextButtonDisabled,
    stepNumber,
    isFirstStepValidate,
    isThirdStepValidate,
    isForthStepValidate,
  ]);


  const nextStep = (currentStepNumber:number) => {
    if(currentStepNumber > lastStep) {
      return;
    }

    console.log("nextStep : " + currentStepNumber);

    let validate = false;
    switch(currentStepNumber){
      case 1:
        validate = isFirstStepValidate;
        if(validate) {
          setNextButtonHidden(true);
        }
        break;
      case 2:
        validate = isSecondStepValidate.current;
        console.log("isSecondStepValidate : " + validate);
        if(validate) {
          setNextButtonTitle('생년월일을 입력해 주세요.'); 
          setNextButtonDisabled(true);
          setNextButtonHidden(false);
        }
        break;
      case 3:
        validate = isThirdStepValidate;
        break;
      case 4:
        validate = isForthStepValidate;
        break;
      default:
        validate = false;
        break;
    }
    console.log("validate : ", validate);
    if(validate && currentStepNumber == 4) {
      setDimLayerOpen(true);
    } else if(validate) {
      setStepNumber(currentStepNumber+1);
    }
  }

  //material
  return (
    <Box className="saju-info-input-wrap header-type-sub">
      <Box className="main-contents">
        <Box className="step mb32">
          <span className='ts13 bold7 blue03'>STEP {stepNumber}</span><span className='ts13 bold4 gray'> / 4</span>
        </Box>
        <Box className="guide-text mb40">
          {getGuideText(stepNumber, userInfo? userInfo.name : "")}
        </Box>
        { isThirdStepValidate && stepNumber >= 4 ?
          <Box className="mb40">
            <JeomsinInputBox
              type={InputBoxType.Time}
              label='태어난 시간'
              onValidate={ (isValidate, value) => {
                setForthStepValidate(isValidate);
                console.log(`isThirdStepValidate ${isValidate}`);
                const timeAndMinute = value.split(":");

                if(isValidate){
                  setUserInfo({
                    name: userInfo.name,
                    gender: userInfo.gender,
                    birthYear: userInfo.birthYear,
                    birthMonth: userInfo.birthMonth,
                    birthDay: userInfo.birthDay,
                    birthTime: (timeAndMinute.length < 2) ? "0" : timeAndMinute[0],
                    birthMinute: (timeAndMinute.length < 2) ? "0" : timeAndMinute[1],
                    calendarType: userInfo.calendarType,
                    calendarTypeText: userInfo.calendarTypeText,
                    isLeapMonth: userInfo.isLeapMonth,
                    isLeapMonthText: userInfo.isLeapMonthText
                  });

                  setNextButtonDisabled(false);
                  setNextButtonTitle("입력완료")
                } else {
                  setNextButtonDisabled(true);
                  setNextButtonTitle('태어난 시간을 선택해 주세요.')
                }
              }}
            />
          </Box>
          : <></>
        }
        { isSecondStepValidate && stepNumber >= 3 ?
          <Box className="mb40">
            <JeomsinInputBox
              type={InputBoxType.Date}
              label='생년월일'
              subLabel='사주분석은 만 나이로 적용돼요.'
              helpText='생년월일 8자리'
              errorText='다시 입력해 주세요.'
              hintText='19950505'
              onValidate={ (isValidate, value, extraValue) => {
                console.log(`setThirdStepValidate value : ${value}` );
                if(extraValue) {
                  calendarType.current = extraValue["calendarType"];
                  if(calendarType.current == 0) { //양력
                    isLeapMonth.current = false; //평달
                  } else { //음력
                    isLeapMonth.current = extraValue["isLeapMonth"];
                  }
                }
                
                if(isValidate && calendarType.current > -1) { //생년월일 입력 및 양력,음력 선택
                  const ymd = value.split(".");
                  setUserInfo({
                    name: userInfo.name,
                    gender: userInfo.gender,
                    birthYear: ymd[0],
                    birthMonth: ymd[1],
                    birthDay: ymd[2],
                    birthTime: userInfo.birthTime,
                    birthMinute: userInfo.birthMinute,
                    calendarType: calendarType.current,
                    calendarTypeText: (calendarType.current == 0 ? "양력" : "음력"),
                    isLeapMonth: isLeapMonth.current,
                    isLeapMonthText: (isLeapMonth.current ? "윤달" : "평달"),
                  });

                  setThirdStepValidate(true);
                  setNextButtonTitle('다음'); 
                  setNextButtonDisabled(false);
                } else if(!isValidate && calendarType.current > -1) { //생년월일 미입력 및 양력,음력 선택
                  setNextButtonTitle('생년월일을 입력해주세요.'); 
                  setNextButtonDisabled(true);
                } else if(isValidate && calendarType.current == -1) { //생년월일 입력 및 양력,음력 미선택
                  setNextButtonTitle('양력 또는 음력을 선택해 주세요.'); 
                  setNextButtonDisabled(true);
                } else {
                  setThirdStepValidate(false);
                  setNextButtonDisabled(true);
                }
              }}
            />
          </Box>
          : <></>
        }
        { isFirstStepValidate && stepNumber >= 2 ?
          <Box className="mb40">
            <JeomsinInputBox
              type={InputBoxType.Select}
              label='성별'
              onSelectBoxClick={ (event) => {
                let selectedVal = event.currentTarget.value;
                if(selectedVal) {
                  console.log("selectedVal : " + selectedVal);
                  setUserInfo({
                    name: userInfo.name,
                    gender: selectedVal,
                    birthYear: userInfo.birthYear,
                    birthMonth: userInfo.birthMonth,
                    birthDay: userInfo.birthDay,
                    birthTime: userInfo.birthTime,
                    birthMinute: userInfo.birthMinute,
                    calendarType: userInfo.calendarType,
                    calendarTypeText: userInfo.calendarTypeText,
                    isLeapMonth: userInfo.isLeapMonth,
                    isLeapMonthText: userInfo.isLeapMonthText
                  });

                  isSecondStepValidate.current = true;
                  nextStep(stepNumber);
                } else {
                  console.log("isFirstStepValidate");
                  setNextButtonHidden(true);
                  isSecondStepValidate.current = false;
                }
              }}
            />
          </Box>
          : <></>
        }
        <Box className="mb40">
          <JeomsinInputBox
            type={InputBoxType.Text}
            label='이름'
            helpText="한글 최대 6자"
            errorText='이름은 최대 6자까지 입력 가능합니다.'
            maxLength={6}
            inputRegexp={/^[ㄱ-ㅎ|가-힣]/}
            resultRegexp={/^[가-힣ᆞㆍᆢ]+$/}
            onValidate={ (isValidate, value) => {
              console.log(`test first 123123 ${value}`);
              setFirstStepValidate(isValidate);
              if(isValidate){
                setUserInfo({
                  name: value,
                  gender: userInfo.gender,
                  birthYear: userInfo.birthYear,
                  birthMonth: userInfo.birthMonth,
                  birthDay: userInfo.birthDay,
                  birthTime: userInfo.birthTime,
                  birthMinute: userInfo.birthMinute,
                  calendarType: userInfo.calendarType,
                  calendarTypeText: userInfo.calendarTypeText,
                  isLeapMonth: userInfo.isLeapMonth,
                  isLeapMonthText: userInfo.isLeapMonthText
                });

                setNextButtonDisabled(false);
                setNextButtonTitle(defualtNextButtonTitle)
              } else if(value.length == 0) {
                setNextButtonDisabled(true);
                setNextButtonTitle(defualtNextButtonTitle)
              } else {
                setNextButtonDisabled(true);
                setNextButtonTitle('입력한 내용을 확인해 주세요.')
              }
            }}
          />
        </Box>
      </Box>
      <DimLayer isOpen={isDimLayerOpen} 
        onLayerEnter={()=> setOpenPopup(true)}
        onClose={()=> {setDimLayerOpen(false)}}
      >
      </DimLayer>
      <BottomPopup
        isOpen={isOpenPopup} 
        type={BottomPopupType.TermsOfUse}
        onClose={()=> setOpenPopup(false)}
        notDimmed={true}
        firstButtonTitle='시작하기'
        onClickFirstButton={async ()=> {
          console.log(`userInfo : ${JSON.stringify(userInfo)}`)
          const response = await apiInfoNew(userInfo);
          const mySajuInfo = SajuInfo(response);
          // setSajuInfo(mySajuInfo); //TODO 전역 저장이 필요한가? 로컬저장으로 충분한가? 판단필요

          userInfo.saju_info = mySajuInfo;
          CookieManager.SetJSON(CookieKeys.representative_user_info, userInfo);
          // CookieManager.SetJSON(CookieKeys.representative_fortune_info, mySajuInfo);//로컬 저장
          CookieManager.SetJSON(CookieKeys.user_info_list, [userInfo]);
          // CookieManager.SetJSON(CookieKeys.user_fortune_info_list, [userInfo]);
          setOpenPopup(false)
          setTimeout(() => setSNSPopupOpen(true), 500);
        }}
      />
      <BottomPopup 
        isOpen={isSNSPopupOpen}
        type={BottomPopupType.SnsIntegration}
        title='SNS 연동하기'
        contents={"연동하지 않으면, 사주정보가 저장되지 않아요😢\n단 한 번의 연동으로 더 편리하게 사용하세요!"}
        notDimmed={true}
        onClose={()=> {
          setSNSPopupOpen(false);
          setDimLayerOpen(false);
          setTimeout(() => navigate(JeomsinPagePath.Home), 300);
        }}
      />
    </Box>
  );
}

const getGuideText = ( stepNumber:number, userName:string ) => {
  let result = null; 

  switch(stepNumber) {
    case 1:
      result = <><span className='ts22 bold7'>어떤 이름으로</span><br /><span className='ts22 bold7'>불러 드릴까요?</span></>; 
      break;
    case 2:
      result = <><span className='ts22 bold7'>{userName}님의</span><br /><span className='ts22 bold7'>성별을 알려주세요.</span></>; 
      break;
    case 3:
    case 4:
      result = <><span className='ts22 bold7'>{userName}님의</span><br /><span className='ts22 bold7'>사주정보를 알려주세요.</span></>; 
      break;
  }

  return result;
}

export default SajuInfoInput;

