import { Box, Stack } from "@mui/material";
import useSharedInfoStore from "@Network/shared_info";
import { useContext } from "react";
import SharedInfoContext from "@Network/shared_info";
import css from "./share_banner.module.css";

function addCommas(nStr: string) {
  var offset = nStr.length % 3;
  if (offset === 0)
    return (
      nStr.substring(0, offset) +
      nStr.substring(offset).replace(/([0-9]{3})(?=[0-9]+)/g, "$1,")
    );
  else
    return (
      nStr.substring(0, offset) +
      nStr.substring(offset).replace(/([0-9]{3})/g, ",$1")
    );
}

// sh_070600 => 오늘의 운세 페이지
const ShareBanner = (countCode: string = "sh_070600") => {
  const { snsCount } = useSharedInfoStore();

  const count =
    snsCount?.cnt_list.filter((cntInfo) => cntInfo.cnt_code === countCode).at(0)
      ?.cnt ?? 0;

  return (
    <div className={`${css.fill_width_z_stack}`} onClick={() => {}}>
      <Stack
        sx={{
          position: "absolute",
          width: "100%",
          alignItems: "center",
          top: "50%",
          transform: "translateY(-50%)",
        }}
        spacing="5px"
      >
        <span className="ts20 bold6">나의 운세를 공유해보세요!</span>
        <span className="ts15 bold4">
          {addCommas(`${count}`)}명이 공유하였습니다.
        </span>
      </Stack>

      <img
        style={{ objectFit: "contain", width: "100%" }}
        src="images/common/result_share_banner.png"
        alt="share banner"
      />
    </div>
  );
};

export default ShareBanner;
