import { JeomsinRequest, JeomsinDomain, JeomsinEndPoint } from "@Network/network";

const apiLunarCalendar = async (
  param:string,
) => {

  const data = param.split('.');
  console.log("data : ", data);

  const response:any = await JeomsinRequest({
    domain: JeomsinDomain.Jeomsin,
    endpoint: JeomsinEndPoint.GetLunarCalendar,
    params: {
      birthyear:data[0],
      birthmonth:data[1],
      birthday:data[2]
    },
  });
  
  // leap_month 윤달 여부
  return new Promise((resolve, reject) => { 
    if(response) {
      resolve(response.leap_month);
    } else {
      reject("N");
    }

  });
}

export default apiLunarCalendar;