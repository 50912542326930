import { useEffect, useRef, useState } from "react";
import css from "./checkbox_group.module.css";
import React from "react";

type CheckBoxGroupProps = {
    checkboxItems: CheckBoxItemProps[];
    isChecked?:boolean;
    checkedIcon?:any;
    uncheckedIcon?:any;
    groupCheckBoxButton?: CheckBoxItemProps;
    onGroupClick?: (isChecked:boolean) => void;
};

type CheckBoxItemProps = {
    contents: CheckBoxContentProps;
    value: any;
    required?: boolean;
    onClick?: (isChecked:boolean) => void;
}

type CheckBoxContentProps = {
    pre:string;
    link?:string;
    post?:string;
    onClick?:() => void;
}
  
const CheckBoxGroup: React.FC<CheckBoxGroupProps> = ( props ) => {
    const [isGroupChecked, setGroupChecked] = useState(false);
    const [isCheckedList, setCheckedList] = useState<boolean[]>([]);

    useEffect(() => {
        setCheckedList(Array(props.checkboxItems.length).fill(false));
    },[props.checkboxItems.length]);
    
    const updateChecked = (index:number, value:boolean) => {
        const updateValue = [...isCheckedList];
        updateValue[index] = value;

        const result = updateValue.filter(value => value == false);
        setGroupChecked(result.length == 0 ? true : false);
        setCheckedList(updateValue);
    };

    return (
        <div>
            <div className={css.checkbox_wrap}>
                {
                    props.checkboxItems.map((item, index) => {
                        return CheckBoxItem(item, index, isCheckedList[index], updateChecked);
                    })
                }
            </div>
            <div>
                {
                    props.groupCheckBoxButton ?
                        <div className={css.agreement_wrap}>
                            <button 
                                value={props.groupCheckBoxButton.value} 
                                onClick={()=> {
                                    const newValue = !isGroupChecked;
                                    const updateValue = [...isCheckedList];
                                    updateValue.map((value, index) => {
                                        updateValue[index] = newValue;
                                    });

                                    setGroupChecked(newValue);
                                    setCheckedList(updateValue);
                                    
                                    if(props.groupCheckBoxButton?.onClick)
                                        props.groupCheckBoxButton?.onClick(newValue);
                                }}
                                className={isGroupChecked ? css.checked : ""}
                            >
                                    {props.groupCheckBoxButton.contents.pre}
                                    {props.groupCheckBoxButton.contents.link ? <span className={css.underline} onClick={props.groupCheckBoxButton!.contents.onClick}>{props.groupCheckBoxButton.contents.link}</span> : <></> }
                                    {props.groupCheckBoxButton.contents.post ? props.groupCheckBoxButton.contents.post : <></> }
                            </button>
                        </div>
                    : <></>
                }
            </div>
        </div>
    );
}

const CheckBoxItem = ( item:CheckBoxItemProps, index:number, isChecked:boolean, updateChecked:(index: number, value: boolean) => void) => {
    return (
        <button 
            key={index} 
            onClick={()=>{{
                updateChecked(index, !isChecked)

                if(item.onClick)
                    item.onClick(!isChecked);
            }}}
            className={isChecked ? css.checked : ""}
        >
            {item.contents.pre}
            {item.contents.link ? <span className={css.underline} onClick={item.contents.onClick}>{item.contents.link}</span> : <></> }
            {item.contents.post ? item.contents.post : <></> }
        </button>
    )
}


export default CheckBoxGroup;
  