import css from "./profile.module.css";
import { FC, useState } from "react";
import { OhangColorButton } from "./o_hang_color_button";
import SajuMyungsikData, { SajuMyungsikInfo } from "@Storage/data_model/saju_myungsik_data";
import { BottomPopup, BottomPopupType } from "@BasicComponents/basic";
import { JeomsinPagePath } from "@Routes/PageRoutes";
import { useNavigate } from "react-router-dom";
import { SajuInfoModel } from "@Storage/data_model/saju_info_model";

interface ProfileProps {
  name: string;
  gender: string;
  birthYear: string;
  birthMonth: string;
  birthDay: string;
  birthHour: string;
  birthMinute: string;
  calendar: string;
  element: SajuInfoModel;
  clickHandler?: () => void;
  showSajuProfileSelectorClickAction?: () => void;
}

const Profile: FC<ProfileProps> = ({
  name,
  gender,
  birthYear,
  birthMonth,
  birthDay,
  birthHour,
  birthMinute,
  calendar,
  element,
  clickHandler,
  showSajuProfileSelectorClickAction,
}) => {
  const navigate = useNavigate();
  const sajuMyungsikDatas = SajuMyungsikData();
  const [isShowOhangPopup, setShowOhangPopup] = useState<boolean>(false);
  
  const topbar = () => (
    <div className={`${css.spaced}`}>
      <span className="bold7 ts16 pr6">사주명식</span>
      <div
        className={`${css.info_icon_container}`}
        onClick={() => {
          if (clickHandler) {
            clickHandler();
          }
        }}
      >
        <img
          className={`${css.info_icon}`}
          src="images/saju_multi_information/3.0x/info.png"
          alt="info logo"
        />
      </div>
      <div className={`${css.growable}`} />
      <div
        onClick={() => {
          if (showSajuProfileSelectorClickAction) {
            showSajuProfileSelectorClickAction();
          }
        }}
      >
        <div
          className={`${css.change_button} ${css.centered} jeomsin-bgcolor-bright-yellow rounded30 bold5`}
        >
          <span className="ts11">변경</span>
        </div>
      </div>
    </div>
  );

  const myOhang = () => (
    <div className={`${css.family_name_info} ${css.centered}`}>
      <OhangColorButton
        ohang={element.myOhangNum}
        text={element.myOhangText.substring(2,3)}
        clickHandler={()=> setShowOhangPopup(true)}
      />
      <div className={`${css.ohang_look}`}>
        <span className={`ts13 bold6`}>나의 오행</span>
      </div>
    </div>
  );

  const nameGenderAndBirth = () => (
    <div className={`${css.name_gender_and_birth_view}`}>
      <div className={`${css.sajoo_profile_name_hstack}`}>
        <span className={`${css.name}`}>{name}</span>
        <span className={`jeomsin-color-light-gray ${css.identity}`}>
          (본인)
        </span>
      </div>
      <div className={`${css.spacer_with_12}`} />
      <span className={`ts12`}>{gender}</span>
      <div className={`${css.spacer_with_6}`} />
      <span className={`ts12`}>
        {birthYear}.{birthMonth}.{birthDay} ({calendar})
      </span>
      <div className={`${css.spacer_with_6}`} />
      <span className={`ts12`}>
        {birthHour}시 {birthMinute}분
      </span>
    </div>
  );

  const myOhangPopup = (value:SajuMyungsikInfo) => (
    <BottomPopup
    title={`${value?.classTitle ?? ""} [${
      value?.classChineseChar ?? ""
    }]`}
    contents={{
      definition: value?.description ?? "",
      buttonTitle: "자세히보기",
    }}
    isOpen={isShowOhangPopup}
    type={BottomPopupType.DestinyChartSpecifics}
    blurred={true}
    onClose={() => {
      setShowOhangPopup(false);
    }}
    onClickFirstButton={() => {
      navigate(JeomsinPagePath.WhatIsOhangGuide);
    }}
    />
  );

  return (
    <div className={`${css.sajoo_profile} bgcolor-white`}>
      <div className={`${css.inner_padding}`}>
        {topbar()}
        <div className={`${css.user_info_view}`}>
          {myOhang()}
          {nameGenderAndBirth()}
        </div>
      </div>
      {myOhangPopup(sajuMyungsikDatas.birthMyOhangData[element.myOhangNum])}
    </div>
  );
};

export default Profile;
