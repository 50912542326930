import { FC, useEffect, useState } from "react";
import css from "./unse_circle_view.module.css"

export const TimeType = {
    Moring: 1,
    Afternoon: 2,
    Night: 3
} as const
export type TimeType = (typeof TimeType)[keyof typeof TimeType];

type UnseCircleProps = {
    unseScore:number|undefined;
    timeType:TimeType;
};

const UnseCircleView: FC<UnseCircleProps> = ( props ) => {
    const score = props.unseScore ?? 0;
    const circleRadius = 35;
    const circleSize = 2  * Math.PI * circleRadius
    const progress = score / 100;
    // let dashoffset = 0;
    console.log("circleSize : " + circleSize);

    const centerX = 45;
    const centerY = 45;

    let typeName;
    let typeTime;

    if( props.timeType == TimeType.Afternoon ) {
        typeName = "오후";
        typeTime = "12:00~17:59";
    } else if(props.timeType == TimeType.Night) {
        typeName = "밤";
        typeTime = "18:00~23:59";
    } else {
        typeName = "오전";
        typeTime = "07:00~11:59";
    }

    // const currentTime = new Date("HH:mm").toISOString();
    // console.log("currentTime : " + currentTime);
  
    const [dashoffset, setDashoffset] = useState(220);
    // State for cap position
    const [capPosition, setCapPosition] = useState({ x: centerX, y: centerY - circleRadius });
    const [capTransform, setCapTransform] = useState(`translate(${capPosition.x}px, ${capPosition.y}px)`);

    const updateCapPosition = (percentage:number) => {
        const angle = (percentage / 100 * 360) * (Math.PI / 180); // Progress angle in degrees
        const theta = (0 * Math.PI / 180) + angle; // Convert angle to radians, starting from 0deg

        // Calculate the new X and Y coordinates
        const newX = centerX + circleRadius * Math.cos(theta);
        const newY = centerY + circleRadius * Math.sin(theta);
        // radius + radius * math.cos(theta),
        // adius + radius * math.sin(theta)
        // Update cap position state
        setCapPosition({ x: newX, y: newY });
        setCapTransform(`translate(${capPosition.x}px, ${capPosition.y}px)`);
    };

    useEffect(() => {
        // updateCapPosition(score);
        setDashoffset(circleSize * (1-progress));
    }, [props.unseScore]);

    // 3.14159265359;
    //CIRCUMFERENCE = 2 * Math.PI * RADIUS;
//progress = value / 100;
//dashoffset = CIRCUMFERENCE * (1 - progress);
//   const hashTags = props.counselor.CO_History.split("|");
//   let payCounselBtnTextTitle = "바로 상담";
//   let counselStateIcon = "";
//   let counselButtonDisabled = false;

//   switch (props.counselor.conn_state ?? 0) {
//     case 1:
//       //비회원 로직
//       if (props.counselor.yn_freecall 
//         && props.counselor.yn_freecall != "" 
//         && props.counselor.yn_freecall == 'Y') 
//       {
//         payCounselBtnTextTitle = '3분 무료 상담';
//       }
//       break;
//     case 2:
//       payCounselBtnTextTitle = '부재중';
//       counselStateIcon = css.state_consulting
//       counselButtonDisabled = true;
//       break;
//     case 3:
//       payCounselBtnTextTitle = '상담중';
//       counselStateIcon = css.state_consulting
//       counselButtonDisabled = true;
//       break;
//     default:
//       break;
//   }

  return (
    // <div className={css.circle_area}>
    <div className={css.circle_wrap}>
        { checkMyTime(props.timeType) ?
        <div className={css.circle}>
            <div className={css.pie}>
                <svg>
                    {/* <circle cx={centerX} cy={centerY} r={circleRadius} style={{strokeDashoffset:dashoffset}}></circle> */}
                    <circle cx={centerX} cy={centerY} r={circleRadius} style={{strokeDashoffset:dashoffset}}></circle>
                    {/* <circle className={css.dot} cx={centerX} cy={centerY} r={circleRadius} /> */}
                        {/* <g className={css.cap_wrap}> */}
                        {/* <circle r="3" cx={capPosition.x} cy={capPosition.y} className={css.cap}></circle> */}
                        {/* </g> */}
                </svg>
            </div>
            <div className={css.wrap_txt}>{props.unseScore}</div>
        </div>
        :
        <div className={css.unse_deactivate}>
            <img className={css.unse_deactivate} src='images/saju_analyzing/profile.png' alt=""></img>
        </div>
        }
        <div className={`${css.circle_title} ${css.type}`}>{typeName}</div>
        <div className={`${css.circle_title} ${css.time}`}>{typeTime}</div>
    </div>
    // </div>
  );
}

const checkMyTime = (timeType:TimeType) => {
    let result = false;
    const date = new Date();
    console.log(date.getHours() + " : " + date.getMinutes());


    const hour = date.getHours();
    const minutes = date.getMinutes();
    switch(timeType) {
        case TimeType.Moring:
            result = (hour >= 7 && hour <= 23); //00시부터는 일자가 넘어가 초기화
            break;
        case TimeType.Afternoon:
            result = (hour >= 12 && hour <= 23); //00시부터는 일자가 넘어가 초기화
            break;
        case TimeType.Night:
            result = (hour >= 18 && hour <= 23); //00시부터는 일자가 넘어가 초기화
            break;
    }

    return result
}

export default UnseCircleView;