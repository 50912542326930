import {
  JeomsinRequest,
  JeomsinDomain,
  JeomsinEndPoint,
  RequestProps,
  JeomsinMethod,
} from "@Network/network";

interface ProductData {
  productId: number;
  productName: string;
  productPrice: number;
  productImage: string;
  productUrl: string;
  categoryName: string;
  isRocket: boolean;
  isFreeShipping: boolean;
}

interface CoupangAdRecoResponse {
  rCode: string;
  rMessage: string;
  data: ProductData;
}

const apiRequestCoupangAdReco = async (
  channelId?: string,
  imageSize: string = "320x320",
  viewCode?: string
): Promise<CoupangAdRecoResponse | undefined> => {
  const emptyAdId = "";

  let params: {
    sub_id?: string;
    ad_id?: string;
    view_code?: string;
    image_size?: string;
  } = {};

  if (channelId) {
    params.sub_id = channelId;
  }

  if (viewCode) {
    params.view_code = viewCode;
  }

  params.image_size = imageSize;
  params.ad_id = emptyAdId;

  let request: RequestProps = {
    method: JeomsinMethod.Post,
    domain: JeomsinDomain.Jeomsin,
    endpoint: JeomsinEndPoint.RequestCoupangAdReco,
    params: params,
  };

  const response: any = await JeomsinRequest(request);

  try {
    const inferred = response as CoupangAdRecoResponse;
    return inferred;
  } catch (_) {
    return undefined;
  }
};

export { apiRequestCoupangAdReco };
export type { CoupangAdRecoResponse };
