import { Stack, Box } from "@mui/material";
import { FC } from "react";
import "../css/o_hang_color_button.css";

const OhangType = {
  tree: 0,
  fire: 1,
  soil: 2,
  gold: 3,
  water: 4,
} as const;
type OhangType = (typeof OhangType)[keyof typeof OhangType];

type OhangTypeColor = {
  type: OhangType,
  bgColor: string,
  textColor: string,
  text: string
}
const ohangTypeColors:OhangTypeColor[] = [
  {type: OhangType.tree, bgColor: "#4370CE", textColor:"#FFFFFF", text: '木 , 나무'},
  {type: OhangType.fire, bgColor: "#EF5C5C", textColor:"#FFFFFF", text: '火 , 불'},
  {type: OhangType.soil, bgColor: "#FFDE00", textColor:"#000000", text: '土 , 흙'},
  {type: OhangType.gold, bgColor: "#CECECE", textColor:"#000000", text: '金 , 쇠'},
  {type: OhangType.water, bgColor: "#565656", textColor:"#FFFFFF", text: '水 , 물'}
];

interface OhangColorButtonProps {
  ohang: number;
  text: string;
  bottomText?: string;
  width?: string | number;
  height?: string | number;
  clickHandler?: () => void;
}

const OhangColorButton: FC<OhangColorButtonProps> = ({
  ohang,
  text,
  bottomText,
  width = "70px",
  height = "70px",
  clickHandler,
}) => {
  const selectedOhang = ohangTypeColors[ohang];

  let buttonWidth: string;
  if (typeof width === "number") {
    buttonWidth = `${width}px`;
  } else {
    buttonWidth = width;
  }

  let buttonHeight: string;
  if (typeof height === "number") {
    buttonHeight = `${height}px`;
  } else {
    buttonHeight = height;
  }

  const subFontSize = Number(buttonWidth.split("px")[0]) >= 80 ? 13 : 10;
  const mainFontSize = Number(buttonWidth.split("px")[0]) >= 80 ? 29 : 25;

  return (
    <button
      style={{
        outline: "none",
        border: "none",
        backgroundColor: "transparent",
      }}
      onClick={() => {
        if (clickHandler) {
          clickHandler();
        }
      }}
    >
      <Box
        className="rounded17 centered"
        bgcolor={selectedOhang.bgColor}
        width={buttonWidth}
        height={buttonHeight}
      >
        {
          bottomText ?
          <Stack className="stack-centered" direction="column" color={selectedOhang.textColor}>
            <span className={`ts${subFontSize}`}>{selectedOhang.text}</span>
            <span className={`ts${mainFontSize} bold6`}>{text}</span>
            <span className={`ts${subFontSize}`}>{bottomText}</span>
          </Stack>
          :
          <Stack className="stack-centered" direction="column" color={selectedOhang.textColor}>
            <span className={`ts${mainFontSize} bold6`}>{text}</span>
          </Stack>
        }
      </Box>
    </button>
  );
};

export { OhangColorButton };
export type { OhangColorButtonProps };
