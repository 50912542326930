import { useEffect, useRef, useState, ReactNode } from "react";
import css from "./bottom_popup.module.css";
import "@CSS/animation/fade.css";
import "@CSS/animation/slide_in_bottom.css";
import { CSSTransition } from "react-transition-group";
import DestinyChartSpecifics from "@Pages/destiny_chart/views/destiny_chart_specifics";
import { BottomButton, CheckBoxGroup } from "@BasicComponents/basic";
import CustomButton from "@BasicComponents/custom_button/custom_button";
import { companyTitle, familyTitle, noneTitle, relationshipTitle } from "@Storage/data_model/relation_info_model";

type BottomPopupProps = {
  isOpen: boolean;
  type: BottomPopupType;
  title?: string;
  contents?: any;
  firstButtonTitle?: string;
  onClickFirstButton?: () => void;
  secondButtonTitle?: string;
  onClickSecondButton?: () => void;
  onClose?: (returnValue?:any) => void;
  blurred?: boolean;
  notDimmed?: boolean;
  children?: ReactNode;
};

export const BottomPopupType = {
  Onboarding: "Onboarding",
  Login: "Login",
  SnsIntegration: "SnsIntegration",
  DestinyChartSpecifics: "DestinyChartSpecifics",
  TermsOfUse: "TermsOfUse",
  PrivacyUsage: "PrivacyUsage",
  ReleationSelcet: "ReleationSelcet"
} as const;
export type BottomPopupType = (typeof BottomPopupType)[keyof typeof BottomPopupType];

const BottomPopup: React.FC<BottomPopupProps> = (props) => {
  const popupWrapRef = useRef(null);
  const popupContentRef = useRef(null);
  const [showText, setShowText] = useState(true);

  return (
    <CSSTransition
      nodeRef={popupWrapRef}
      in={props.isOpen}
      timeout={{
        enter: 400,
        exit: 400,
      }}
      classNames="fade"
      mountOnEnter
      unmountOnExit
      onEnter={() => setShowText(true)}
      onExit={() => setShowText(false)}
    >
      <section
        className={`${css.modal} ${
          props.blurred ?? false ? css.blurred : css.unblurred
        }`}
      >
        <div
          className={`${css.dim} ${props.notDimmed ? "" : css.dim_color}`}
          onClick={()=> {
            if (props.onClose) {
              props.onClose();
            }
          }}
          ref={popupWrapRef}
        >
        </div>
        <CSSTransition
          nodeRef={popupContentRef}
          in={showText}
          timeout={{
            enter: 200,
            exit: 200,
          }}
          classNames="slide-in-bottom"
        >
          {PopupType(props, popupContentRef)}
        </CSSTransition>
      </section>
    </CSSTransition>
  );
};

const PopupType = (
  props: BottomPopupProps,
  ref: React.MutableRefObject<null>
) => {
  let returnValue: ReactNode;
  const [isDisable, setDisable] = useState(true);

  //TODO 특정 팝업을 위한 변수, 추후 상태관리 라이브러리 등을 통해 분리 필요
  let privacyUsageAgreement = useRef(false);
  let ageFourteenOrOlderAgreement = useRef(false);
  let receiveEventAndBenefitNotificationsAgreement = useRef(false);
  let termsOfService = useRef(false);
  
  const [isOpenPopup, setOpenPopup] = useState(false);
  const [selectValue, setSelectValue] = useState<string | null>(null);
  const [selectText, setSelectText] = useState<string | null>(null);

  switch (props.type) {
    case BottomPopupType.Onboarding:
      returnValue = (
        <div className={css.modal_content} ref={ref}>
          <div className={css.close_button_wrap}>
            <img
              className={css.close_button}
              src="images/member_integrated/mypage/setting/close.png"
              onClick={()=> {
                if (props.onClose) {
                  props.onClose();
                }
              }}
            />
          </div>
          <div className={css.title}>{props.title}</div>
          <div className={`${css.contents} mb40`}>{props.contents}</div>
          <div className={css.button_wrap}>
            <div
              className={`${css.button} bgcolor-yellow`}
              onClick={props.onClickFirstButton}
            >
              점신 시작하기
            </div>
            <div
              className={`${css.button} bgcolor-gray`}
              onClick={props.onClickSecondButton}
            >
              기존 회원 로그인하기
            </div>
          </div>
        </div>
      );
      break;
    case BottomPopupType.Login:
      returnValue = (
        <div className={css.modal_content}>
          <div className={css.close_button_wrap}>
            <img
              className={css.close_button}
              src="images/member_integrated/mypage/setting/close.png"
              onClick={()=> {
                if (props.onClose) {
                  props.onClose();
                }
              }}
            />
          </div>
          <div className={css.title}>{props.title}</div>
          <div className={css.login_text_wrap}>
            <div className={css.login_text}>
              <span className={css.login_main_text}>SNS 계정으로 로그인</span>
              <span className={`${css.login_sub_text} mt5`}>
                연동했던 SNS계정을 선택해주세요
              </span>
            </div>
            <div className={`${css.find_account} ${css.login_sub_text}`}>
              계정찿기
            </div>
          </div>
          <div className={`${css.sns_wrap} mt20 mb48`}>
            <img src="images/member_integrated/member/naver.png" />
            <img src="images/member_integrated/member/kakao.png" />
            <img src="images/member_integrated/member/google.png" />
          </div>
        </div>
      );
      break;
    case BottomPopupType.SnsIntegration:
      returnValue = (
        <div className={css.modal_content}>
          <div className={css.close_button_wrap}>
            <img
              className={css.close_button}
              src="images/member_integrated/mypage/setting/close.png"
              onClick={()=> {
                if (props.onClose) {
                  props.onClose();
                }
              }}
            />
          </div>
          <div className={css.title}>{props.title}</div>
          <div className={`${css.contents} mb40`}>{props.contents}</div>
          <div className={`${css.sns_wrap} mt20 mb48`}>
            <img src="images/member_integrated/member/naver.png" />
            <img src="images/member_integrated/member/kakao.png" />
            <img src="images/member_integrated/member/google.png" />
          </div>
        </div>
      );
      break;
    case BottomPopupType.DestinyChartSpecifics:
      const info = props.contents;
      if (info) {
        const definition = info.definition;
        const characteristics = info.characteristics;
        const buttonTitle = info.buttonTitle;

        returnValue = (
          <div className={css.modal_content}>
            {DestinyChartSpecifics(
              props.title,
              definition,
              characteristics,
              buttonTitle,
              () => {
                if (props.onClickFirstButton) {
                  props.onClickFirstButton();
                }
              },
              () => {
                if (props.onClose) {
                  props.onClose();
                }
              }
            )}
          </div>
        );
      } else {
        returnValue = <></>;
      }
      break;
    case BottomPopupType.TermsOfUse:
      returnValue = (
        <div className={`${css.modal_content} pb0`}>
          <div className={css.close_button_wrap}/>
          <div className={css.title}>이용약관 동의</div>
          <div className={css.terms_of_use_wrap}>
            <CheckBoxGroup 
              checkboxItems={[
                {
                  contents: {pre:"", link:"개인정보 수집 및 이용 동의", post:" (필수)", onClick:()=> {
                    window.open("https://www.jeomsin.co.kr/policy");
                  }},
                  value: 'privacyUsageAgreement',
                  onClick: (isChecked) => {
                    privacyUsageAgreement.current = isChecked;
                    if(privacyUsageAgreement.current && ageFourteenOrOlderAgreement.current && termsOfService.current) {
                      setDisable(false);
                    } else {
                      setDisable(true)
                    }

                    console.log(`개인정보 수집 및 이용 동의 ${privacyUsageAgreement.current} ${ageFourteenOrOlderAgreement.current} ${termsOfService.current}`); 
                  },
                },
                {
                  contents: {pre:"만 14세 이상 확인 (필수)"},
                  value: 'ageFourteenOrOlderAgreement',
                  onClick: (isChecked) => {
                    ageFourteenOrOlderAgreement.current = isChecked;
                    if(privacyUsageAgreement.current && ageFourteenOrOlderAgreement.current && termsOfService.current) {
                      setDisable(false);
                    } else {
                      setDisable(true)
                    }

                    console.log(`만 14세 이상 확인 (필수) ${privacyUsageAgreement.current} ${ageFourteenOrOlderAgreement.current} ${termsOfService.current}`); 
                  },
                },
                {
                  contents: {pre:"이벤트 및 ", link:"혜택 알림", post:" 수신 동의 (선택)", onClick:()=> {
                    setOpenPopup(true);
                  }},
                  value: 'receiveEventAndBenefitNotificationsAgreement',
                  onClick: (isChecked) => {
                    console.log(`이벤트 혜택 알림 ${privacyUsageAgreement.current} ${ageFourteenOrOlderAgreement.current} ${termsOfService.current}`); 
                    receiveEventAndBenefitNotificationsAgreement.current = isChecked;
                  },
                },
                {
                  contents: {pre:"", link:"서비스이용약관", post:" 동의 (필수)", onClick:()=> {
                    window.open("https://www.jeomsin.co.kr/agree");
                  }},
                  value: 'termsOfService',
                  onClick: (isChecked) => { 
                    termsOfService.current = isChecked;
                    if(privacyUsageAgreement.current && ageFourteenOrOlderAgreement.current && termsOfService.current) {
                      setDisable(false);
                    } else {
                      setDisable(true)
                    }

                    console.log(`서비스이용약관 ${privacyUsageAgreement} ${ageFourteenOrOlderAgreement} ${termsOfService}`);
                  },
                }
              ]}
              groupCheckBoxButton={{
                contents: {pre:"모든 약관에 동의합니다."},
                value: 'AllAgreement',
                onClick: (isChecked) => {
                  privacyUsageAgreement.current = !isChecked;
                  ageFourteenOrOlderAgreement.current = !isChecked;
                  receiveEventAndBenefitNotificationsAgreement.current = !isChecked;
                  termsOfService.current = !isChecked;
                  setDisable(!isChecked)
                },
              }} 
            ></CheckBoxGroup>
          </div>
          <button className={`${css.full_button} ${isDisable ? css.disable : ""}`} disabled={isDisable} onClick={props.onClickFirstButton}>{props.firstButtonTitle}</button>
          <BottomPopup isOpen={isOpenPopup} type={BottomPopupType.PrivacyUsage} notDimmed={true} onClose={() => setOpenPopup(false)}></BottomPopup>
        </div>
      );
      break;
    case BottomPopupType.PrivacyUsage:
      returnValue = (
        <div className={css.modal_content}>
        <div className={css.close_button_wrap}>
          <img
            className={css.close_button}
            src="images/member_integrated/mypage/setting/close.png"
            onClick={()=> {
              if (props.onClose) {
                props.onClose();
              }
            }}
          />
        </div>
        <div className={css.privacy_usage}>
          <div className={css.title}>개인정보 수집 · 이용 동의 (혜택 알림)</div>
          <table>
            <tr>
              <th>수집 및<br/>이용 목적</th>
              <td>각종 이벤트, 회원 혜택, 쿠폰(알림) 등의 사전 안내 및 이벤트 기반한 알림</td>
            </tr>
            <tr>
              <th>수집 항목</th>
              <td>이름, 휴대전화, 생년월일, 성별, 상품 구매정보, 서비스 이용 내역, 광고 정보 수신 채널 (문자, 앱 푸시, 카카오톡)</td>
            </tr>
            <tr>
              <th>보유 및<br/>이용기간</th>
              <td>회원 탈퇴 후 30일까지 또는 해당 서비스 등의 철회 요청 종료 시까지</td>
            </tr>
          </table>
          <p className={css.note}>* 회원은 본 서비스 이용 동의에 대한 거부를 할 수 있으며, 미동의 시 본 서비스에 대한 혜택을 받을 수 없습니다.</p>
        </div>
      </div>
      );
      break;
    case BottomPopupType.ReleationSelcet:
      const space_text = 
      returnValue = (
        <div className={css.modal_content} ref={ref}>
        <div className={css.close_button_wrap}>
        </div>
        <div className={css.title}>{props.title}과의 관계</div>
        <div className={`${css.contents} mb40 pl24 pr24`}>
          <div className={css.relation_title}>가족</div>
          <div className={css.relation_container}>
            {
              familyTitle.map((value, index) => (
                <button
                  key={value[1]}
                  className={`${css.relation_button} ${(selectValue == value[1]) ? css.selected : ""}`} 
                  value={value[1]} 
                  onClick={()=> {
                    setSelectText(value[0]);
                    setSelectValue(value[1]);
                  }}
                >
                  {value[0]}
                </button>
              ))
            }
          </div>
          <div className={css.relation_title}>회사</div>
          <div className={css.relation_container}>
          {
              companyTitle.map((value, index) => (
                <button
                  key={value[1]}
                  className={`${css.relation_button} ${(selectValue == value[1]) ? css.selected : ""}`} 
                  value={value[1]} 
                  onClick={()=> {
                    setSelectText(value[0]);
                    setSelectValue(value[1]);
                  }}
                >
                  {value[0]}
                </button>
              ))
            }
          </div>
          <div className={css.relation_title}>친구</div>
          <div className={css.relation_container}>
          {
              relationshipTitle.map((value, index) => (
                <button
                  key={value[1]}
                  className={`${css.relation_button} ${(selectValue == value[1]) ? css.selected : ""}`} 
                  value={value[1]} 
                  onClick={()=> {
                    setSelectText(value[0]);
                    setSelectValue(value[1]);
                  }}
                >
                  {value[0]}
                </button>
              ))
            }
          </div>
          <div className={css.relation_title}>미설정</div>
          <div className={css.relation_container}>
            {
              noneTitle.map((value, index) => (
                <button
                  key={value[1]}
                  className={`${css.relation_button} ${(selectValue == value[1]) ? css.selected : ""}`} 
                  value={value[1]} 
                  onClick={()=> {
                    setSelectText(value[0]);
                    setSelectValue(value[1]);
                  }}
                >
                  {value[0]}
                </button>
              ))
            }
          </div>
        </div>
        <div className={css.button_wrap}>
          <div
            className={`${css.button} bgcolor-yellow`}
            onClick={()=> {
              if (props.onClose) {
                props.onClose({text: selectText, value:selectValue});
              }
            }}
          >
            등록하기
          </div>
        </div>
      </div>
      );
      break;
    default:
      returnValue = <></>;
      break;
  }

  return returnValue;
};

export default BottomPopup;
