import '../css/fortune.css';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import { BottomPopup, BottomPopupType } from '@BasicComponents/basic';
import { useEffect, useState } from 'react';
import { BottomNavigationType, JeomsinBottomNavigation, JeomsinPageType } from '@Components/compose';
import { JeomsinPagePath, JeomsinPageUIProps } from '@Routes/PageRoutes';
import DimLayer from '@BasicComponents/dim_layer/dim_layer';
import useDimmedStore from '@Storage/titlebar_actions'
import CookieKeys from '@Storage/cookie_keys';
import CookieManager from '@Storage/cookie_manager';

function Fortune({ headerType, bottomNavParam }: JeomsinPageUIProps) {
  const navigate = useNavigate();
  // const [isDimLayerOpen, setDimLayerOpen] = useState(false);
  const {isDimLayerOpen, setDimLayerOpen} = useDimmedStore();
  const [isOnboardingPopupOpen, setOnboardingPopupOpen] = useState(false);
  const [isLoginPopupOpen, setLoginPopupOpen] = useState(false);

  useEffect(() => {
    headerType({type: JeomsinPageType.Fortune});
    bottomNavParam({type:BottomNavigationType.Tab});
  }, []);

  return (
    <div className="main-contents fortune-wrap">
      <div className="fortune-main-banner">
        <img src='img/test_1.png' width="100%"></img>
      </div>
      <div>
        <Grid className="menu-box" container columns={18}>
          <Grid className="text-area" xs={18} item={true}>
            <Typography className="sub-text" component="p">
            소름 돋는 미래 예측
            </Typography>
            <Typography className="main-text" component="span">
            가장 정확한 사주 풀이
            </Typography>
          </Grid>
          <Grid className="type1" xs={6} item={true}>
            <div className="box-item" onClick={()=> {
                const userInfoJson = CookieManager.GetJSON(CookieKeys.representative_user_info);
                userInfoJson ? navigate(JeomsinPagePath.SajuMyeongsik) : setDimLayerOpen(true);
              }}
            >
              <img src="/img/menu_icon.png"/>
              <span>오늘의 운세</span>
            </div>
          </Grid>
          <Grid className="type1" xs={6} item={true}>
            <div className="box-item" onClick={() => { 
                CookieManager.RemoveKeyValue(CookieKeys.representative_user_info); 
                CookieManager.RemoveKeyValue(CookieKeys.user_info_list);
                alert("초기화 완료");
                window.location.reload();
              }}>
              <img src="/img/menu_icon.png"/>
              <span>데이터 초기화</span>
            </div>
          </Grid>
          <Grid className="type1" xs={6} item={true}>
          <div className="box-item" onClick={() => { 
                console.log("get representative_user_info : "+JSON.stringify(CookieManager.GetJSON(CookieKeys.representative_user_info)));
                console.log("get user_info_list : "+JSON.stringify(CookieManager.GetJSON(CookieKeys.user_info_list)));
              }}>
              <img src="/img/menu_icon.png"/>
              <span>데이터 확인</span>
            </div>
          </Grid>
          <Grid className="type1" xs={6} item={true}>
            <div className="box-item">
              <img src="/img/menu_icon.png"/>
              <span>신년운세</span>
            </div>
          </Grid>
          <Grid className="type1" xs={6} item={true}>
            <div className="box-item">
              <img src="/img/menu_icon.png"/>
              <span>토정비결</span>
            </div>
          </Grid>
          <Grid className="type1" xs={6} item={true}>
            <div className="box-item">
              <img src="/img/menu_icon.png"/>
              <span>정통사주</span>
            </div>
          </Grid>
        </Grid>
      </div>
      <div className="banner-ad-box">
        <span>100/50</span>
      </div>
      <div>
        <Grid className="menu-box" container columns={18}>
          <Grid className="text-area" xs={18} item={true}>
            <Typography className="sub-text" component="p">
            액운 방지
            </Typography>
            <Typography className="main-text" component="span">
            손쉽게 행운을 얻는 방법
            </Typography>
          </Grid>
          <Grid className="type2" xs={18} item={true}>
            <div className="box-item">
              <div className="img-box">
                <img src="/img/bok_icon.png"/>
              </div>
              <div className="text-box">
                <span className="main-text">천생복덕운</span>
                <span className="sub-text">타고난 복을 활용해보세요</span>
              </div>
            </div>
            <img className="move-icon" src="/common/rightward_arrow_b.png"/>
          </Grid>
          <Grid className="type2" xs={18} item={true}>
            <div className="box-item">
              <div className="img-box">
                <img src="/img/bok_icon.png"/>
              </div>
              <div className="text-box">
                <span className="main-text">관상</span>
                <span className="sub-text">얼굴에 적힌 운명 알아보기</span>
              </div>
            </div>
            <img className="move-icon" src="/common/rightward_arrow_b.png"/>
          </Grid>
          <Grid className="type2" xs={18} item={true}>
            <div className="box-item">
              <div className="img-box">
                <img src="/img/bok_icon.png"/>
              </div>
              <div className="text-box">
                <span className="main-text">행운의 번호</span>
                <span className="sub-text">내 인생을 바꿔줄 6개의 숫자</span>
              </div>
            </div>
            <img className="move-icon" src="/common/rightward_arrow_b.png"/>
          </Grid>
          <Grid className="type2" xs={18} item={true}>
            <div className="box-item">
              <div className="img-box">
                <img src="/img/bok_icon.png"/>
              </div>
              <div className="text-box">
                <span className="main-text">살풀이</span>
                <span className="sub-text">액운 방지에는 살풀이가 최고</span>
              </div>
            </div>
            <img className="move-icon" src="/common/rightward_arrow_b.png"/>
          </Grid>
          <Grid className="type2" xs={18} item={true}>
            <div className="box-item">
              <div className="img-box">
                <img src="/img/bok_icon.png"/>
              </div>
              <div className="text-box">
                <span className="main-text">이사택일</span>
                <span className="sub-text">한눈에 보는 이사하기 좋은날</span>
              </div>
            </div>
            <img className="move-icon" src="/common/rightward_arrow_b.png"/>
          </Grid>
          <Grid className="type2" xs={18} item={true}>
            <div className="box-item">
              <div className="img-box">
                <img src="/img/bok_icon.png"/>
              </div>
              <div className="text-box">
                <span className="main-text">행운의 부적</span>
                <span className="sub-text">나를 도와주는 영험한 기운</span>
              </div>
            </div>
            <img className="move-icon" src="/common/rightward_arrow_b.png"/>
          </Grid>
        </Grid>
      </div>
      <div>
        <Grid className="menu-box" container columns={18} item={true}>
          <Grid className="text-area" xs={18} item={true}>
            <Typography className="sub-text" component="p">
            취업과 진로
            </Typography>
            <Typography className="main-text" component="span">
            내게 맞는 직업 찾기
            </Typography>
          </Grid>
          <Grid className="type1" xs={6} item={true}>
            <div className="box-item">
              <img src="/img/menu_icon.png"/>
              <span>취업 운세</span>
            </div>
          </Grid>
          <Grid className="type1" xs={6} item={true}>
            <div className="box-item">
              <img src="/img/menu_icon.png"/>
              <span>능력 평가</span>
            </div>
          </Grid>
          <Grid className="type1" xs={6} item={true}>
            <div className="box-item">
              <img src="/img/menu_icon.png"/>
              <span>심리 분석</span>
            </div>
          </Grid>
        </Grid>
      </div>
      <div>
        <Grid className="menu-box" container columns={18}>
          <Grid className="text-area" xs={18} item={true}>
            <Typography className="sub-text" component="p">
            소원을 이뤄요
            </Typography>
            <Typography className="main-text" component="span">
            지금 필요한 해결책
            </Typography>
          </Grid>
          <Grid className="type3 pt10 pb5 pl10 pr5" xs={9} item={true}>
            <div className="box-item">
              <div className="text-box">
                <span className="main-text">소원담벼락</span>
                <span className="sub-text">함께 비는 소원은<br/>더 빨리 이뤄져요</span>
              </div>
              <img src="/img/candle_icon.png"/>
            </div>
          </Grid>
          <Grid className="type3 pt10 pb5 pr10" xs={9} item={true}>
            <div className="box-item">
              <div className="text-box">
                <span className="main-text">고민구슬</span>
                <span className="sub-text">머리 아픈 고민!<br/>3초만에 끝내기</span>
              </div>
              <img src="/img/candle_icon.png"/>
            </div>
          </Grid>
          <Grid className="type3 pb10 pl10 pr5" xs={9} item={true}>
            <div className="box-item">
              <div className="text-box">
                <span className="main-text">점신1:1상담</span>
                <span className="sub-text">혼자 해결하기 어려운<br/>문제가 있다면</span>
              </div>
              <img src="/img/candle_icon.png"/>
            </div>
          </Grid>
          <Grid className="type3 pb10 pr10" xs={9} item={true}>
            <div className="box-item">
              <div className="text-box">
                <span className="main-text">포츈쿠키</span>
                <span className="sub-text">오늘의 당신에게<br/>필요한 한마디</span>
              </div>
              <img src="/img/candle_icon.png"/>
            </div>
          </Grid>
        </Grid>
      </div>
      <div className="banner-ad-box">
        <span>100/50</span>
      </div>
      <div>
        <Grid className="menu-box" container columns={18}>
          <Grid className="text-area" xs={18} item={true}>
            <Typography className="sub-text" component="p">
            타고난 운명
            </Typography>
            <Typography className="main-text" component="span">
            나에 대한 모든 것
            </Typography>
          </Grid>
          <Grid className="type1" xs={6} item={true}>
            <div className="box-item">
              <img src="/img/menu_icon.png"/>
              <span>띠 운세</span>
            </div>
          </Grid>
          <Grid className="type1" xs={6} item={true}>
            <div className="box-item">
              <img src="/img/menu_icon.png"/>
              <span>별자리 운세</span>
            </div>
          </Grid>
          <Grid className="type1" xs={6} item={true}>
            <div className="box-item">
              <img src="/img/menu_icon.png"/>
              <span>생년월일 운세</span>
            </div>
          </Grid>
          <Grid className="type1" xs={6} item={true}>
            <div className="box-item">
              <img src="/img/menu_icon.png"/>
              <span>태어난 계절운</span>
            </div>
          </Grid>
          <Grid className="type1" xs={6} item={true}>
            <div className="box-item">
              <img src="/img/menu_icon.png"/>
              <span>전생운</span>
            </div>
          </Grid>
          <Grid className="type1" xs={6} item={true}>
            <div className="box-item">
              <img src="/img/menu_icon.png"/>
              <span>탄생석</span>
            </div>
          </Grid>
        </Grid>
      </div>
      <div>
        <Grid className="menu-box" container columns={18}>
          <Grid className="text-area" xs={18} item={true}>
            <Typography className="sub-text" component="p">
            궁합 파헤치기
            </Typography>
            <Typography className="main-text" component="span">
            우리는 어떻게 될까?
            </Typography>
          </Grid>
          <Grid className="type2" xs={18} item={true}>
            <div className="box-item">
              <div className="img-box">
                <img src="/img/bok_icon.png"/>
              </div>
              <div className="text-box">
                <span className="main-text">정통궁합</span>
                <span className="sub-text">연애 시작 전 확인 필수!</span>
              </div>
            </div>
            <img className="move-icon" src="/common/rightward_arrow_b.png"/>
          </Grid>
          <Grid className="type2" xs={18} item={true}>
            <div className="box-item">
              <div className="img-box">
                <img src="/img/bok_icon.png"/>
              </div>
              <div className="text-box">
                <span className="main-text">짝궁합</span>
                <span className="sub-text">쉽고 자세한 해석을 원한다면!</span>
              </div>
            </div>
            <img className="move-icon" src="/common/rightward_arrow_b.png"/>
          </Grid>
          <Grid className="type2" xs={18} item={true}>
            <div className="box-item">
              <div className="img-box">
                <img src="/img/bok_icon.png"/>
              </div>
              <div className="text-box">
                <span className="main-text">별자리 궁합</span>
                <span className="sub-text">별자리로 이어진 너와 나</span>
              </div>
            </div>
            <img className="move-icon" src="/common/rightward_arrow_b.png"/>
          </Grid>
          <Grid className="type2" xs={18} item={true}>
            <div className="box-item">
              <div className="img-box">
                <img src="/img/bok_icon.png"/>
              </div>
              <div className="text-box">
                <span className="main-text">혈액형 궁합</span>
                <span className="sub-text">같은 혈액형 vs 다른 혈액형</span>
              </div>
            </div>
            <img className="move-icon" src="/common/rightward_arrow_b.png"/>
          </Grid>
          <Grid className="type2" xs={18} item={true}>
            <div className="box-item">
              <div className="img-box">
                <img src="/img/bok_icon.png"/>
              </div>
              <div className="text-box">
                <span className="main-text">연예인 궁합</span>
                <span className="sub-text">우리가 연인이 되면 어떨까?</span>
              </div>
            </div>
            <img className="move-icon" src="/common/rightward_arrow_b.png"/>
          </Grid>
          <Grid className="type2" xs={18} item={true}>
            <div className="box-item">
              <div className="img-box">
                <img src="/img/bok_icon.png"/>
              </div>
              <div className="text-box">
                <span className="main-text">나의 인연 운세</span>
                <span className="sub-text">미래의 배우자 미리보기</span>
              </div>
            </div>
            <img className="move-icon" src="/common/rightward_arrow_b.png"/>
          </Grid>
        </Grid>
      </div>
      <div>
        <Grid className="menu-box" container columns={18}>
          <Grid className="text-area" xs={18} item={true}>
            <Typography className="sub-text" component="p">
            꿈해몽
            </Typography>
            <Typography className="main-text" component="span">
            그 꿈에 담긴 의미
            </Typography>
          </Grid>
          <Grid className="type4" xs={18} item={true}>
            <div className="box-item">
              <span className='sub-text mr5'>#구설수</span>
              <span className='main-text mr5'>예쁜 꽃에 입술을 맞추는 꿈</span>
            </div>
          </Grid>
          <Grid className="type4" xs={18} item={true}>
          <div className="box-item">
              <span className='sub-text mr5'>#구설수</span>
              <span className='main-text mr5'>형제 자매나 친구가 걸인이 된 꿈</span>
            </div>
          </Grid>
          <Grid className="type4" xs={18} item={true}>
          <div className="box-item">
              <span className='sub-text mr5'>#구설수</span>
              <span className='main-text mr5'>아무도 없는 곳에서 웃음소리를 듣...</span>
            </div>
          </Grid>
          <Grid className="type4" xs={18} item={true}>
          <div className="box-item">
              <span className='sub-text mr5'>#구설수</span>
              <span className='main-text mr5'>동쪽에서 보름달이 솟아 오르는 꿈</span>
            </div>
          </Grid>
          <Grid className="type4" xs={18} item={true}>
          <div className="box-item">
              <span className='sub-text mr5'>#구설수</span>
              <span className='main-text mr5'>예쁜 꽃에 입술을 맞추는 꿈</span>
            </div>
          </Grid>
        </Grid>
      </div>
      <div className="bottom-info">
        (주)테크랩스 | 대표 : 유지헌 | 주소 : 서울특별시 강남구 언주로 428,
        4층, 5층, 6층, 9층, 13층, 14층(역삼동, 모제림빌딩) |
        사업자 등록번호 : 144-01-15705 (사업자 정보 확인) | 
        통신판매업신고 : 제2018-서울강남-03394호 | 
        대표전화 : 1811-9329
      </div>
      <JeomsinBottomNavigation type={BottomNavigationType.Tab} title={'저장'} buttonDisable={true}/>
      <DimLayer isOpen={isDimLayerOpen} 
        onLayerEnter={()=> setOnboardingPopupOpen(true)}
        onClose={()=> {setDimLayerOpen(false)}}
      >
      </DimLayer>
      <BottomPopup
          isOpen={isOnboardingPopupOpen} 
          type={BottomPopupType.Onboarding}
          title='당신의 앞날이 궁금하다면?'
          contents={'지금 사주정보를 입력하고\n다양한 운세를 무료로 확인하세요!'}
          notDimmed={true}
          onClose={()=> {
            setOnboardingPopupOpen(false);
            setDimLayerOpen(false);
          }}
          firstButtonTitle='점신 시작하기'
          onClickFirstButton={()=> {
            setOnboardingPopupOpen(false);
            setDimLayerOpen(false);
            navigate(JeomsinPagePath.SajuInput)
          }}
          secondButtonTitle='기존 회원 로그인하기'
          onClickSecondButton={()=> {
            setOnboardingPopupOpen(false);
            setTimeout(() => setLoginPopupOpen(true), 500);
          }}
        />
        <BottomPopup 
          isOpen={isLoginPopupOpen}
          type={BottomPopupType.Login}
          title='로그인 하기'
          notDimmed={true}
          onClose={()=> {
            setLoginPopupOpen(false);
            setDimLayerOpen(false);
          }}
        />
    </div>
  );
}

export default Fortune;