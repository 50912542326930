import css from "./top_summary_view.module.css";
import { FortuneTodayUnseResponse } from "@Network/request/api_get_fortune_today_unse";

const TopSummaryView = (response: FortuneTodayUnseResponse) => {
  const description = response.drmwork_data.menu1.category1.depth1.text;
  const num = response.drmwork_data.menu1.category1.depth1.num ?? 0;
  let summary = (description.split(".").at(0) ?? "").concat(".");

  if (summary === ".") {
    summary = "";
  }

  return (
    <div className={`${css.v_stack}`}>
      <div className={`${css.relative_positioning}`}>
        <div className={`${css.number_font}`}>
          <div className={`${css.relative_positioning}`}>
            <div className={`${css.spacer_0}`} />
          </div>
          <span className={`${css.number_title}`}>{num}</span>
        </div>

        <div className={`${css.invisible_dummy}`}>
          <span>{num}</span>
        </div>
      </div>

      <div className={`${css.centered_with_padding}`}>
        <span>{summary}</span>
      </div>
    </div>
  );
};

export default TopSummaryView;
