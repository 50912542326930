import { Radar } from "react-chartjs-2";
import css from "./custom_hexagon_graph.module.css";
import { FortuneTodayUnseResponse } from "@Network/request/api_get_fortune_today_unse";

const CustomHexagonGraph = (response: FortuneTodayUnseResponse) => {
  const hexagonData = [
    response.drmwork_data.menu1.category1,
    response.drmwork_data.menu2.category1,
    response.drmwork_data.menu2.category2,
    response.drmwork_data.menu2.category3,
    response.drmwork_data.menu2.category4,
    response.drmwork_data.menu2.category5,
  ]
    .filter((nullable) => nullable !== undefined)
    .map((category) => [category!.depth1.title, category!.depth1.num!]);

  const data = {
    responsive: true,
    labels: hexagonData.map((data) => data[0]),
    datasets: [
      {
        backgroundColor: "#20DEE566",
        borderColor: "#3EFFFF",
        data: hexagonData.map((data) => data[1]),
        fill: true,
        pointRadius: 0,
      },
    ],
  };
  const options = {
    scales: {
      r: {
        suggestedMin: 0,
        suggestedMax: 100,
        angleLines: {
          display: false,
        },
        ticks: {
          display: false,
        },
        grid: {
          display: false,
        },
        pointLabels: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    elements: {
      line: {
        borderWidth: 2,
      },
    },
    maintainAspectRatio: false,
  };

  return (
    <div className={`${css.defined_size}`}>
      <div className={`${css.chart_size}`}>
        <Radar options={options} data={data} />
      </div>
      <img
        src="images/today_fortune/3.0x/bg.png"
        alt="Fortune Result Radar Background"
        className={`${css.image_size}`}
      />
    </div>
  );
};

export default CustomHexagonGraph;
