import { useEffect, useRef, useState } from "react";
import "@CSS/common.css";
import Profile from "./profile";
import { OhangColorButton } from "./o_hang_color_button";
import {
  BottomNavigationType,
  JeomsinPageType,
  RowOfTexts,
} from "@Components/compose";
import {
  BottomButton,
  BottomPopup,
  BottomPopupType,
  ModalPopup,
  ModalPopupType,
} from "@BasicComponents/basic";
import DestinyChartGuide from "./destiny_chart_guide";
import { DestinyChartDisplayableSpecifics } from "../models/destiny_chart_displayable_specifics";
import { JeomsinPageUIProps } from "@Routes/PageRoutes";
import { useNavigate } from "react-router";
import { JeomsinPagePath } from "@Routes/PageRoutes";

import css from "../css/destiny_chart_basic.module.css";
import CookieManager from "@Storage/cookie_manager";
import CookieKeys from "@Storage/cookie_keys";
import { UserInfo } from "@Storage/data_types";
import SajuMyungsikData, { SajuMyungsikInfo } from "@Storage/data_model/saju_myungsik_data";
import { SajuInfoModel } from "@Storage/data_model/saju_info_model";

function DestinyChartBasic({ headerType, bottomNavParam }: JeomsinPageUIProps) {
  const nav = useNavigate();
  const [userInfo, setUserInfo] = useState<UserInfo>(CookieManager.GetJSON(CookieKeys.representative_user_info));
  const [myFortuneInfo, setMyFortuneInfo] = useState<SajuInfoModel>(userInfo.saju_info!);
  const userList = useRef<UserInfo>();

  useEffect(() => {
    headerType({type: JeomsinPageType.Sub, title:"오늘의 운세"});
    bottomNavParam({
      type:BottomNavigationType.Button, 
      title:"운세 풀이 보기",
      onClick() { 
        nav(JeomsinPagePath.SajuAnalyzing, {state: userInfo}); ;
      }
    });
  }, [bottomNavParam, headerType, userInfo, myFortuneInfo]);

  const [showInfo, setShowInfo] = useState(false);
  const [specifics, setSpecific] = useState<SajuMyungsikInfo>();
  const [showSpecifics, setShowSpecifics] = useState<boolean>(false);
  const [showSajuInfoSelect, setShowSajuInfoSelect] = useState<boolean>(false);

  userList.current = CookieManager.GetJSON(CookieKeys.user_info_list);

  const sajuMyungsikDatas = SajuMyungsikData();
  const goToSpecificsView = (specifics:SajuMyungsikInfo) => {
    setSpecific(specifics);
    setShowSpecifics((showSpecifics) => {
      return showSpecifics ? false : true;
    });
  };

  return (
      <div
        className={`${css.center_wrapper} header-type-sub jeomsin-bgcolor-default-background`}
      >
        <div className={`${css.main_contents}`}>
          <Profile
            name={userInfo.name}
            gender={(userInfo.gender == "0") ? "남성" : "여성"}
            birthYear={userInfo.birthYear}
            birthMonth={userInfo.birthMonth}
            birthDay={userInfo.birthDay}
            birthHour={userInfo.birthTime}
            birthMinute={userInfo.birthMinute}
            calendar={userInfo.calendarTypeText}
            element={myFortuneInfo}
            clickHandler={() => {
              setShowInfo((info) => {
                return info ? false : true;
              });
            }}
            showSajuProfileSelectorClickAction={() => {
              setShowSajuInfoSelect(true);
            }}
          />
          <div className={`${css.spacer_40}`} />
          <div>
            <RowOfTexts
              contents={[
                sajuMyungsikDatas.birthSajuData[0],
                sajuMyungsikDatas.birthSajuData[1],
                sajuMyungsikDatas.birthSajuData[2],
                sajuMyungsikDatas.birthSajuData[3],
              ].map((value, index) => (
                <div key={index} onClick={()=> goToSpecificsView(value)}>{value.classTitle}</div>
              ))}
              className={`${css.unimportant_text} pt19 pb19`}
            />
            <div className={`${css.spacer_10}`} />
            <RowOfTexts
              contents={[
                sajuMyungsikDatas.birthSipSungData[Number(myFortuneInfo.chunGan10Sungs[0])],
                sajuMyungsikDatas.birthSipSungData[Number(myFortuneInfo.chunGan10Sungs[1])],
                sajuMyungsikDatas.birthSipSungData[Number(myFortuneInfo.chunGan10Sungs[2])],
                sajuMyungsikDatas.birthSipSungData[Number(myFortuneInfo.chunGan10Sungs[3])], 
              ].map((value, index) => (
                <div key={index} onClick={()=> goToSpecificsView(value)}>{value.classTitle}</div>
              ))}
              showDivider={true}
              dividerOpacity="20%"
              dividerRelativeHeight="68%"
              className={`${css.important_text} pt19 pb19`}
            />
            <div className={`${css.spacer_24}`} />
            <div className={`${css.spaced}`}>
              {
                myFortuneInfo.chunganJijiOhangNodes.map((value, index) => (
                  index < myFortuneInfo.chunganJijiOhangNodes.length/2 ?
                  <OhangColorButton
                    key={index} 
                    ohang={value.ohang}
                    text={value.text}
                    bottomText={sajuMyungsikDatas.birthChunganData[value.num].classTitle}
                    clickHandler={()=>goToSpecificsView(sajuMyungsikDatas.birthChunganData[value.num])}
                  />
                  : null
                ))
              }
            </div>
            <div className={`${css.spacer_10}`} />
            <div className={`${css.spaced}`}>
              {
                myFortuneInfo.chunganJijiOhangNodes.map((value, index) => (
                  index >= myFortuneInfo.chunganJijiOhangNodes.length/2 ?
                  <OhangColorButton
                    key={index} 
                    ohang={value.ohang}
                    text={value.text}
                    bottomText={sajuMyungsikDatas.birthJijiData[value.num].classTitle}
                    clickHandler={()=>goToSpecificsView(sajuMyungsikDatas.birthJijiData[value.num])}
                  />
                  : null
                ))
              }
            </div>
            <div className={`${css.spacer_24}`} />
            <RowOfTexts
              contents={[
                sajuMyungsikDatas.birthSipSungData[Number(myFortuneInfo.jiji10Sungs[0])],
                sajuMyungsikDatas.birthSipSungData[Number(myFortuneInfo.jiji10Sungs[1])],
                sajuMyungsikDatas.birthSipSungData[Number(myFortuneInfo.jiji10Sungs[2])],
                sajuMyungsikDatas.birthSipSungData[Number(myFortuneInfo.jiji10Sungs[3])], 
              ].map((value, index) => (
                <div key={index} onClick={()=> goToSpecificsView(value)}>{value.classTitle}</div>
              ))}
              showDivider={true}
              dividerOpacity="20%"
              dividerRelativeHeight="68%"
              className={`${css.important_text} pt19 pb19`}
            />
            <div className={`${css.spacer_16}`} />
            <RowOfTexts
              contents={[
                sajuMyungsikDatas.birthSipYiUnSungData[Number(myFortuneInfo.unSungs[0])],
                sajuMyungsikDatas.birthSipYiUnSungData[Number(myFortuneInfo.unSungs[1])],
                sajuMyungsikDatas.birthSipYiUnSungData[Number(myFortuneInfo.unSungs[2])],
                sajuMyungsikDatas.birthSipYiUnSungData[Number(myFortuneInfo.unSungs[3])], 
              ].map((value, index) => (
                <div key={index} onClick={()=> goToSpecificsView(value)}>{value.classTitle}</div>
              ))}
              showDivider={true}
              dividerOpacity="20%"
              dividerRelativeHeight="68%"
              className={`${css.important_text} pt19 pb19`}
            />
          </div>
        </div>
        <div className={`pt25`} />
        <BottomButton title="운세 풀이 보기" />
        {DestinyChartGuide(() => {
        setShowInfo((info) => (info ? false : true));
      }, showInfo)}

      <BottomPopup
        title={`${specifics?.classTitle ?? ""} ${
          specifics?.classChineseChar ? "["+specifics?.classChineseChar+"]" : ""
        }`}
        contents={{
          definition: specifics?.description ?? "",
          characteristics: specifics?.characteristics ?? "",
          buttonTitle: "자세히보기",
        }}
        isOpen={showSpecifics}
        type={BottomPopupType.DestinyChartSpecifics}
        blurred={true}
        onClose={() => {
          setShowSpecifics(false);
        }}
        onClickFirstButton={() => {
          nav(JeomsinPagePath.SajuMyeongsikDetail, {
            state: specifics,
          });
        }}
      />
      <ModalPopup
          data={userList.current}
          isOpen={showSajuInfoSelect}
          type={ModalPopupType.SajuInfoSelect}
          onClickFirstButton={()=> nav(JeomsinPagePath.SajuModify)}
          onClose={(picked?: any) => {
            if (picked) {
              const pickedUser:UserInfo = picked;
              console.log(`I have picked User ${pickedUser.name}`);
              setUserInfo(pickedUser);
              setMyFortuneInfo(pickedUser.saju_info!);
            }

            setShowSajuInfoSelect(false);
          }}
        />
      </div>
  );
}

export default DestinyChartBasic;
