import { Box } from '@mui/material';
import "../css/aside_page.css"

const JeomsinAsidePage = (props:any) => {
    return (
        <Box className="aside-wrap">
            <Box className="logo-wrap">
                <img src='aside_page/jeomsin_logo.png'></img>
            </Box>
            <Box className="content-wrap">
                <Box className="main-content-wrap">
                    <span className="ts20 bold6">
                        당신에게 딱 맞는 운세 상담!
                    </span>
                    <div className="ts50 bold7 mt20">
                        매일 나의 운세를 <br/>
                        <span className='blue'>무료</span>로 봐요!
                    </div>
                </Box>
                <Box className="coupon-wrap">
                    <div className="coupon-text">
                        <div className="ts16 bold5">무료 상담 쿠폰</div>
                        <div className="ts25 bold7">100% 증정!</div>
                    </div>
                    <div className='coupon-download'>
                        <img src='aside_page/download_24.png'></img>
                    </div>
                </Box>
                <Box className="banner-wrap">
                    <div className="banner-text-wrap">
                        <div className="banner-text">
                            <span className="ts20 bold7">점신과 함께할 상담사를 찾고 있어요!</span>
                            <br/>
                            <span className="ts14 bold6">월 최고 지급액 000만원에 도전해 보세요.</span>
                        </div>
                    </div>
                    <img src='aside_page/asset_icon.png'></img>
                </Box>
            </Box>
            <Box className="footer-wrap">
                <Box className="store-wrap">
                    <Box className="play-stroe mr24">
                        <img className="mr8" src='aside_page/playstore_logo.png'></img>
                        <span className="ts16 bold5">Google Play</span>
                    </Box>
                    <Box className="app-stroe">
                        <img className="mr8" src='aside_page/appstore_logo.png'></img>
                        <span className="ts16 bold5">App Store</span>
                    </Box>
                </Box>
                <Box className="qrcode-wrap">
                    <Box className="qrcode">
                        <img src='aside_page/qrcode.png'></img>
                        <div className='ml10'>
                            <span className="ts15 bold8">오늘의 운세?</span>
                            <br/>
                            <span className="ts15 bold5">앱으로 편하게 보세요!</span>
                        </div>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

export default JeomsinAsidePage;