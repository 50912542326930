import create from 'zustand';

// 상태와 함수 타입 정의
interface DimmedStoreState {
    isDimLayerOpen: boolean;  // boolean 상태
    setDimLayerOpen: (value: boolean) => void;  // boolean 값을 받아 상태를 설정하는 함수
  }

const useDimmedStore = create<DimmedStoreState> ((set) => ({
    isDimLayerOpen: false,
    setDimLayerOpen: (value:boolean) => set(() => ({ isDimLayerOpen: value  }))
}));

export default useDimmedStore;