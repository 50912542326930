import { useEffect, useRef, useState } from 'react';
import './input_box.css';
import { BottomPopup, BottomPopupType, JeomsinSelectBox, ModalPopup, ModalPopupType } from '@BasicComponents/basic';

type InputBoxProps = {
    type: InputBoxType;
    label?: string;
    subLabel?: string;
    helpText?: string;
    errorText?: string;
    hintText?: string;
    maxLength?: number;
    inputRegexp?: RegExp;
    resultRegexp?: RegExp;
    onInputBoxChnage?: (event:React.ChangeEvent<HTMLInputElement>) => void;
    onValidate?: (isValidate:boolean, value:string, extraValue?:any) => void;
    onReturnValue?: (value:string, extraValue?:any) => void;
    onSelectBoxClick?: (event:React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

enum InputBoxType {
    Text,
    Select,
    Date,
    Time,
    Button,
}

enum ExtraButtonType {
    Text,
    Select,
    OneButton,
    Date,
    Time,
    None
}

const space_text = " ";

const JeomsinInputBox:React.FC<InputBoxProps> = ( props ) => {
    return (
        <section className="input-section pb22">
            <div className="input-wrap">
                <div className="top-text">
                    <label className="title">{props.label}</label>
                    <label className="sub-text">{props.subLabel}</label>
                </div>
                {
                    InputType(
                        props.type, 
                        props.helpText, 
                        props.errorText, 
                        props.hintText, 
                        props.maxLength, 
                        props.inputRegexp, 
                        props.resultRegexp, 
                        props.onInputBoxChnage,
                        props.onValidate,
                        props.onSelectBoxClick,
                        props.onReturnValue
                    )
                }
            </div>
        </section>
    );
};

const InputType = (
    type:InputBoxType, 
    helpText?:string, 
    errorText?:string,
    hintText?:string, 
    maxLength:number = 999,
    inputRegexp?:RegExp,
    resultRegexp?:RegExp,
    onInputBoxChnage?:(event:React.ChangeEvent<HTMLInputElement>) => void,
    onValidate?:(isValidate:boolean, value:string, extraValue?:any) => void,
    onSelectBoxClick?:(event:React.MouseEvent<HTMLButtonElement, MouseEvent>) => void,
    onReturnValue?: (value:string, extraValue?:any) => void
) => {
    var returnValue:JSX.Element;

    const inputBoxRef = useRef<HTMLInputElement>(null);
    const clearButtonRef = useRef<HTMLImageElement>(null);
    const [textColor, setTextColor] = useState('');
    const [isValidate, setValidate] = useState(true);
    const [inputValue, setInputValue] = useState('');
    const [extraValue, setExtraValue] = useState<any>();

    const [isPopupOpen, setPopupOpen] = useState(false);

    useEffect(() => {
        validateValue(inputValue, resultRegexp);
      }, [inputValue, extraValue]
    );

    const validateValue = (value:string, regexp?:RegExp) => {
        const isValidate = regexp ? regexp?.test(value) : false;
        console.log('validateValue:', isValidate);
        console.log(`isValidate ${value} / ${extraValue}`);
    
        if(onValidate) {
            onValidate(isValidate, value, extraValue);
        } else {

        }

        return isValidate;
    }

    const clearAction = () => {
        console.log(`onClick ${inputValue}`);
        setInputValue("");
        const defaultClassName = clearButtonRef.current?.classList[0];
        clearButtonRef.current?.classList.remove(...clearButtonRef.current.classList);
        clearButtonRef.current?.classList.add(defaultClassName!);
    }

    switch(type) {
        case InputBoxType.Text:
            returnValue = (
                <div>
                    <div className="text-content">
                        <input 
                            ref={inputBoxRef} 
                            type='text'
                            value={inputValue}
                            className="input-text"
                            maxLength={maxLength} 
                            placeholder={hintText}
                            onChange={(event)=> {
                                const newValue = event.target.value;
                                let textAddAction = (newValue.length - inputValue.length > 0) ? true : false;
                                if (textAddAction && inputRegexp) {
                                    const addedChar = newValue.slice(-1); // 방금 입력된 문자
                                    let isInputValidate = inputRegexp.test(addedChar);
                                    if(!isInputValidate) {
                                        event.target.value = newValue.slice(0, newValue.length-1);
                                        return;
                                    }
                                }

                                if (newValue.length > maxLength) {//6자 제한
                                    event.target.value = newValue.slice(0, maxLength);
                                }

                                const editedValue = event.target.value;

                                setInputValue(editedValue);

                                if(resultRegexp) {
                                    const isValidate = validateValue(event.target.value, resultRegexp);

                                    if(isValidate || event.target.value.length == 0){
                                        setTextColor('');
                                        inputBoxRef.current?.classList.remove("outline-red");
                                        clearButtonRef.current?.classList.add("type-text");
                                        setValidate(true);
                                    } else {
                                        setTextColor('red');
                                        inputBoxRef.current?.classList.add("outline-red");
                                        clearButtonRef.current?.classList.remove("type-text");
                                        setValidate(false);
                                    }
                                }

                                if(onInputBoxChnage) {
                                    onInputBoxChnage(event)
                                }   
                            }} 
                        />
                        <img 
                            ref={clearButtonRef}
                            className='clear-button'
                            src='common/delete_incircle.png'
                            onClick={clearAction}
                        />
                    </div>
                    <span className={"help-text" + space_text + textColor}>{ isValidate ? helpText : errorText}</span>
                </div>
            );
            break;
        case InputBoxType.Date:
            inputRegexp = /^[0-9]|.$/;
            resultRegexp = /^\d{4}\.(0[1-9]|1[0-2])\.(0[1-9]|[1-2][0-9]|3[0-1])$/;
            returnValue = (
                <div>
                    <div className="text-content">
                        <input 
                            ref={inputBoxRef} 
                            type='text'
                            className="input-text" 
                            value={inputValue}
                            placeholder={hintText}
                            maxLength={10}
                            onChange={(event)=> {
                                const newValue = event.target.value;
                                let textAddAction = (newValue.length - inputValue.length > 0) ? true : false;
                                if (textAddAction) {
                                    const addedChar = newValue.slice(-1); // 방금 입력된 문자
                                    let isInputValidate = inputRegexp!.test(addedChar);
                                    if(!isInputValidate) {
                                        event.target.value = newValue.slice(0, newValue.length-1);
                                        return;
                                    }
                                }
                               
                                if(textAddAction) {
                                    if(event.target.value.length == 5){//yyyy.
                                        event.target.value = newValue.slice(0, 4) + "." + newValue.slice(4);
                                    } else if(event.target.value.length == 8){//yyyy.MM.
                                        event.target.value = newValue.slice(0, 7) + "." + newValue.slice(7);
                                    }
                                } else {
                                    if(event.target.value.length == 5){//yyyy.
                                        event.target.value = newValue.slice(0, 4) + newValue.slice(5);
                                    } else if(event.target.value.length == 8){//yyyy.MM.
                                        event.target.value = newValue.slice(0, 7) + newValue.slice(8);
                                    }
                                }

                                const editedValue = event.target.value;

                                setInputValue(editedValue);

                                const isValidate = validateValue(editedValue, resultRegexp);

                                if(isValidate || editedValue.length == 0){
                                    setTextColor('');
                                    inputBoxRef.current?.classList.remove("outline-red");
                                    clearButtonRef.current?.classList.add("type-date");
                                    setValidate(isValidate);
                                } else {
                                    setTextColor('red');
                                    inputBoxRef.current?.classList.add("outline-red");
                                    clearButtonRef.current?.classList.remove("type-date");
                                    setValidate(false);
                                }

                                if(onInputBoxChnage) {
                                    onInputBoxChnage(event)
                                }
                            }} 
                        />
                        <div className='date-extra-wrap'>
                            {extraValue ? <span className='mr5'>{extraValue['text']}</span> : <></>}
                            <img 
                                ref={clearButtonRef}
                                className='clear-button'
                                src='common/delete_incircle.png'
                                onClick={clearAction}
                            />
                        </div>
                    </div>
                    <span className={"help-text" + space_text + textColor}>{ isValidate ? helpText : errorText}</span>
                    {ExtraButton(ExtraButtonType.Date, inputValue, setInputValue, "", setExtraValue)}
                </div>
            );
            break;
        case InputBoxType.Select:
            returnValue = (
                <JeomsinSelectBox 
                    className='mt20' 
                    options={[
                        {label:"남자", value:"0"},
                        {label:"여자", value:"1"}
                    ]}
                    onSelect={(event) => {
                        if(onSelectBoxClick){
                            onSelectBoxClick(event)
                        }
                    }}
                />
            );
            break;
        case InputBoxType.Time:
            inputRegexp = /^[0-9]|:$/;
            resultRegexp = /^([01][0-9]|2[0-3]):([0-5][0-9])|(시간 모름)$/;
            returnValue = (
                <div>
                    <div className="text-content">
                        <input 
                            ref={inputBoxRef} 
                            type='text'
                            className="input-text" 
                            value={inputValue}
                            placeholder={hintText}
                            maxLength={5}
                            onChange={(event)=> {
                                const newValue = event.target.value;
                                let textAddAction = (newValue.length - inputValue.length > 0) ? true : false;
                                if (textAddAction) {
                                    const addedChar = newValue.slice(-1); // 방금 입력된 문자
                                    let isInputValidate = inputRegexp!.test(addedChar);
                                    if(!isInputValidate) {
                                        event.target.value = newValue.slice(0, newValue.length-1);
                                        return;
                                    }
                                }
                               
                                if(textAddAction && newValue.length == 3){//00.
                                    event.target.value = newValue.slice(0, 2) + ":" + newValue.slice(2);
                                } else if(!textAddAction && event.target.value.length == 3){//00.
                                    event.target.value = newValue.slice(0, 2) + newValue.slice(3);
                                }

                                const editedValue = event.target.value;

                                setInputValue(editedValue);
                                if( validateValue(editedValue, resultRegexp!) ) {
                                    clearButtonRef.current?.classList.add("type-time");
                                } else {
                                    clearButtonRef.current?.classList.remove("type-time");
                                }

                                if(onInputBoxChnage) {
                                    onInputBoxChnage(event)
                                }
                            }} 
                        />
                        <img 
                            ref={clearButtonRef}
                            className='clear-button'
                            src='common/delete_incircle.png'
                            onClick={clearAction}
                        />
                    </div>
                    <span className={"help-text" + space_text + textColor}>{ isValidate ? helpText : errorText}</span>
                    {ExtraButton(ExtraButtonType.Time, inputValue, setInputValue)}
                </div>
            );
            break;
            case InputBoxType.Button:
                returnValue = (
                    <div>
                        <div className="text-content">
                            <input 
                                ref={inputBoxRef} 
                                type='text'
                                value={inputValue}
                                readOnly
                                className="input-text"
                            />
                        </div>
                        <div className="extra">
                            <span className='ts13 bold7 action-button' onClick={()=> setPopupOpen(true)}>변경</span>
                        </div>
                        <BottomPopup
                            isOpen={isPopupOpen} 
                            type={BottomPopupType.ReleationSelcet}
                            onClose={(returnValue)=> {
                                if(returnValue) {
                                    setInputValue(returnValue.text);
                                    
                                    if(onReturnValue) {
                                        onReturnValue(returnValue.value);
                                    }
                                }
                                setPopupOpen(false)
                            }}
                            firstButtonTitle='등록하기'
                        />
                    </div>
                );
                break;
        default:
            returnValue = (<span></span>);
            break;

    }

    return returnValue;
};

const ExtraButton = (
    extraType:ExtraButtonType,
    inputValue:string, 
    setInputValue:React.Dispatch<React.SetStateAction<string>>,
    extraData?:string,
    setExtraValue?:React.Dispatch<React.SetStateAction<any>>
) => {
    const [isPopupOpen, setPopupOpen] = useState(false);
    const [toggleOff, setToggleOff] = useState(true);
    var returnValue:JSX.Element;
    
    switch(extraType) {
        case ExtraButtonType.Text:
            returnValue = (
                <div className="extra">
                    <img className="clear-button mr5" src='common/delete_incircle.png'></img>
                    <span className="">{extraData}</span>
                </div>
            );
            break;
        case ExtraButtonType.Date:
            returnValue = (
                <div className="extra">
                    <JeomsinSelectBox  
                        width={47}
                        height={32}
                        fontClass='ts13 bold7'
                        options={[
                            {label:"양력", value:"0"},
                            {label:"음력", value:"1"}
                        ]}
                        onSelect={(event) => {
                            if(event.currentTarget.value == "1"){
                                setPopupOpen(true);
                            } else {
                                if(setExtraValue) {
                                    setExtraValue({calendarType:event.currentTarget.value, isLeapMonth:false, text:'(양력)'});
                                }
                            }
                        }}
                    />
                    { 
                        isPopupOpen ? 
                        <ModalPopup 
                            isOpen={isPopupOpen}
                            type={ModalPopupType.BirthTypeSelect} 
                            data={inputValue}
                            onClose={(returnData) => {
                                if(returnData) {
                                    let selectedData:any;
                                    if(returnData["value"] == 1) { //윤달
                                        selectedData = {calendarType:1, isLeapMonth:true, text:returnData["text"]}
                                    } else { //평달
                                        selectedData = {calendarType:1, isLeapMonth:false, text:'(평달)'}
                                    }
    
                                    if(setExtraValue) {
                                        setExtraValue(selectedData);
                                    }
                                }

                                setPopupOpen(false);
                            }}
                        /> 
                        : <></>
                    }
                </div>
            );
            break;
        case ExtraButtonType.OneButton:
            returnValue = (
                <div className="extra">
                    <span className='selct-box ts13 bold7 selected'>변경</span>
                </div>
            );
            break;
        case ExtraButtonType.Time:
            returnValue = (
                <div 
                    className="extra" 
                    onClick={() => {
                        if(toggleOff){
                            setInputValue("시간 모름");
                        }else {
                            setInputValue("");
                        }

                        setToggleOff(!toggleOff);
                    }}
                >
                    {
                        toggleOff ? 
                            <img className="time-unknown-button mr5" src='images/member_integrated/uncheckbox.png'></img>
                            : <img className="time-unknown-button mr5" src='images/member_integrated/checkedbox.png'></img>
                    }
                    <span className="ts14 bold5">시간 모름</span>
                </div>
            );
            break;
        default:
            returnValue = (<span></span>);
            break;

    }

    return returnValue;
};

export {
    JeomsinInputBox,
    InputBoxType,
    ExtraButtonType
}