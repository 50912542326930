export type SajuMyungsikInfo = {
    classTitle: string,
    classChineseChar: string,
    classOhang: string,
    classEumyang: string,
    classBearing: string,
    classColor: string,
    classSeason: string,
    description: string,
    characteristics: string,
    detailTitle: string,
    detailSubTitleMeaning: string,
    detailSubTitleCharacteristics: string,
    detailResult: string
}

type SajuMyungsikDatas = {
    birthSajuData:SajuMyungsikInfo[],
    birthSipSungData:SajuMyungsikInfo[],
    birthSipYiUnSungData:SajuMyungsikInfo[],
    birthChunganData:SajuMyungsikInfo[],
    birthJijiData:SajuMyungsikInfo[],
    birthMyOhangData:SajuMyungsikInfo[]
}
const SajuMyungsikData = () => {
    const datas:SajuMyungsikDatas = {
        birthSajuData: [birthSiju, birthIlju, birthWolju, birthNyunju],
        birthSipSungData: [
            birthSipSung1,
            birthSipSung2,
            birthSipSung3,
            birthSipSung4,
            birthSipSung5,
            birthSipSung6,
            birthSipSung7,
            birthSipSung8,
            birthSipSung9,
            birthSipSung10,
            birthSipSung11,
          ],
        birthSipYiUnSungData: [
            birthSipYiUnsung1,
            birthSipYiUnsung2,
            birthSipYiUnsung3,
            birthSipYiUnsung4,
            birthSipYiUnsung5,
            birthSipYiUnsung6,
            birthSipYiUnsung7,
            birthSipYiUnsung8,
            birthSipYiUnsung9,
            birthSipYiUnsung10,
            birthSipYiUnsung11,
            birthSipYiUnsung12,
          ],
        birthChunganData: [
            birthChungan1,
            birthChungan2,
            birthChungan3,
            birthChungan4,
            birthChungan5,
            birthChungan6,
            birthChungan7,
            birthChungan8,
            birthChungan9,
            birthChungan10,
          ],
        birthJijiData: [
            birthJiji1,
            birthJiji2,
            birthJiji3,
            birthJiji4,
            birthJiji5,
            birthJiji6,
            birthJiji7,
            birthJiji8,
            birthJiji9,
            birthJiji10,
            birthJiji11,
            birthJiji12,
          ],
        birthMyOhangData: [
            birthMyOhang1,
            birthMyOhang2,
            birthMyOhang3,
            birthMyOhang4,
            birthMyOhang5,
          ]
    }

    return datas;
}

const birthNyunju: SajuMyungsikInfo = {
    classTitle: "년주",
    classChineseChar: "",
    classOhang: "",
    classEumyang: "",
    classBearing: "",
    classColor: "",
    classSeason: "",
    description: "태어난 년도의 기둥을 상징",
    characteristics: "초년운, 조상, 부모, 국가를 상징",
    detailTitle: "년주란?",
    detailSubTitleMeaning: "태어난 년도의 기둥을 상징",
    detailSubTitleCharacteristics: "초년운, 조상, 부모, 국가를 상징",
    detailResult: "태어난 년도 년주는 사주에서 뿌리입니다. \n계절로는 봄, 하루 중 아침을 상징하고,\n사주 중에서 년주는 근본이 되어 국가나 조상, 부모를 뜻합니다. \n존재감이 없어 보일 수 있으나 조상이 있기에 사주 주인공이 존재하는 것입니다.  \n\n년주가 있기 때문에 월, 일, 시주가 있습니다.\n년주는 사주의 초년운 시기로 1~15세까지를 뜻합니다.\n즉, 년주는 자라 온 성장 배경이나 환경, 소년소녀 시절을 상징합니다. \n그래서 년주는 현재가 아닌 과거형이지만, \n인생의 기초 역할로 인생을 지배하기도 합니다. \n어린 시절 다양한 사건의 발생으로 트라우마가 남기도 하는 시기입니다.",
};

const birthWolju:SajuMyungsikInfo = {
    classTitle: "월주",
    classChineseChar: "",
    classOhang: "",
    classEumyang: "",
    classBearing: "",
    classColor: "",
    classSeason: "",
    description: "태어난 월의 기둥을 상징",
    characteristics: "집안, 부모, 집안의 성향을 상징",
    detailTitle: "월주란?",
    detailSubTitleMeaning: "태어난 월의 기둥을 상징",
    detailSubTitleCharacteristics: "집안, 부모, 집안의 성향을 상징",
    detailResult: "태어난 월 월주는 사주에서 존재감이 높습니다.\n계절로는 여름, 하루 중 낮을 상징하고, 청년(여성)의 시기로 20~30세까지를 뜻합니다. \n월주는 자신이 태어 나고 자라 온 집안을 뜻합니다. \n즉, 가족을 의미하여 부모와 형제를 뜻하기도 합니다.\n\n천간 글자는 아버지, 지지 글자는 어머니입니다.\n사주의 월주는 가풍, 성향이나 가치관뿐 아니라 \n사회성, 업무 방식, 사회적인 이상과 목표 달성 방법 등을 좌지우지합니다.",
};

const birthIlju:SajuMyungsikInfo = {
    classTitle: "일주",
    classChineseChar: "",
    classOhang: "",
    classEumyang: "",
    classBearing: "",
    classColor: "",
    classSeason: "",
    description: "태어난 일의 기둥을 상징",
    characteristics: "본인을 상징, 외모, 내면을 상징",
    detailTitle: "일주란?",
    detailSubTitleMeaning: "태어난 일의 기둥을 상징",
    detailSubTitleCharacteristics: "본인을 상징, 외모, 내면을 상징",
    detailResult: "태어난 일 일주는 바로 자신, 나를 뜻합니다. \n사주 중 현재형이므로 가장 중요한 자리이기도 합니다.    \n계절로는 가을, 하루 중 저녁을 상징하며, \n중년의 시기를 뜻합니다.\n곧 사주의 자신과 배우자를 의미합니다.\n\n일주는 기본적으로 성격을 상징합니다. \n일주의 일간(日干)은 겉모습이나 드러나는 성향을 뜻하고,\n아랫부분은 배우자 자리인 동시에 속내,  잠재된 끼나 재주\n진정성 있는 마음, 느낌 등을 상징합니다.",
};

const birthSiju:SajuMyungsikInfo = {
    classTitle: "시주",
    classChineseChar: "",
    classOhang: "",
    classEumyang: "",
    classBearing: "",
    classColor: "",
    classSeason: "",
    description: "태어난 시의 기둥을 상징",
    characteristics: "자식운, 미래를 상징",
    detailTitle: "시주란?",
    detailSubTitleMeaning: "태어난 시의 기둥을 상징",
    detailSubTitleCharacteristics: "자식운, 미래를 상징",
    detailResult: "태어난 시간 시주는 미래를 상징합니다.\n자식을 뜻하고, 시주의 말년운을 의미합니다. \n계절로는 겨울, 하루 중 밤을 뜻합니다.\n년주와 반대되는 시주는 미래를 의미합니다. \n\n시주는 자식과의 모든 문제나 관계입니다.\n시주의 영향력은 사주에서 상당하지는 않지만, \n시주의 방향과 여부에 따라 사주의 전반적인 구조가 바뀔 수 있습니다. ",
};

const birthSipSung1:SajuMyungsikInfo = {
    classTitle: "비견",
    classChineseChar: "比肩",
    classOhang: "",
    classEumyang: "",
    classBearing: "",
    classColor: "",
    classSeason: "",
    description: "(친구, 동료, 형제, 라이벌)  나와 유사한 조건의 사람들이 많은 삶",
    characteristics: "경쟁심, 독립적, 협력, 자기애, 고집, 자만",
    detailTitle: "비견(比肩)이란?",
    detailSubTitleMeaning: `견줄 비比, 어깨 견 肩, \"나와 어깨를 견주다\" \n 인간관계에서 나와 어깨를 견주는 사이`,
    detailSubTitleCharacteristics: "나를 지켜주는 인간관계, 강한 자존심",
    detailResult: "비견은 인간관계에서는 형제, 자매, 동료. 친구 등 나와 밀접하고 가까운 사이를 의미합니다.\n대인관계에서 남과 협력하여 화합을 도모하기도 하지만 동시에 경쟁의식 또한 강하게 가지고 있는 것이 특징입니다. \n\n비견은 자존심과 고집, 승부욕이 굉장히 강합니다.\n또 독립정신과 개척정신이 강하고 투철하여 다른 사람에게 의지하지 않고도 성공을 이룰 수 있습니다.\n이런 성향은 겉으로 드러나거나 내면으로 잠재되어 사생활의 영역에서 강하게 발현됩니다.\n그래서 타인과 교류가 원활하지 않을 수 있어 직장이나 결혼 생활에 어울리지 않는 경향이 있습니다.\n \n비견이 강한 사람들은 나의 주변 사람과 협력하는데 능력을 발휘하여 화합을 도모하고 자신과 가까운 주변인을 잘 챙겨줍니다. \n하지만 반대로 동료가 때론 경쟁자가 되기도 하여 자신의 논리를 내세우고 다투기도 합니다.\n\n장점\n내 편이 많아 주변에서 도움을 많이 줍니다.\n독립심과 자존심과 승부욕이 강해 의존적이지 않으며 성공할 가능성이 높습니다.\n\n단점\n주변 사람과 다툼이 잦은 편입니다.",
};

const birthSipSung2:SajuMyungsikInfo = {
    classTitle: "겁재",
    classChineseChar: "劫財",
    classOhang: "",
    classEumyang: "",
    classBearing: "",
    classColor: "",
    classSeason: "",
    description: "모두 갖거나 모두 빼앗기는 극단적인 성향을 보이는 삶",
    characteristics: "투쟁심, 승부욕, 질투, 극단적",
    detailTitle: "겁재(劫財)란?",
    detailSubTitleMeaning: `위협할 劫, 재물 財, \"재물을 겁탈한다\"\n 내가 가져야 할 재물을 겁재가 호심탐탐 노리고 있다가 빼앗아 가는 것`,
    detailSubTitleCharacteristics: "내것을 뻇기지 않으려는 욕심, 극단적 성향",
    detailResult: "겁재는 \"모 아니면 도\" 라는 극단성을 가지고 있어서 가지면 모두 가지고, 빼앗기면 모두 빼앗기도 합니다. \n자신의 것을 뺏기지 않기 위해 소유욕이 강하며 이에 대기업 총수일수록 사주에 겁재가 많이 있습니다.\n겁재의 기운이 강하면 강력한 카리스마의 소유자로 자존심이 강하고 항상 승부욕과 투쟁심, 질투심이 강한 특징이 있습니다.\n\n겁재가 강한 사람은 스스로는 원치 않지만 재물, 능력, 인간관계, 사회생활을 잃기도 합니다. \n재산 소실이나 불화의 요소를 가지고 있어 항상 투쟁심이 불타며 승부욕, 질투심, 집념이 대단합니다.\n한번 결정한 일은 끝까지 밀어붙이는 추진력과 또 이를 이루는 능력까지 갖추고 있어 사회에서 성공하는 경우가 많습니다.\n\n겁재는 극단성도 강하여 전체의 판을 장악할 막강한 힘도 있고,\n자기 자신을 망가뜨리는 사회 부적응이 되기도 합니다.\n이것은 배신으로 나타나기도 하는데, 자주 배신을 당하거나 본인이 상대방을 자주 배신하는 상황을 겪게 됩니다. \n\n장점\n자신의 것을 뺏기지 않기 위해 철저하며 소유욕이 강해 성공하는 사람이 많습니다.\n\n단점\n집착이 필요 이상으로 강한 편입니다.\n자신의 많은 것을 의도치 않게 뺏길 수 있는 기운이 많아 항상 지키기 위한 노력이 필요합니다.",
};

const birthSipSung3:SajuMyungsikInfo = {
    classTitle: "식신",
    classChineseChar: "食神",
    classOhang: "",
    classEumyang: "",
    classBearing: "",
    classColor: "",
    classSeason: "",
    description: "의식주가 편안하고 안정적인 삶",
    characteristics: "긍정적, 말솜씨, 배려심, 결단력 결여, 우유부단",
    detailTitle: "식신(食神)이란?",
    detailSubTitleMeaning: `먹을 食, 귀신 神, \"의식주와 관련된 복\"\n 의식주에 있어서 부족함이 없다는 의미`,
    detailSubTitleCharacteristics: "걱정없는 의식주, 낙천적 성향",
    detailResult: "식신은 '먹을 복'을 의미하여 크게 노력하지 않고도 재물을 얻을 수 있는 복,\n물려받은 유산을 뜻하고, 장수의 기운이 있습니다.\n대체로 심성이 착하고 배려심이 가득하고 온화하며 낭만적인 성향도 \n가지고 있습니다.\n\n주어진 복이 많으니 식신은 낙천성을 의미하기도 하고,그만큼 시련과 고난을 겪지 않았다는 것을 볼 수 있습니다. \n억지로 노력하지 않아도 먹고 사는데 불편함이 없기에 식신이 강한 사람들은 낙천적이고 명랑하며 예술적 감수성이 좋으며,신선한 창의력을 가지고 있습니다. \n\n식신은 일을 추진하긴 하나 두려움이 많아 난관에 부딪히면 바로 포기하고 작은 시련에돠 좌절하는 경우가 많습니다.\n느긋하고 온화한 성격은 장점이지만 무책임한 태도나결단력과 용기가 부족한 모습은 식신의 단점이 됩니다.\n\n장점\n의식주에 있어 큰 어려움 없이 살아갈 수 있는 운이 강합니다.\n주변 사람들을 잘 살피며 보살핍니다.\n\n단점\n결단력과 끈기가 부족해 일을 시작하고 마무리가 되지 않는 경우가 많습니다.",
};

const birthSipSung4:SajuMyungsikInfo = {
    classTitle: "상관",
    classChineseChar: "傷官",
    classOhang: "",
    classEumyang: "",
    classBearing: "",
    classColor: "",
    classSeason: "",
    description: "자신의 재능을 마음것 뽐내는 삶",
    characteristics: "총명, 특별한재능, 부지런, 적극적, 논리적",
    detailTitle: "상관(傷官)이란?",
    detailSubTitleMeaning: `상처 傷, 벼슬 官, \"벼슬을 상하게 하는 기운\"\n 자신의 능력을 표출하는것을 의미`,
    detailSubTitleCharacteristics: "불의를 보면 못참는 성격, 구설수에 잘 오름",
    detailResult: "상관은 비상한 면이 있고, 다재다능합니다. 좋은 의미로는 창조적인 능력이 있고,\n예술적 자질이나 재주를 바탕으로 한 자기 표현을 잘 합니다.\n반면 오지랖이 넓고 언변이 뛰어나 반골적·비판적 성향도 굉장히 강합니다.\n상관이 강한 사람들은 오만함과 자존심만 버리면 최대의 능력을 발휘하여 최고의 자리에 오를 수 있습니다.  \n\n기본적으로 천재성이 돋보이는 총명함을 가진 상관은 예리하고 민첩하며 순발력이 좋습니다.\n또 창조성이 굉장히 뛰어나 자기 자신을 표현하는데 특유의 감각이 있고, 예술적 감성을 가지고 있습니다. \n\n상관이 강한 사람들은 부당함에 반발하고 필요에 따라 날카롭게 정의를 부르짖고 날선 언변으로 사람들에게 상처를 주기도 합니다. 이에 말 때문에 자신의 인덕을 깎아 먹거나 구설수에 오르기 쉽습니다. \n\n하지만 상관은 다소 거칠고 직설적이지만 그 내면은 굉장히 여리고 약합니다. \n그래서 약자에 대한 희생, 봉사정신과 동정심도 강하여 자신의 이익을 챙기기보단\n자신이 포기하고 희생해 버리곤 합니다.",
};

const birthSipSung5:SajuMyungsikInfo = {
    classTitle: "편재",
    classChineseChar: "偏財",
    classOhang: "",
    classEumyang: "",
    classBearing: "",
    classColor: "",
    classSeason: "",
    description: "치우친 재물을 지키기위해 노력하는 삶",
    characteristics: "현실적, 기획력, 한탕주의, 봉사, 대인관계",
    detailTitle: "편재(偏財)란?",
    detailSubTitleMeaning: `치우칠 偏, 재물 財 \"한쪽으로 치우친 재물\"\n 우연치않게 획득한 재물을 의미`,
    detailSubTitleCharacteristics: "횡재, 허욕, 욕심, 투기, 투자, 주식, 복권의 키워드!!",
    detailResult: "편재는 한탕주의의 성격을 지녔고, 금전을 상징합니다.  \n모든 일의 판을 잘 짜고, 설계하는 능력이 탁월합니다.  \n타고난 촉, 감각이 탁월하여 한눈에 전체 흐름을 읽습니다.\n현실적인 안목이 있는 만큼 이익을 볼 수 있는 것도 잘 찾아\n과감하게 투자도 잘 합니다. 이런 성향으로 편재는 사업가도 많습니다.\n스케일과 활동 범위가 넓어서 다양하게 일을 잘 벌여 돈도 잘 벌 수 있습니다.  .\n호탕한 성격처럼 큰돈이 생기면 기부금도 잘 냅니다. \n\n이성과의 스캔들이 우려되니 주의해야 합니다. 직장 여성이나 사업을 하는 여성은 일과 가정 사이에서 고민하는 경향이 많아 별거나 이혼하는 경우도 많습니다. \n\n장점\n매사 유연하고 유머감각이 있어 이성에게 인기가 많습니다. \n사업수완이 좋고, 활발한 대인관계를 유지합니다. \n\n단점\n경제적이지 못해 낭비벽이 심하고, 일확천금을 노립니다.  \n유흥‧이성‧도박 등의 문제를 일으킬 수 있습니다.",
};

const birthSipSung6:SajuMyungsikInfo = {
    classTitle: "정재",
    classChineseChar: "正財",
    classOhang: "",
    classEumyang: "",
    classBearing: "",
    classColor: "",
    classSeason: "",
    description: "있는것을 지키며 안정적인 삶 추구",
    characteristics: "노력, 성실, 현실성, 인색함, 검소함, ",
    detailTitle: "정재(正財)란?",
    detailSubTitleMeaning: `바를 正, 재물 財 \"정당하고 올바른 재물\"\n 내 몸에 지닌 재물, 즉 안정적인 수익을 의미`,
    detailSubTitleCharacteristics: "안정적인 수입, 꾸준한 노력파",
    detailResult: "정재는 일정한 노력을 통해 고정적인 재물 취득을 의미합니다. \n직장인의 월급, 정당한 방법으로 취득한 자영업자들의 수입이 해당됩니다. 그래서 정재는 근면성실하고 정직한 것이 특징입니다. 안정적으로 돈을 벌 기회를 잘 잡고, 그것을 지키고자 하는 성향이 강합니다.\n\n정재는 현실적이라 실현 가능한 목표만을 세우고, 매사에 한결같은 태도로 일을 완수하여 신용이 좋습니다.    \n요행을 바라지 않고 늘 정해진 길만 묵묵히 걸어나가 사람들은 고지식하거나 융통성이 없는 사람으로 판단하기도 합니다. 정재가 강한 사람은 돈을 쓰고 생색내지도 않지만, 잘 드러나지도 않습니다. \n\n장점\n검소한 성향이 강하여 낭비하지 않습니다. \n편법을 쓰지 않아 믿을 수 있습니다.\n\n단점\n내 눈앞에 있는 것만 봐서 남보다 앞서기 힘듭니다. \n나눔에 인색한 편입니다.",
};

const birthSipSung7:SajuMyungsikInfo = {
    classTitle: "편관",
    classChineseChar: "偏官",
    classOhang: "",
    classEumyang: "",
    classBearing: "",
    classColor: "",
    classSeason: "",
    description: "자신과 남을 제어하려 하는삶",
    characteristics: "예측불허, 자신감, 권위적, 절제력",
    detailTitle: "편관(偏官)이란?",
    detailSubTitleMeaning: `치우칠 偏, 벼슬 官 \"치우친 벼슬자리\"\n 나를 강하게 억제하고 제압하며 강한 카리스마를 의미`,
    detailSubTitleCharacteristics: "나를 호시탐탐 노리는 강한 힘, \"폼생폼사(폼에 살고 폼에 죽는다)",
    detailResult: "편관은 폭발적인 에너지와 과감한 남성적인 에너지를 의미합니다. \n특유의 배짱이 있어서 강한 카리스마를 뿜어내서 사람들로부터 존경을 받기도 합니다.\n편관은 상대방이 자신을 이용하거나 제압하려 하면 당하고 있지 않습니다.\n사람들을 압도하는 직업이나규율이 엄격한 군인, 경찰, 검찰, 정보기관이나 특수기관, 운동선수가 어울립니다.\n\n강력한 권위나 명예를 누리기도 하지만 사고나 질병 등의 재난을 당할 수 있습니다. \n여성은 가정에 있기보다는 사회생활을 하는것이 좋습니다. 남성의 경우 자신보다 기가 센 배우자를 만날 가능성이 높습니다. \n\n장점\n용감하며 결단력이 있습니다.\n상대방이 도움을 요청하면 자신을 희생하여 도와줍니다. \n\n단점\n지나친 권력욕과 명예욕이 있습니다.\n자만심이나 허세가 있어 실속이 없기도 합니다.  \n",
};

const birthSipSung8:SajuMyungsikInfo = {
    classTitle: "정관",
    classChineseChar: "正官",
    classOhang: "",
    classEumyang: "",
    classBearing: "",
    classColor: "",
    classSeason: "",
    description: "규율을 지키며 정돈된 삶",
    characteristics: "합리적, 이성적, 논리적, 보수적",
    detailTitle: "정관(正官)이란?",
    detailSubTitleMeaning: `바를 正, 벼슬 官 \"올바른 벼슬자리\"\n 안정적이고 철두철미한 삶을 의미`,
    detailSubTitleCharacteristics: "곧은 성품, 고리타분함 속 절제된 삶",
    detailResult:
        "정관은 안정적인 삶과 보수적인 성향이 강합니다. 항상 예측 가능한 범위에서 생활하며 충동적인 행동을 자제해 변수를 최소화 시킵니다. \n남자에게는 자식과 명예, 직장이 되고, 여자에게는 남편이 되기 때문에 과거나 현대에나 중요한 역할을 합니다.\n\n정관은 법과 질서를 중요하게 생각하여 규칙적인 삶을 살아가는 사람이 많습니다. \n예의를 존중하고 재물보다는 명예를 소중히 여깁니다.\n그래서 정관은 신용을 지키고, 준법 생활을 하며 살아갑니다. \n원칙만을 고수하다보니 고지식하다는 말을 듣기도 합니다. \n책임을 다해야 직성이 풀려 무책임한 행동을 한다거나 남에게 폐를 끼치는 일은 하지 않습니다.   \n여성은 정관이 남편을 의미하므로 현모양처의 기질을 선천적으로 가지고 있어  '이 시대의 신사임당' 입니다. \n\n장점\n\"법 없이도 살 사람\" 으로 책임감, 절제력이 강합니다. \n차분한 성향으로 매사 사려 깊고 온화합니다.\n\n단점\n외부의 시선을 의식해 자기 자신을 늘 평가하여 예민합니다. \n고지식하여 융통성이 없습니다.",
};

const birthSipSung9:SajuMyungsikInfo = {
    classTitle: "편인",
    classChineseChar: "偏印",
    classOhang: "",
    classEumyang: "",
    classBearing: "",
    classColor: "",
    classSeason: "",
    description: "관심있는 분야에 파고들며 열정을 보이는 삶",
    characteristics: "의심, 임기응변, 호기심, 순발력",
    detailTitle: "편인(偏印)이란?",
    detailSubTitleMeaning: `치우칠 偏, 도장 印 \"한쪽으로 치우친 자격\"\n 내가 노력하지 않고 가만히 있어도 나를 채워주는 비상한 능력을 의미`,
    detailSubTitleCharacteristics: "4차원 성향, 모 아니면 도",
    detailResult: "편인은 치우쳐진 재능으로 남이 가지고 있지 않은 독특하고 유별난 성향이 있습니다. \n조급한 편이기도 하고, 두뇌 회전이 빨라 순발력이 좋습니다.\n\n다방면에 재능이 있으나 용두사미형 입니다.\n처음에 시작한 일을 끝까지 마무리하지 못하는 경향이 있습니다. \n연애도 이와 같아 처음에는 불같은 사랑을 하지만 \n금새 애정이 식어 다른 이성에게 눈을 돌리기도 하니 주의해야 합니다. \n편인이 가장 성공하기 위해서는 초지일관하면 목표한 바를 성취할 수 있습니다. \n\n장점\n독창성, 창의성이 뛰어납니다. \n영적인 능력이 탁월하여 해당 분야의 최고점을 찍을 수 있습니다. \n\n단점\n스스로 사회적 고립을 자초하고, 불안이나 짜증, 우울증에 시달리기도 합니다. \n변덕이 심하고, 의존성이 강합니다.",
};

const birthSipSung10:SajuMyungsikInfo = {
    classTitle: "정인",
    classChineseChar: "正印",
    classOhang: "",
    classEumyang: "",
    classBearing: "",
    classColor: "",
    classSeason: "",
    description: "지혜와 학문을 바탕으로 정직하고 품위있는 삶",
    characteristics: "순수함, 직관력, 수동적, 이해력",
    detailTitle: "정인(正印)이란?",
    detailSubTitleMeaning:
        `바를 正, 도장 印 \"올바른 자격\"\n 나를 적절하게 도와주는 기운, 나의 부족한 부분을 채워주는 힘을 의미`,
    detailSubTitleCharacteristics: "\"학자, 학문\",  \"전교 1등\"",
    detailResult: "정인은 남녀 모두 나를 낳아준 어머니를 뜻하기에 어려서부터 어머니의 영향을 많이 받습니다. \n정인의 특징은 총명하고, 선량하며 안정적인 힘이 있습니다. 정인은 부모님으로부터 풍족한 지원을 받는 경우가 많고, 어려운 일이 있을 때도 의외의 귀인을 만나 극복할 수 있습니다.\n\n전형적인 모범생 스타일로 공부를 잘하는 편이라 안정적인 의식주를 영위합니다. \n편안한 생활에 안주하지 않고 명석한 노력가이기도 합니다.  \n또, 사회 질서나 양심, 의무를 중시합니다. \n따라서 공동체의 규범을 잘 따릅니다. \n\n장점\n여유있고 느긋한 태도로 지도자로 존경받을 수 있습니다.  \n탐구심이 강합니다. \n\n단점\n고집이 세나 결단력이 부족하고 순발력이 떨어집니다.\n어머니의 지나친 간섭으로 의존성이 강한 마마보이를 만들 수 있습니다.  ",
};

const birthSipSung11:SajuMyungsikInfo = {
    classTitle: "일원",
    classChineseChar: "日元",
    classOhang: "",
    classEumyang: "",
    classBearing: "",
    classColor: "",
    classSeason: "",
    description: "일원이란 일간이란 말과 같은 뜻, 사주에서는 일간은 나를 가리키는 말, 기호",
    characteristics: "",
    detailTitle: "일원(日元) 이란?",
    detailSubTitleMeaning: `날 일日, 으뜸 원 元 \"하나뿐인 나 자신\"\n 나 자신을 상징하는 것`,
    detailSubTitleCharacteristics: "나 자신을 상징",
    detailResult: "일원은 나 자신을 뜻합니다. \n일원에 따라 같은 오행이 오더라도 십성은 달라질 수 있고,   \n나 자신을 기준으로 삼기 때문에 전반적인 운도 달라질 수 있습니다.\n그래서 일원은 사주를 볼 때 가장 중요한 요소이기도 합니다. \n사주를 구성하는 여덟글자는 일원을 중심으로 서로 합치기도하고 대립하기도 하며 조화를 이룹니다.\n사주는 이러한 음양오행의 조화를 꾀하며 균형과 중용을 이뤄 운명을 개척하는것을 이상적으로 생각합니다.",
};

const birthSipYiUnsung1:SajuMyungsikInfo = {
    classTitle: "장생",
    classChineseChar: "長生",
    classOhang: "",
    classEumyang: "",
    classBearing: "",
    classColor: "",
    classSeason: "",
    description: "세상에 막 태어난 생명처럼 순수함",
    characteristics: "섬세함, 영특함, 의지력 결여",
    detailTitle: "장생(長生)이란?",
    detailSubTitleMeaning: "긴(첫) 장長, 날 생生 태어나서 첫 울음을 터트리는 시기, 세상 밖으로 탄생한 순수한 생명을 의미",
    detailSubTitleCharacteristics: "티 없이 맑은 나를 더럽힐 생각마, 어리숙함",
    detailResult: "장생은 길성으로 어두웠던 세계가 밝아지는 기운입니다. \n미성숙한 자아가 완전해지는 상태입니다.\n장생을 비유하자면 어린 아이의 순수한 미소입니다. \n깨끗하고 투명한 성향으로 순수한 성품과 심성이 돋보입니다.\n그래서 장생은 신비롭고 수수한 이미지를 가진 사람이 많습니다.\n예의바르고 성격이 온화하고, 성장하려는 의지가 강합니다.\n장생은 성취 동기가 뚜렷하여 발전적이기도 합니다.  \n열정적이고 순수한 힘으로 새로운 환경에서 새 출발하여  큰 성장이 가능합니다. \n천성적으로 인덕과 후원을 가지고 있습니다.\n조상이나 부모를 잘 만나 유복한 경우가 많고, 부모의 은덕이 오래 지속됩니다.\n탐험하고 발견하고 자신의 것을 만들어나가는 정신이 강하고 \n독립하고자 하는 성향이 있지만\n도움을 받지 못하면 성공하지 못하는 경우가 많습니다.\n\n-직업\n직업적으로 작가, 기자가 어울립니다. 실제로 극작가 김수현 작가는 장생을 대표하는 인물입니다.\n직장이라는 관의 보호를 받을 수 있기 때문에 \n직장인도 안성맞춤입니다. \n\n본인자체가 좋은 배우자로서 안정된 삶을 살아갑니다. \n\n-장점\n섬세하고 총명하며, 지식에 대한 열망이 대단하기도 합니다. \n예술적인 재능과 기예, 문예 등에 능합니다. \n\n-단점\n주변의 영향을 많이 받기 때문에 주체성이 부족하고, 리더십이 부족합니다.\n크고 작은 고난을 겪을 수 있습니다. ",
};

const birthSipYiUnsung2:SajuMyungsikInfo = {
    classTitle: "목욕",
    classChineseChar: "沐浴",
    classOhang: "",
    classEumyang: "",
    classBearing: "",
    classColor: "",
    classSeason: "",
    description: "세상과 마주하며 생기가 넘침",
    characteristics: "사교성, 호기심, 창의성",
    detailTitle: "목욕(沐浴)이란?",
    detailSubTitleMeaning: "머리 감을 沐목, 목욕할 浴욕 태어난 유아가 자라나 세상에 나기기 전에 깨끗이 몸단장을 하는 것을 의미합니다. ",
    detailSubTitleCharacteristics: "수집광, 오타쿠 모여라!",
    detailResult: "목욕은 길성과 흉성이 공존하는 것이 특징입니다. \n또, 유아기적인 시기를 거치면서 사회적 생활이 시작되는 단계이기도 합니다.\n배움과 학습의 반복으로 경험을 많이 쌓을 수 있고,\n여러 가지 다양한 시도를 하나 마무리가 부족할 수 있습니다. \n사회성이 급속도로 성장하는 시기라 사람들과 교류가 많습니다.\n생기가 있고 쾌활하며 순수한 기운이 넘칩니다.\n매력이 넘치고 자기 과시력이 강하여 도화살과 연결되기도 합니다.\n패가망신 할 만큼 큰 실수로 인한 사연을 겪을 가능성이 높습니다. \n그러나 그 이후에는 회복하여 성취하고, 재기할 수 있습니다 \n많은 사람을 사귀는 것을 좋아하기 때문에 배우자 인연이 약합니다.\n\n-직업\n1.영업, 외교관, 해외 상사원, 중매자, 매체 언론, 도매업, 파티 플래너\n2.디자인, 패션, 뷰티, 방송, 예술, 기예, 배우, 가수, 멋쟁이\n\n-장점\n사교성이 우수하여 대인관계가 원만합니다.\n호기심이나 섬세한 감각이 발달되어 창의성, 예술적 재능이 뛰어납니다.\n\n-단점\n끈기가 부족하여 경거망동하기 쉽습니다.\n도박이나 유흥, 이성을 좋아하여 다양한 문제가 생길 수 있습니다. ",
};

const birthSipYiUnsung3:SajuMyungsikInfo = {
    classTitle: "관대",
    classChineseChar: "冠帶",
    classOhang: "",
    classEumyang: "",
    classBearing: "",
    classColor: "",
    classSeason: "",
    description: "자신만의 정체성을 보여줌",
    characteristics: "강한 에너지, 자존심, 독단적",
    detailTitle: "관대(冠帶)란?",
    detailSubTitleMeaning: "갓 冠관, 허리띠 帶대 관(갓)을 쓰고, 대(허리띠)장원급제하여 두르는 띠를 의미",
    detailSubTitleCharacteristics: "불가능한 것도 가능해지라, 피끓는 청춘의 힘!",
    detailResult: "관대는 청년기(10대 중반~20대 초)로, 인격을 갖춰나갑니다.\n성숙하지 못하여 예의범절을 배울 때이기도 하고,\n실수나 실패를 경험하고 극복하며 혈기왕성합니다.\n독립심이 강하고, 성취욕이나 승부욕, 정면돌파 성향이 두드러지지만 \n반면에 어리고 미성숙하여 세상 이치에 많이 어둡습니다. \n관대는 미숙한 시작임에도 불굴의 의지로 좋은 결실을 맺기도 합니다.\n관대에서는 격렬한 운동으로 육체적인 힘을 빼는 게 좋습니다.\n관대는 대기만성형이 많아  \n젊을 때 보다 40대 이후 자수성가하는 사람이 많습니다. \n남성의 경우 자녀의 성공을 얻을 수  있으나\n부부궁합이 좋지 않은 경우도 있습니다. \n\n-직업\n관과 관련된 직업, 공무원, 법조계, 경찰, 군인 \n경영, 교육, 언론계, 의료계\n\n-장점\n진취적이고, 개척정신이 강하여 발전적입니다. \n외모가 출중하고, 예체능계 재능이 뛰어납니다.\n\n-단점\n다른 사람 비판하기를 즐기고, 배려심이 부족하여 타인과의 트러블이 많습니다.\n자기중심적 사고와 고집으로 일을 그르치기 쉽습니다.\n",
};

const birthSipYiUnsung4:SajuMyungsikInfo = {
    classTitle: "건록",
    classChineseChar: "健祿",
    classOhang: "",
    classEumyang: "",
    classBearing: "",
    classColor: "",
    classSeason: "",
    description: "스스로의 힘으로 세상을 이김",
    characteristics: "추진력, 권위적, 현실적",
    detailTitle: "건록(健祿)이란?",
    detailSubTitleMeaning: "굳셀 健건, 복 祿록 사회에 나가 지속적으로 돈을 버는 '완전한 어른'이 된 상태를 의미",
    detailSubTitleCharacteristics: "인생의 황금기, 의욕과 정열이 불타오르는 때로 과감하게 도전하라!",
    detailResult: "건록은 현실적인 시기로 인생의 목표를 향해 전진합니다. \n세상의 모든 풍파를 견딜 수 있는 강한 힘이 있습니다. \n건록의 총명함으로 세상에 우뚝 서는 기운이 강합니다.\n독립성이 강한 자수성가형입니다.\n건록은 안에 있기 보다는 밖으로 나가서 힘을 써야 재물이 쌓입니다.\n부를 누릴 충분한 역량과 여유로움이 있습니다. \n일찍 결혼하기 보다 세상의 노하우가 쌓인 뒤에 결혼하는 것이 이롭고,\n무엇보다 배우자를 잘 만나는 것이 중요합니다.  \n\n-직업\n프리랜서, 사업가, 영업, 감사, 연구원, 기술자, 학원 강사, 엔터테인먼트, 스포츠\n\n-장점\n강한 자신감과 추진력이 있습니다. \n노련함이나 숙련미가 남들보다 앞섭니다.\n\n-단점\n이기적인 성향이 강하고 권위적입니다.\n인복이 없고,  명예와 체면을 중요시합니다.",
};

const birthSipYiUnsung5:SajuMyungsikInfo = {
    classTitle: "제왕",
    classChineseChar: "帝王",
    classOhang: "",
    classEumyang: "",
    classBearing: "",
    classColor: "",
    classSeason: "",
    description: "가장 강한 힘을 바탕으로 원하는바를 이룸",
    characteristics: "외로움, 독단적, 의리",
    detailTitle: "제왕(帝旺)이란?",
    detailSubTitleMeaning: "임금 帝제, 왕성할 旺왕 권력이 가장 강한 왕좌에 올랐다는 것을 의미",
    detailSubTitleCharacteristics: "노력에 대한 보람과 보상을 거둬들인다!",
    detailResult: "제왕은 권위, 권력, 정점, 절대적인 힘을 가지고 있는 상태입니다. \n개인주의적 성향과 우두머리 기질이 강합니다. \n육체와 정신, 경험과 기술이 최고점이며, 사회와 가족에서 강한 존재감을 가지고 있습니다.\n이 강력한 힘은 폭력, 구속, 실직으로 연결될 수 있습니다. \n그러나 얻을 것보다 잃을 것이 많기 때문에 보수적이고 방어적이기도 하며,\n주도하는 것을 즐기기 때문에 구속이나 통제받는 것을 싫어합니다\n부부의 연이 박하여 결혼을 하더라도 독신처럼 살거나 이혼할 수 있습니다.\n\n-직업\n국가 자격증 취득, 공무원, 의사, 약사, 공인중개사 안정적인 직업이 보장되는 직장운 \n\n-장점\n넓은 도량과 큰 배포를 가지고 도움을 요청하면 흔쾌히 도움을 줍니다.\n결단성, 리더십이 있어서 주변을 잘 아우릅니다.\n\n-단점\n인간관계에 능숙한 반면 고독함과 외로움을 늘 가지고 있습니다.\n독선적이고 주관적인 성향이 강합니다.\n야망과 공명심을 지키기 위한 낭비가 많습니다.",
};

const birthSipYiUnsung6:SajuMyungsikInfo = {
    classTitle: "쇠",
    classChineseChar: "衰",
    classOhang: "",
    classEumyang: "",
    classBearing: "",
    classColor: "",
    classSeason: "",
    description: "점차 기운이 쇠하고 내려가게 됨",
    characteristics: "자비로움, 고독함, 묵묵함",
    detailTitle: "쇠(衰)란?",
    detailSubTitleMeaning: "쇠한 衰쇠 서서히 기운이 빠져가는 시기로 인생의 내리막길을 의미",
    detailSubTitleCharacteristics: "지는 해, 담백한 사람, 애어른",
    detailResult: "쇠는 신체적으로 점점 허약해지고 의욕이 감퇴하는 점이 특징입니다.\n또한 현재의 안전을 우선시하여 안정적인 시기이기도 합니다. \n외유내강 스타일로 온순하고 담백하나\n방어적이고 보수적인 성향도 있습니다.\n경험이 많아 노련함이 있고, 지혜롭습니다. \n모든 사안에 대해 조화와 조절 능력을 가지고 있습니다. \n성숙하게 모든 것을 해결하고 살아가서 늘 고독합니다.\n평소 조용한 타입이지만 일생에 한 번 큰 사고(소비)를 칠 수 있습니다. \n\n-직업\n기술 분야, 학문, 엔지니어링, 연구, 이공계통 연구계통\n\n-장점\n다정다감하여 사람들과 조화를 잘 이룹니다. \n책임감이 강하고 참을성이 있습니다.\n\n-단점\n적극성과 진취력이 부족하여 리더십이 다소 부족합니다. \n독창성이 부족합니다.",
};

const birthSipYiUnsung7:SajuMyungsikInfo = {
    classTitle: "병",
    classChineseChar: "病",
    classOhang: "",
    classEumyang: "",
    classBearing: "",
    classColor: "",
    classSeason: "",
    description: "내리막을 내려와 마지막을 기다림",
    characteristics: "건강관리, 매력적, 창의",
    detailTitle: "병(病)이란?",
    detailSubTitleMeaning: "병들 病병 병상에 눕게 된 상태, 노년기의 허약한 모습을 의미",
    detailSubTitleCharacteristics: "도화살, 보호본능 자극, 연예인",
    detailResult: "병은 배려심이나 공감능력이 뛰어난 기운이 뛰어난 반면\n육체적인 에너지가 부족합니다. \n그래서 이런 부분은 경쟁력이 떨어질 수 있습니다. \n유년기, 청소년기에 잔병치레가 많을 수 있으니 건강에 유의해야 합니다.\n병의 성향은 온순하여 매사에 평화롭게 대처합니다.\n그래서 결과가 좋지 않을 때에도 남탓을 하지 않습니다. \n도화살과 어떠한 점에서 통하여 사람들에게 관심과 사랑을 받는 경우가 많습니다. \n주변 환경에 관심이 많아 소속감을 중요시 여겨\n세상과 분리되는 것을 두려워합니다.\n배우자와의 인연은 있으나 재혼하는 경우 관계가 더 좋을 수 있습니다. \n\n-직업\n의료, 교육, 법률, 상담사, 봉사관련 업, NGO, 연기자\n\n-장점\n엔터테인먼트적인 창의성이 뛰어납니다. \n노련함과 감각이 있습니다. \n\n-단점\n미래에 대한 알 수 없는 불안감으로 실제 건강상의 문제가 발생하기도 합니다.\n정이 넘치고 마음이 여려 상처 받기도 쉽습니다. ",
};

const birthSipYiUnsung8:SajuMyungsikInfo = {
    classTitle: "사",
    classChineseChar: "死",
    classOhang: "",
    classEumyang: "",
    classBearing: "",
    classColor: "",
    classSeason: "",
    description: "모든것이 멈춰진 상태",
    characteristics: "집중력, 노력, 긍정적 에너지",
    detailTitle: "사(死)란?",
    detailSubTitleMeaning: "죽을 死사 생명을 다함, 활동이 멈춘 정지를 의미",
    detailSubTitleCharacteristics: "물고 뜯는  불독, 무서운 노력파",
    detailResult: "사는 결실을 맺는 기운이 강합니다.\n그래서 모든 일을 시작하면 마무리가 잘 됩니다. \n외유내강형으로 전형적인 노력파입니다.\n마음 먹은 것은 야무지게 끝장을 봅니다. \n그래서 주변 사람들에게 독하다는 평가도 받습니다.  \n집중력이 좋아서 전문적인 분야에서 재능을 펼칠 수 있습니다.\n\n-직업\n의료직, 학문적 연구가, 기술자, 발명가, 조예가\n\n\n-장점\n모든 일에 준비성이 철저합니다. \n\n\n-단점\n성격이 까다로운 편이라 주변인들이 힘들기도 합니다.\n집요한 면이  표출될 수 있습니다. ",
};

const birthSipYiUnsung9:SajuMyungsikInfo = {
    classTitle: "묘",
    classChineseChar: "墓",
    classOhang: "",
    classEumyang: "",
    classBearing: "",
    classColor: "",
    classSeason: "",
    description: "생명이 멈추고 마감하는 단계",
    characteristics: "의심, 임기응변, 호기심, 순발력",
    detailTitle: "묘(墓)란?",
    detailSubTitleMeaning: "무덤 墓묘 죽음을 맞이한 생명, 무덤에 들어가는 단계를 의미",
    detailSubTitleCharacteristics: "저축의 달인, 시작은 미약하나 끝은 창대하리!, 다중이",
    detailResult: "묘의 특징은 저축의 기운이 강하고, \n전형적인 대기만성 유형입니다. \n구두쇠 묘는 알뜰하고 검소하여 경제적입니다. \n재물에 대한 욕망과 욕심도 많습니다. \n묘는 침착하고 안정적으로 보이나\n내면은 늘 온갖 감정이 소용돌이칩니다.\n타인이 자신을 평가하는 것도 굉장히 싫어합니다. \n묘는 자신의 의사가 강한 편이나\n자신의 의지와 상관없이 장남과 결혼하는 경우도 많습니다.\n\n-직업\n공무원, 작가, 금융계통, 전문직\n\n-장점\n모든 일을 철저히 계획하여 소신껏 전진합니다.\n의사표현을 잘 합니다. \n\n-단점\n자신만의 컴플렉스나 열등감이 있습니다.\n이성보다 감정이 앞서 우유부단합니다.",
};

const birthSipYiUnsung10:SajuMyungsikInfo = {
    classTitle: "절",
    classChineseChar: "絶",
    classOhang: "",
    classEumyang: "",
    classBearing: "",
    classColor: "",
    classSeason: "",
    description: "완벽히 기운과 형태가 사라진 상태",
    characteristics: "재물, 열등감, 스트레스",
    detailTitle: "절(絶)이란?",
    detailSubTitleMeaning: "끊을 絶절 무, 완전히 사라지고 없음을 의미합니다.",
    detailSubTitleCharacteristics: "대박 아니면 폭망, 모 아니면 도, 극한 팔자",
    detailResult: "절의 특징은 극단적인 에너지를 가지고 있어서 중간이 없습니다.\n즉, 모 아니면 도! 길흉화복이 굉장히 심합니다.\n그래서 절에는 자수성가와 고립무원의 기운이 함께 존재합니다.  \n그만큼 인생의 변화, 방향이 잘 바뀌는 특성이 있습니다. \n과거를 털어버리고, 새 출발할 수 있는 힘이 있으나\n이직이 잦고, 남의 감언이설에 쉽게 좌지우지 합니다. \n절은 대체로 외모가 훌륭하여 사람들의 관심을 받기도 쉬운 반면   \n갖가지 고초를 겪기도 쉽습니다.\n\n-직업\n철학가, 교육가, 사색가, 연애인, 모델, 패션미용 관련직\n\n-장점\n남에 대한 배려심이 깊고 어려움에 처한 사람을 잘 돌봅니다.\n결단력이 좋고, 위기 순간에 전환을 잘 합니다. \n\n-단점\n불안정한 면이 많아 결혼생활이 평탄하지 않기도 합니다. \n난폭하거나 조급하게 행동하여 구설수에 취약합니다. ",
};

const birthSipYiUnsung11:SajuMyungsikInfo = {
    classTitle: "태",
    classChineseChar: "胎",
    classOhang: "",
    classEumyang: "",
    classBearing: "",
    classColor: "",
    classSeason: "",
    description: "눈에 보이진 않지만 무언가 시작되는 상태",
    characteristics: "이상적, 자유로움, 배려심",
    detailTitle: "태(胎)란?",
    detailSubTitleMeaning: `아이 밸 태 胎, 잉태된 생명\n 모든 가능성을 열어둔 상태라 안 보고도 느끼는 것, 눈에 보이지 않는 것을 믿는 것을 의미`,
    detailSubTitleCharacteristics: "모사꾼, 무한한 가능성을 가진 자유로운 영혼",
    detailResult: "태는 부드럽고 총명합니다. \n그래서  대체로 학업 성적이 우수한 사람이 많습니다.\n주위 환경의 영향을 잘 받지 않습니다.\n이상주의적 성향이 강해서 다소 현실성이 부족합니다. \n자유로운 특성이 있어서 한군데 정착하지 못하고\n변화를 두려워하지 않습니다. \n그래서 조직생활이 어렵기도 한 경우가 많습니다.\n태는 약한 힘을 의미해서 유년기에 특히 허약하기도 합니다. \n이 시기를 잘 보내야 탈이 없습니다. \n\n-직업\n엔터테이너, 작가, 영업직, 예능계 \n\n-장점\n타인을 잘 배려합니다. \n남을 의존하기도 하나 존중하는 마음이 큽니다. \n\n-단점\n경솔하여 말 실수를 잘하고, 험담하기를 좋아합니다.\n불안감이나 피해의식이 많아 남의 눈치를 잘 봅니다.",
};

const birthSipYiUnsung12:SajuMyungsikInfo = {
    classTitle: "양",
    classChineseChar: "養",
    classOhang: "",
    classEumyang: "",
    classBearing: "",
    classColor: "",
    classSeason: "",
    description: "세상에 나오기 전의 아기처럼 평화와 안전한 상태",
    characteristics: "계승, 사교력, 인내심 결여",
    detailTitle: "양(養)이란?",
    detailSubTitleMeaning: `養기를 양, 태아\n 인생 중 가장 완벽한 편안함, 안정과 준비 단계를 의미`,
    detailSubTitleCharacteristics: "팔자 좋은 복덩이, 마마걸, 마마보이",
    detailResult: "양은 침착한 편이고 온순한 성향입니다. \n원만하게 전진하는 힘도 있습니다. \n모든 사람과 잘 어울리고, 인기인이 많기도 합니다. \n부모나 그 윗대로부터 이어받는 경향이 강해서\n재물이나 학업, 능력 등 안정적입니다.\n그래서  여유있게 살아가는 사람들이 많습니다. \n반면 고난이 닥치면 좌절하기도 쉬워 스스로 해결하기 힘들어 합니다.\n여성은 좋은 남편을 만날 가능성이 크나\n양의 뛰어난 사교성이 중년 이후 불륜을 만들 가능성도 높습니다. \n\n-직업\n사업가, 소설가, \n화훼업, 보육원, 축산업, 교육 사업 등 양육에 관련된 직업\n\n-장점\n사교성과 친화력이 뛰어납니다.\n자신감과 여유가 있습니다.\n\n-단점\n의존적이고, 끈기가 없는 편입니다. \n자기 뜻대로 되지 않으면 쉽게 짜증냅니다.",
};

const birthChungan1:SajuMyungsikInfo = {
    classTitle: "갑",
    classChineseChar: "甲",
    classOhang: "목",
    classEumyang: "양",
    classBearing: "동",
    classColor: "청색",
    classSeason: "봄",
    description: "새 생명의 시작, 저항을 뚫고 위로 솟구치려는 기운으로 꿋꿋하고 대장의 기질이 있음",
    characteristics: "성장, 자신감, 리더십, 즉흥, 추진력, 우직, 자부심, 고집",
    detailTitle: "갑(甲)이란?",
    detailSubTitleMeaning: "밭에(田) 뿌린 씨앗이 땅을 뚫고 나오는 형상으로 생명, 시작, 개척을 상징",
    detailSubTitleCharacteristics: "기분파 오지랖, 생기있는 변덕쟁이, 순수한 열정",
    detailResult:
        "갑은 겨우내 얼어 있던 땅이 싹을 틔우기 위해 올라오는 강한 성장의 힘으로 시작과 개척, 상승하고 발전하는 우두머리 기세가 있습니다.\n위로 곧게 올라가는 성질이 강하여 진취적인 기질과 이상이 높고 명예를 중요시 합니다.\n매사에 추진력과 리더십을 잘 발휘하여 새로운 일을 탁월하게 진행하나 마무리는 약합니다.\n지나치게 자신을 내세우는 허세, 자만이 있고 어디든 나서기를 좋아하는 기질로\n남들과의 경쟁에서는 공격적인 성향이라 적을 만들기도 쉽습니다.\n여자는 활동력과 생활력이 강한 편이라, 사회생활을 활발히 하곤 합니다. \n\n-직업\n경찰, 강사, 상담가, 사업(자수성가형), 변호사, ngo\n\n-장점\n상대에 대한 배려와 인정이 있고, 특히 약자(왕따)를 잘 보살핍니다.\n언제나 진취적이라 극한 상황에서도 당당한 매력이 있습니다. \n\n-단점\n주체성이 강하여 간섭 구속을 굉장히 싫어하고, 남의 말을 잘 듣지 않습니다.\n즉흥적이며, 돌발행동을 서슴지 않아 치기 어린 객기를 부리기도 합니다. ",
};

const birthChungan2:SajuMyungsikInfo = {
    classTitle: "을",
    classChineseChar: "乙",
    classOhang: "목",
    classEumyang: "음",
    classBearing: "동",
    classColor: "청색",
    classSeason: "봄",
    description: "치솟은 힘을 옆으로 펴뜨리는 힘, 기운을 퍼뜨리며 더불어 살아감. 협동심과 포용력 발휘",
    characteristics: "적응력, 포용력, 협동력, 판단력, 계산적, 감정기복",
    detailTitle: "을(乙)이란?",
    detailSubTitleMeaning: "덩굴식물과 같이 끈질긴 생명력으로 옆으로 완연하게 퍼지는 힘을 의미",
    detailSubTitleCharacteristics: "상사의 왼팔, 굽신 굽신, 짠돌이",
    detailResult:
        "을은 옆으로 퍼지는 힘으로 갑과 달리 뚫고 지나가려는것이 아닌 유연하게 장애물을 타고 넘는 것이 특징입니다. 온유한 타입으로 배려심있고, 섬세하며 명랑합니다.\n항상 다른 사람들과 더불어 어울리기를 좋아하는 타입입니다.\n환경 적응력과 순발력, 판단력이 뛰어나 어떠한 난관에도 굴하지 않습니다.\n오히려 장애물과 하나되어 그 장애물을 타고 넘습니다.  \n상대에게 완벽하게 굽혀 처음에는 굴복한 듯 보이나 결국 최후의 일인자로 남습니다.\n존재감은 미미하지만 계산적이며 이해타산을 치밀하게 잘 따져 실속을 잘 챙깁니다.\n그러나 실리를 챙기는 본성으로 타인으로부터 고립되는 경우도 많습니다.\n\n-직업\n사람을 상대하는 직업, 교육, 언론, 문예, 영업직, 서비스업, 비서\n\n-장점\n유연한 자세가 탁월하여 상황과 주변 환경을 잘 이용합니다.\n섬세한 리더십으로 기획력, 추진력도 있습니다.\n\n-단점\n자립심이 부족하여 다른 사람에게 의지하거나 이용하려는 경향이 강합니다.\n감정표현이 서툴러 스트레스가 많고, 감정 기복이 심하기도 합니다. ",
};

const birthChungan3:SajuMyungsikInfo = {
    classTitle: "병",
    classChineseChar: "丙",
    classOhang: "화",
    classEumyang: "양",
    classBearing: "남",
    classColor: "적색",
    classSeason: "여름",
    description: "양중의 양의 기질, 발산하여 멀리 비추는 태양과 유사, 강한 에너지 보유",
    characteristics: "당당, 예의, 무모, 좌충우돌, 도전정신",
    detailTitle: "병(丙)이란?",
    detailSubTitleMeaning: "온 세상을 비추는 빛, 열과 같이 만물을 감싸 정열적이고 공평함을 의미",
    detailSubTitleCharacteristics: "불꽃 생명수, 다이나믹 좌충우돌, 시끌벅적, 에너자이저\n",
    detailResult: "병은 기본적으로 활달하며 매사 정열적이며 자신감, 리더십이 넘쳐 거침이 없습니다.\n공평하고 사람들에게 평등하기 때문에 남을 속이려거나 사기를 치는 일이 없는 편입니다. \n새로운 것과 화려함을 좋아해서 외모에 관심이 많으며 유행을 잘 따라가는 편이며 세상을 바꾸려는 에너지도 갖고 있기 때문에 용기가 있으며 직설적으로 자신의 이야기를 잘 합니다. 이에 사건사고가 많고, 좌충우돌 인생을 사는 사람도 많습니다.\n\n급한 성격 탓에 실수가 잦고 싫증을 빨리 내는 편입니다. 겉보기와 다르게 예민한 편이다보니 감정기복이 심하며 속으로 끙끙 앓으며 스트레스를 받는 경우도 많습니다.\n\n-직업\n사업가, 여행가, 여행가이드, 탐험모험가, 정치가, 언론\n\n-장점\n사교적이고 친화적이라 대인관계가 좋습니다.\n인사성과 예의가 아주 바르고 질서와 규율을 잘 지킵니다.\n\n-단점\n도전정신이 강해 일단 저지르고, 실수해도 반성하지 않아 실수가 반복됩니다. \n불같이 급한 성격 때문에 불꽃처럼 빠르게 타오르고 빠르게 식어 싫증과 포기가 빠릅니다. \n집착과 소유욕, 말이 너무 많아 구설수에 휘말리거나 타인을 괴롭히는 성향입니다.",
};

const birthChungan4:SajuMyungsikInfo = {
    classTitle: "정",
    classChineseChar: "丁",
    classOhang: "화",
    classEumyang: "음",
    classBearing: "남",
    classColor: "적색",
    classSeason: "여름",
    description: "뜨거운 열을 바탕으로 생명력을 부여. 온화하고 생명력을 불어넣음",
    characteristics: "친절, 따뜻, 쾌활, 배려, 과잉반응, 폭발",
    detailTitle: "정(丁)이란?",
    detailSubTitleMeaning: "모든것이 성숙하며 늠름한 모습으로 성장을 의미 ",
    detailSubTitleCharacteristics: "외유내강, 탐욕스런 2인자",
    detailResult:
        "정은 겉보기엔 조용하고 밝고 따뜻하고 부드러운 분위기를 보입니다. 그러나 겉보기완 다르게 내면적으로는 누구보다 정신력이 뛰어납니다.\n대인관계에서 남을 잘 배려하고, 겸손하며 예의가 바른 타입으로 많은 사람들이 잘 따르고 좋아하는 편입니다. \n위계적이고 의존적인 권력을 좋아하여 본인이 앞으로 나서지 않고,\n정교하고 구체적으로 대리자 역할을 잘 수행합니다. \n정은 지나친 사색에 빠져 상상력이 풍부하고, 주로 밤에 활동을 많이 하는 '야행성'이 많습니다. \n\n-직업\n관리자, 정치인, 교수, 수사관, 과학자, 전자공학, 검찰, 수학, 컴퓨터, 전자정보\n\n-장점\n헌신적이고 봉사정신이 투철합니다.\n주변 환경과 조화를 잘 이루어 격식을 잘 갖춥니다. \n\n-단점\n순간순간 감정의 기복이 다소 심합니다. \n내면에는 자기 중심성과 다혈질적인 면이 강하여 사고를 치기도 합니다.",
};

const birthChungan5:SajuMyungsikInfo = {
    classTitle: "무",
    classChineseChar: "戊",
    classOhang: "토",
    classEumyang: "양",
    classBearing: "중앙",
    classColor: "황색",
    classSeason: "",
    description: "양과 음의 전환점으로 제어, 관리 탁월",
    characteristics: "고집, 소유욕, 고지식, 신뢰, 남성적",
    detailTitle: "무(戊)란?",
    detailSubTitleMeaning: "모든것이 아름답고 무성하게 자란다는 것을 의미",
    detailSubTitleCharacteristics: "고집의 끝판왕, '아니면 말고!' 식의 마인드",
    detailResult: "무는 끝없이 넓은 땅처럼 믿음직스럽고, 언행이 신중한 편입니다. \n겉으로 보기에 무뚝뚝해 인정이 없거나 멋이 없게 보이지만 매사에 생각이 깊고 모든 사람들을 아우르는 포스가 있습니다.\n배포와 스케일이 커서 자만심이나 자기 과시, 허세와 허풍이 있는 편입니다. \n특유의 포용력으로 친화력이 풍부하여 항상 주위에 사람들이 많고 잘 지냅니다. 분쟁이 났을 때 중간 역할을 담당하거나 화해시키기도 합니다. \n고집이 그 누구보다 강하기 때문에 아무도 꺾을 수 없으며 한번 밀고 나가기 시작하면 끝을 보는 타입이다 보니 무토의 기운이 강한 사람의 생각을 바꾸기란 여간 쉽지 않습니다.\n\n\n-직업\n무역, 해외 영업직, 종교인, 수사기관, 주택관리인, 농장경영, 토목건축업, 행정관료 \n\n-장점\n매사 성실하고 책임감이 강하며, 실천력이 뛰어나 한다면 무엇이든 합니다. \n모든 사람을 포용하고 중화와 중용을 지키려합니다.\n\n-단점\n고집과 독선이 강하고, 다른 사람의 말을 무시하는 경향이 있습니다. \n이상과 현실의 차이로 스스로 갈등을 느끼고 인연을 멀리하기도 합니다. ",
};

const birthChungan6:SajuMyungsikInfo = {
    classTitle: "기",
    classChineseChar: "己",
    classOhang: "토",
    classEumyang: "음",
    classBearing: "중앙",
    classColor: "황색",
    classSeason: "",
    description: "비옥하고 알찬 땅, 본격적인 음의 기운의 시작",
    characteristics: "설득력, 자기관리, 계획적, 자립심",
    detailTitle: "기(己)란?",
    detailSubTitleMeaning: "완벽한 성숙을 의미하는 동시에 음의 작용의 시작을 의미",
    detailSubTitleCharacteristics: "이불 밖은 위험해 타입, 은근히 이기적",
    detailResult: "기는 밖으로는 완연한 에너지를 갖추지만 자기 표현을 억제하는 특징이 있습니다.\n언행이 일치하고 신중하게 처신하며, 자기 일에 충실한 스타일입니다.\n자기 관리를 완벽하게 잘 해서 안정적이고 계획적으로 살아가며,\n가족과 본인이 마음을 허락한 주변인을 잘 챙기는 타입입니다.\n겉으로 냉철해 보이지만 속은 굉장히 여린 편이고,\n자신이 갖춘 실력만큼 담대하지 못하고 자신감이 부족합니다.\n순수한 것 같으면서도 자기 실속을 챙기는 모습과 평소 의심이 많고 자신을 남들에게 보이지 않으려 하기 때문에 오해를 받기도 합니다.\n\n\n-직업\n상담사, 종교인, 교육 관련 일, 교사, \n\n-장점\n순박하고 부드러워 어머니와 같은 자애로움과 포용력이 있습니다. \n다른 사람의 말을 잘 들어주고 감정을 잘 헤아립니다.\n\n-단점\n의존성이 깊고, 다양한 상황에 대한 대처 능력이 떨어집니다.\n매사 의심이 많고 신경이 예민하며 까다로운 면이 있습니다.\n좀처럼 자기 속마음을 털어놓지 않아 스트레스가 상당히 많습니다. ",
};

const birthChungan7:SajuMyungsikInfo = {
    classTitle: "경",
    classChineseChar: "庚",
    classOhang: "금",
    classEumyang: "양",
    classBearing: "서",
    classColor: "백색",
    classSeason: "가을",
    description: "견고하게 열매를 맺음. 결실, 수렴 등 가을의 기운",
    characteristics: "원리원칙, 정돈, 질서, 꼼꼼, 신념, 추진력",
    detailTitle: "경(庚)이란?",
    detailSubTitleMeaning: "큰 바위, 큰 쇠와 같이 만물의 기운이 열매를 맺는다는 것을 의미",
    detailSubTitleCharacteristics: "까탈스러움이 매력, 지고지순 의리파",
    detailResult: "경은 공과 사를 분명히 가리는 스타일로 질서와 원리원칙을 잘 지킵니다.\n한번 결정한 일은 끝까지 강하게 밀어붙이는 등 결단력과 자기 확신, 신념이 강합니다.\n자신의 결정을 번복하거나 수정하는 일이 없어 카리스마 넘치기도 합니다. \n때론, 남들에게 오만하고 융통성이 부족한 사람으로 판단됩니다.\n그래서 적이 생기거나 스스로 재앙을 불러들이는 경향도 있습니다. \n그러나 동료애나 소속감이 남달리 강한 편이라 충성심도 강하고,\n강자에게는 대항하고 약자는 도와주는 희생정신이 강한 면도 있습니다. \n자신이 믿는 사람에게는 끝까지 깊은 의리를 지킵니다. \n\n\n-직업\n사업 공무원, 군인, 경찰, 검찰, 법관, 철강, 공업계열 \n\n-장점\n자신 주변 정돈을 잘 합니다. \n지도력과 통솔력이 뛰어납니다. \n\n-단점\n성격이 조급하고, 결과에 대해 집중하여 난폭한 경향이 있습니다.\n명예욕이 강해 자기 과시적이고 독선적인 면이 많습니다. ",
};

const birthChungan8:SajuMyungsikInfo = {
    classTitle: "신",
    classChineseChar: "辛",
    classOhang: "금",
    classEumyang: "음",
    classBearing: "서",
    classColor: "백색",
    classSeason: "가을",
    description: "세공된 보석처럼 꼼꼼하고 섬세함, 강하고 예리한 음의 기운 ",
    characteristics: "예리, 꼼꼼, 섬세, 계획적, 논리적, 비판적",
    detailTitle: "신(辛)이란?",
    detailSubTitleMeaning: "작은 금속, 칼, 보석 등으로 열매를 떨어뜨리는 기운을 의미",
    detailSubTitleCharacteristics: "잔소리  달인, 바늘이 호주머니에 있는 격",
    detailResult: "신은 소소한 계획이 많고 그것대로 매일 지켜야 직성이 풀리는 성향입니다.  \n다소 소극적이지만 매사 정확하면서도 총명하고 논리정연합니다.\n또 섬세하고 꼼꼼하고 주체성이 강하고, 자기 주장이 확실한 편입니다. \n언변도 좋아 누구도 따라올 자가 없습니다. \n이런 기질로 사회생활에서 신뢰를 잘 형성하기도 하고,\n반면에 예민하고 신경이 날카로워 잔소리가 많아 본인, 주변 모두 힘들기도 합니다. \n신금은 날카로운 기운이 강해 체력적인 문제에 시달리고, 특히 정신건강에 유의해야 합니다.  \n이성에게 유혹을 가장 많이 받으며 화려하고 사치스러운 것을 좋아합니다.\n\n\n-직업\n정교한 작업을 하는 업, 엔지니어링, 패션, 의사, 조각가, 간호사, 약사, 역술가, 상담사, 요리사\n\n-장점\n정확한 기획능력이나 계산능력이 뛰어납니다.\n항상 새로운 것을 추구하며, 외모에 신경을 써 멋을 아는 장점이 있습니다. \n\n-단점\n욕심이 많고, 자존심이 강해 자아도취에 빠져 살기 쉽습니다.\n남들이 두려워할 정도의 냉혹함과 칼로 도려내는 듯한 야유와 독설이 강합니다.",
};

const birthChungan9:SajuMyungsikInfo = {
    classTitle: "임",
    classChineseChar: "壬",
    classOhang: "수",
    classEumyang: "양",
    classBearing: "북",
    classColor: "검정색",
    classSeason: "겨울",
    description: "큰 바다, 고여있는 큰 물처럼 속이 보이지 않는 깊은 물과 같음 ",
    characteristics: "두뇌회전, 포용, 통찰력, 독창적",
    detailTitle: "임(壬)이란?",
    detailSubTitleMeaning: "큰 강, 바다, 댐과 같이 모든것을 수용하는 것을 의미",
    detailSubTitleCharacteristics: "도화 그 자체, 나를 따르라!, 사기꾼 기질 다분",
    detailResult: "임은 대범한 태도로 무엇이든 수용하여 어느 곳에서나 잘 어울리고, \n부드러운 리더십으로 사람들을 잘 이끌어 인기가 많은 것이 특징입니다.\n임수는 기본적으로 두뇌 회전이 빨라 총명하고 일 처리도 빠릅니다.\n반면 자기 뜻대로 하려는 기질이 강해 자신을 통제하는 것을 참지 못합니다. \n\n깊은 바닷속을 제대로 알 수 없듯이 속마음을 남들에게 잘 내비치지 않아 \n음흉하거나 비밀이 많다는 오해를 받기 쉽습니다.\n속을 알 수 없으니 권모술수를 꾸미거나 사기꾼 기질이 많습니다.  \n\n\n-직업\n정치인, 연예인, 방송인, \n\n-장점\n예지력과 상상력, 통찰력이 뛰어납니다. \n담는 그릇에 따라 물 모양이 바뀌듯이 재치가 있고 임기응변에 능합니다. \n\n-단점\n인내심이 약하고 변덕이 심해 신뢰감이 떨어집니다. \n유흥이나 유행에 치우쳐 방탕하는 경우도 많습니다.",
};

const birthChungan10:SajuMyungsikInfo = {
    classTitle: "계",
    classChineseChar: "癸",
    classOhang: "수",
    classEumyang: "음",
    classBearing: "북",
    classColor: "검정색",
    classSeason: "겨울",
    description: "음의 끝과 양의 시작이 만나는 지점. 시냇물, 이슬비와 같이 천지에 스며드는 유연함",
    characteristics: "적응력, 여린감성, 지혜, 의심, 여성적",
    detailTitle: "계(癸)란?",
    detailSubTitleMeaning: " 생명체가 분별되듯 음의 기운이 소멸과 새로운 양의 기운이 생성을 의미 ",
    detailSubTitleCharacteristics: "잔머리 왕, 속을 알 수 없는 사람",
    detailResult: "계는 여리고 약한 물이지만 천지에 스며들 수 있을 만큼 유연한 것이 계입니다. \n그만큼 온화하고 감수성이 풍부하고, 여린 심성을 가진 사람이 많습니다. \n계수는 지모가 뛰어나고 조용히 노력하며 아이디어가 특출나지만 나서지 않고 자신의 존재를 잘 드러내지 않으며, 소수와의 관계를 선호하는 편입니다.\n통찰력과 예리함으로 남의 심리를 잘 파악하고, 마음 씀씀이도 자상합니다.\n한편으로 비밀이 많아 보이거나 신비스럽게 보여 오해를 일으킬 소지가 다분합니다. \n\n\n-직업\n작가, 철학, 문학, 종교, 예술, 영업, 마케팅, 과학자, it기술, 교육\n\n-장점\n상황 판단력이 뛰어나 장애물을 만나도 유연하게 대처합니다.\n자신의 역량이나 재능을 집중시켜 잘 발휘합니다.  \n\n-단점\n줏대가 없고, 우유부단하여 상대의 무리한 부탁도 거절하지 못합니다. \n두뇌 회전이 빠르고 기획에 탁월하지만 많이 아는 것에 비해 의지와 실천이 부족합니다. \n의심이 많고, 다소 우울한 감정이 있어서 조심해야 합니다.",
};

const birthJiji1:SajuMyungsikInfo = {
    classTitle: "자",
    classChineseChar: "子",
    classOhang: "수",
    classEumyang: "음",
    classBearing: "",
    classColor: "검정색",
    classSeason: "겨울",
    description: "가장 어둡고 음의 기운이 강하지만 양의 기운의 시작",
    characteristics: "유연함, 유동성, 총명, 감수성, 아이디어, 은밀함, 실용적",
    detailTitle: "자수(子水)란?",
    detailSubTitleMeaning: "지지 중 첫번째 지지인 쥐에 해당한다",
    detailSubTitleCharacteristics: "음욕, 잔머리 1인자",
    detailResult:
        "자수는 양의 기운이 처음 시작되는 것처럼 잠재된 욕망과 끼가 많습니다. 그 재주를 조용하게 도모하는 성향이 있습니다.\n유연함과 유동성도 가지고 있어서 어디나 잘 섞이기도 합니다.\n\n자수 특유의 생명력 있는 에너지는 어두운 현실을 긍정적으로 바꿀 수 있습니다. 그러나 이 에너지는 자칫 일탈의 원동력이 될 수도 있어 경계하는 자세가 필요합니다.\n\n또, 번식력을 의미하는 쥐는 생명력뿐 아니라 성적인 욕망도 상징합니다. 타고난 성적 매력, 도화살로 남들에게 주목받기 쉽습니다.\n\n\n-직업\n의술, 약사, 간호사, 상담가, 역술가 등 생명과 관련된 분야\n\n-장점\n지혜가 있으며 아이디어가 뛰어납니다.\n소박하고 실용적이라 낭비를 하지 않습니다. \n\n-단점\n강한 음욕으로 성적인 문제나 애정사에 시달릴 수 있고, 연애 트러블 메이커가 될 수 있습니다.\n예민한 감수성과 우울함을 가지고 있습니다. ",
};

const birthJiji2:SajuMyungsikInfo = {
    classTitle: "축",
    classChineseChar: "丑",
    classOhang: "토",
    classEumyang: "음",
    classBearing: "",
    classColor: "황색",
    classSeason: "겨울",
    description: "새로운 생명이 땅을 나올 준비를 마침",
    characteristics: "우직함, 성실, 협력, 희생, 고집, 외로움",
    detailTitle: "축토(丑土)란?",
    detailSubTitleMeaning: "지지 중 두번째 지지인 소에 해당한다",
    detailSubTitleCharacteristics: "우직함 속 고집 대장, 타고난 일복",
    detailResult: "축토는 소처럼 우직하고 근면성실한 성향을 보입니다.\n이런 축토의 영향으로 다른 사람들보다 일복이 많은 편입니다.\n자기가 하고 싶은 일을 할 땐 누구보다 열정을 쏟습니다.\n축월에 태어난 경우 감각적이고 아이디어, 기획력이 뛰어납니다.\n\n다만, 얌전한 소가 위험하듯 평상시에 참고 있던 기운이 폭발하면 아무도 못 말릴 정도로 고집이 강해지기도 합니다. 축토인 사람들은 몸의 기운이 찬 경우가 많아 몸을 따뜻하게 하여 조화를 이루는 것도 좋습니다.\n\n-직업\n봉사자, 목회자, 프리랜서, 사업가, 기획 작가, 마케팅, \n\n-장점\n끈기와 인내심이 강하여 중간에 포기하지 않고 끈질기게 밀고 나갑니다.\n자신이 손해를 보더라도 남을 적극적으로 돕고, 공동체를 위해 봉사하고 희생합니다.\n\n-단점\n감정 표현이 서툴러 감정 표현 타이밍을 종종 놓칩니다.\n소의 고집으로 남들이 시키면 절대 하지 않고, 스스로가 인정할 명분 안에서 움직입니다. ",
};

const birthJiji3:SajuMyungsikInfo = {
    classTitle: "인",
    classChineseChar: "寅",
    classOhang: "목",
    classEumyang: "양",
    classBearing: "",
    classColor: "청색",
    classSeason: "봄",
    description: "겨울이 끝나고 봄이 오는것을 알리는 본격적인 양의 시작",
    characteristics: "자존심, 권력욕, 도전정신, 야망, 예측불허, 이기적, 염세적",
    detailTitle: "인목(寅木)이란?",
    detailSubTitleMeaning: "지지 중 세번째 지지인 호랑이에 해당한다",
    detailSubTitleCharacteristics: "우직함 속 고집 대장, 타고난 일복",
    detailResult: "인목은 봄이 되면서 씨앗이 땅 위로 솟구쳐 오르는 맹렬한 기운을 의미하듯 용맹한 호랑이에 빗댈 수 있습니다.\n\n인목은 12지지중 가장 주관이 뚜렷하고 자존심이 강합니다.\n그러다보니 돈보다는 명예욕과 권력욕이 넘치기도 합니다.\n권력 지향적인 성향은 승진이나 큰 성취를 이루는 원동력이 되지만 때론 여러 사람들과 다양한 트러블이나 사고에 노출되어 있기도 합니다.\n\n인목의 영혼은 독립적이며 자유롭습니다.\n혼자 있는 것을 좋아해 고독한 매력이 돋보입니다.\n몸과 마음이 따로 놀듯, 좌충우돌, 예측불허한 욕망이 가득합니다. \n\n-직업\n모험가, 여행가, 프리한 직업, 사업가, 교육, 기획, 전기, 항공, 통신업\n\n-장점\n새로운 일을 시작하는 용기와 역동적인 힘, 추진력이 있습니다. \n장애물을 만나도 강하게 돌파해내고, 새로운 환경을 찾는 저력이 있습니다.\n\n-단점\n큰 한 방에 승부를 걸고 한꺼번에 몰아서 일을 합니다.\n꿈이 커서 현실적 좌절감, 결핍감도 큽니다. ",
};

const birthJiji4:SajuMyungsikInfo = {
    classTitle: "묘",
    classChineseChar: "卯",
    classOhang: "목",
    classEumyang: "음",
    classBearing: "",
    classColor: "청색",
    classSeason: "봄",
    description: "해가 뜨고 만물이 나타나는 시기",
    characteristics: "배려, 인정, 창의력, 섬세, 예민, 손재주",
    detailTitle: "묘목(卯木)이란?",
    detailSubTitleMeaning: "지지 중 네번째 지지인 토끼에 해당한다",
    detailSubTitleCharacteristics: "누구에게나 잘 맞춰줌, 섬세함의 끝",
    detailResult:
        "묘목은 토끼의 외향적인 모습처럼 대체로 온순하며 인정이 많고 타인에 대한 배려심도 깊은 성숙한 인격을 보이는 편입니다. \n묘목은 늘 사람과의 관계를 고려한 현명하고 이상적인 판단을 하고,\n무엇이든 사람들과 나누기를 좋아하고 사회적으로 활발한 편입니다.\n생동감 넘치는 에너지가 사방으로 뻗치는 기운을 가지고 있습니다.\n이런 욕망과 재능을 마음껏 밖으로 표출하는 묘목은 개성이 뚜렷합니다. \n\n\n-직업\n치기공사, 섬세한 패션, 디자인, 인테리어, 조경, 종이나 옷감을 다루는 정교한 작업\n\n-장점\n창의력도 뛰어나고 섬세하여 창조적인 일에 소질을 발휘합니다.\n학문을 좋아하나 명예나 품위에 연연하지 않고, 순수한 성향입니다.\n\n-단점\n에너지가 분산돼서 다양하게 쓰일수록 내면이 공허해지기도 합니다.\n사소한 일에 집착하여 남을 깎아내리는 경우가 많습니다. ",
};

const birthJiji5:SajuMyungsikInfo = {
    classTitle: "진",
    classChineseChar: "辰",
    classOhang: "토",
    classEumyang: "양",
    classBearing: "",
    classColor: "황색",
    classSeason: "봄",
    description: "양과 음의 조화, 모든 만물이 활발하게 움직임",
    characteristics: "권력욕, 이상적, 비현실적",
    detailTitle: "진토(辰土)란?",
    detailSubTitleMeaning: "지지 중 다섯번째 지지인 용에 해당한다",
    detailSubTitleCharacteristics: "충동적인 성향이 짙음, 속을 알 수 없음",
    detailResult: "진토는 권력과 왕을 의미하는 상징이기도 합니다. 그러다 보니 \n기본적으로 권력욕과 지배욕이 강하며 자신의 명예와 체면을 중시하는 성향이 강합니다.\n이에 겉은 화려할 수 있지만 속은 어두운 모습을 갖고 살아가기도 합니다. \n\n현실보다는 이상적이고 미래와 비전에 관심이 많은 편입니다.\n그로 인해 현실적으로 어려운 것을 도전하고 이루는 것을 즐기는 편이기도 합니다. \n그만큼 여러 일에 다방면으로 다재다능한 모습을 보입니다. \n\n현실보다는 이상적인 부분을 꿈꾸다보니 허세를 잘 부리는 편이며 계획은 그럴싸하지만 정작 내실은 없는 모습도 자주 보입니다.\n\n-직업\n마케팅, 기획자, 인테리어, 특허출원\n\n-장점\n난관에 부딪혔을 때 과감한 결정을 내려 위기를 기회로 삼아 능력 발휘를 합니다. \n인내심과 끈기가 강하여 목표한바 결실을 거둘 수 있는 잠재력을 가지고 있습니다. \n\n-단점\n현실 감각이 부족하고, 허세와 망상이 있습니다.\n완벽을 추구하지만, 고집이 세서 타협하지 않습니다.",
};

const birthJiji6:SajuMyungsikInfo = {
    classTitle: "사",
    classChineseChar: "巳",
    classOhang: "화",
    classEumyang: "양",
    classBearing: "",
    classColor: "적색",
    classSeason: "여름",
    description: "양중의 양으로 태양과 유사",
    characteristics: "유연함, 감정적, 책임감, 용의주도, 약한 끈기",
    detailTitle: "사화(巳火)란?",
    detailSubTitleMeaning: "지지 중 여섯번째 지지인 뱀에 해당한다",
    detailSubTitleCharacteristics: "오지랖의 끝판왕, 욕심쟁이",
    detailResult:
        "사화는 어떠한 상황이 닥치더라도 헤쳐나갈 수 있는 유연함과 명석함이 특징입니다. 눈치 빠르고 명석한 두뇌는 사회생활을 함에 있어 집단의 분위기를 파악해 자신의 것으로 바꾸며 이끌어나가는 프로페셔널한 모습을 보여줍니다.\n\n하지만 때론 급한 성격과 에너지로 자기 자신을 억제하지 못해 그동안 쌓아둔 명성을 스스로 깎아 먹을 수 있습니다. 사화의 특징이 두드러진 사람은 자신의 강점이 약점으로 변질되지 않도록 감정 컨트롤을 잘 하는 것이 필요합니다.\n\n사람을 끌어당기는 매력이 있어 사교성도 뛰어나고 주변에서 인기도 많습니다. 자신이 매력이 있다는 것을 잘 아는 만큼 다소 자기중심적인 경향도 있어 주변의 시기를 받을 수 있으니 인맥 관리를 위해선 한 발짝 양보하는 태도도 필요합니다.\n\n-직업\n활동적인 업, 항공, 승무원, 통신, 자동차, 전기·전자, 행정직, 경찰, 군인\n\n-장점\n특유의 권력의지와 책임감이 강하여 출세나 승진을 합니다.\n사람을 끌어당기는 묘한 매력이 있습니다.\n\n-단점\n성질이 급하고 양기가 넘쳐 폭발적인 에너지로 분노 조절이 잘 안됩니다.\n개성은 강하지만 끈기가 약합니다.",
};

const birthJiji7:SajuMyungsikInfo = {
    classTitle: "오",
    classChineseChar: "午",
    classOhang: "화",
    classEumyang: "음",
    classBearing: "",
    classColor: "적색",
    classSeason: "여름",
    description: "사방을 질주하는 말처럼 활동성과 역동성 발휘",
    characteristics: "실행력, 독립적, 감수성, 미적감각",
    detailTitle: "오화(午火)란?",
    detailSubTitleMeaning: "지지 중 일곱번째 지지인 말에 해당한다",
    detailSubTitleCharacteristics: "가만히 있지를 못하는 성격, 바람에도 슬퍼하는 감수성",
    detailResult:
        "오화는 넓은 초원을 달리는 말처럼 활동성이 강하며 도전을 두려워하지 않습니다. 넓은 인간관계는 물론 시대의 트랜드에 따라 변화하며 모험을 두려워하지 않는 것이 특징입니다. 그만큼 역마성도 강하다고 볼 수 있습니다.\n\n오화는 틀에 박힌 것을 싫어하며 독립적이고 자유롭습니다. 이에 조직 생활을 잘 견디지 못하는 경우가 많아 사업이나 장사를 하는 경우도 많으며 연애에 있어 어도 자유로운 영혼을 꿈꿔 이별, 이혼 등의 이별 수가 강한 편입니다.\n\n또 오화의 기운이 강한 사람은 대체로 미적 감각이나 예술적 감각이 뛰어납니다. 이에 디자인이나 예체능 계열에 있는 경우가 많으며 다른 기운들에 비해 외모도 뛰어난 경우가 많습니다.\n\n-직업\n통신, 여행, 가이드, 예술, 연예인, 패션, 교육계, 언론계\n\n-장점\n감수성이 풍부하고 미적 감각이 발달하여 예술적이며 개성이 뚜렷합니다.\n낯선 사람과 잘 어울려 폭넓은 대인관계를 맺습니다.\n\n-단점\n유혹에 약해 작은 유혹에도 쉽게 정신을 뺏깁니다.\n독립적인 성향이 강해 비판, 반대, 혁명의 불씨가 됩니다.",
};

const birthJiji8:SajuMyungsikInfo = {
    classTitle: "미",
    classChineseChar: "未",
    classOhang: "토",
    classEumyang: "음",
    classBearing: "",
    classColor: "황색",
    classSeason: "여름",
    description: "뜨겁고 메마른 땅, 참을성과 강한 끈기",
    characteristics: "자존심, 고집, 희생, 의리",
    detailTitle: "미토(未土)란?",
    detailSubTitleMeaning: "지지 중 여덟번째 지지인 양에 해당한다",
    detailSubTitleCharacteristics: "순한듯 까칠함, 끈기와 희생정신",
    detailResult: "미토는 외양의 밝은 면과 내면의 어두운 면, 양면을 모두 가지고 있는 것이 특징입니다.\n남들 앞에선 밝아 보이지만 혼자 있을땐 고독하며 예민합니다. 온순한 듯 보이지만 성격은 날카롭고 사나운 모습도 있는 등 양면성을 갖고 있습니다.\n\n미토는 인내심과 희생정신이 뛰어납니다. 자기 사람에 한해서는 자신이 갖고 있는 것을 나눌 줄 알며 조금 손해를 보더라도 상대방을 위해서 배려할 줄 아는 모습에 주변으로부터 평가도 좋고 대인관계가 뛰어난 편입니다. \n\n인내심과 배려 모두를 갖추고 있지만 이러한 성격으로 인해 틀에 박혀 유동적이지 못하거나 고집이 강한 편입니다. 이러한 성향으로 평소 스트레스를 많이 받는 편이며 스트레스 역시 풀리지 않아 예민한 편입니다. \n\n-직업\n부동산 중개업, 상담사, 공무원, 사회복지사 \n\n-장점\n자신이 속한 조직이나 가족, 친구, 자식에게 희생정신이 강합니다. \n\n-단점\n자존심이 강하고 고집이 강한 편입니다. \n섬세하고 예민한데 스스로 감당하지 못해 신경성 두통에 시달리기도 합니다. ",
};

const birthJiji9:SajuMyungsikInfo = {
    classTitle: "신",
    classChineseChar: "申",
    classOhang: "금",
    classEumyang: "양",
    classBearing: "",
    classColor: "백색",
    classSeason: "가을",
    description: "원숭이와 같이 영리하고 특별한 재능, 기술",
    characteristics: "과감, 실속, 산만, 다재다능",
    detailTitle: "신금(申金)이란?",
    detailSubTitleMeaning: "지지 중 아홉번째 지지인 원숭이에 해당한다",
    detailSubTitleCharacteristics: "엄청난 야망가, 실리주의 최고",
    detailResult:
        "신금은 대체로 영리하고 다재다능하며 미적 감각이 뛰어난 것이 특징입니다. 명석한 두뇌를 바탕으로 결단력도 빠르며 자신에게 이득이 되는 실리적인 모습에서도 뛰어납니다.\n자신의 재주와 다재다능함을 알기에 호기롭게 일은 벌여놓지만 정작 마무리하지 못하고 흐지부지되는 경우가 많습니다. \n\n겉으로는 밝고 웃음도 많으며 긍정적인 것처럼 보이지만 금의 성향이 있기 때문에 우울한 성향도 갖고 있으며 주변 사람들의 평가에 예민하고 의기소침해하는 경우도 많습니다. \n\n또, 사회생활에 잘 적응하며 임기응변도 뛰어난 편이지만 기운 자체가 혼자 있는것을 선호하는 탓에 적극적으로 나서고 즐거워하지는 않는 편입니다.  \n\n-직업\n정치가, 화가, 조각가, 전문적인 일\n\n-장점\n강한 결단력과 총명함으로 실리를 추구합니다. \n조직에 잘 적응하고 임기응변 능력이 뛰어납니다. \n\n-단점\n시야가 좁은 우물 안 개구리로 잘난 척 큰소리치는 사람이 많습니다.\n독단적이며 우월감에 사로잡혀 있기도 합니다.\n산만하고 변덕스러우며 시작만큼 마무리를 잘 못합니다.",
};

const birthJiji10:SajuMyungsikInfo = {
    classTitle: "유",
    classChineseChar: "酉",
    classOhang: "금",
    classEumyang: "음",
    classBearing: "",
    classColor: "백색",
    classSeason: "가을",
    description: "날카로운 양면을 지님, 날카롭고 확실함",
    characteristics: "원칙, 소신, 섬세함, 감정적, 결단력, 현실안주",
    detailTitle: "유금(酉金)이란?",
    detailSubTitleMeaning: "지지 중 열번째 지지인 닭에 해당한다",
    detailSubTitleCharacteristics: "현상유지, 빈틈없는 자기관리",
    detailResult: "유금은 금의 기운을 많이 받는 만큼 세심하고 철두철미하지만 날카롭고 예민한 것이 특징입니다.\n섬세하고 계획적이며 남들에게 모범적이고 뛰어난 성품을 보일 만큼 자신에 대해서도 날카롭지만, 상대에게도 날카로운 면이 많습니다. 의도하지 않더라도 남들에게 상처를 주는 말을 하거나 너무나 솔직하고 직설적인 모습에 주변 사람들이 상처를 받는 경우가 많습니다.\n\n활동적인 것을 즐기지 않고 안정적인 것을 선호하다 보니 모험이나 새로운 것에 도전하는 것을 두려워하며 좋은 기회가 오더라도 고민하다가 놓치는 경우가 많습니다. 특히 섣불리 결정하지 않고 여러 시나리오를 생각하며 결정을 하다 보니 결정을 하게 되면 곧바로 실천하고 번복하지 않지만, 그 결정까지 시간이 소요돼 손해를 보는 경우도 많습니다.\n\n\n\n-직업\n금속, 금융, 제조, 공장, 의사, 약사, 의술 관련 업, 종교, 예술 분야, 시인, 작가, 연예인\n\n-장점\n현실적인 감각이 뛰어나 매우 실용적이라 최대의 가성비를 지녔습니다.\n날카로운 통찰력과 직관적으로 모든 상황을 잘 캐치합니다.\n\n-단점\n참다가 한번에 치명타를 날리는 타입으로 상대방에게 상처를 남깁니다.",
};

const birthJiji11:SajuMyungsikInfo = {
    classTitle: "술",
    classChineseChar: "戌",
    classOhang: "토",
    classEumyang: "양",
    classBearing: "",
    classColor: "황색",
    classSeason: "가을",
    description: "몸과 마음이 항상 분주함",
    characteristics: "성실, 호기심, 애정, 의리, 끈기, 극단적",
    detailTitle: "술토(戌土)란?",
    detailSubTitleMeaning: "지지 중 열한번째 지지인 개에 해당한다",
    detailSubTitleCharacteristics: "혼자있으면 외로움, 아닌척 하지만 은근 보수적 성향",
    detailResult:
        "술토는 특유의 친화력으로 대인관계가 좋고 주변 사람들과 사이가 좋습니다. 특히 사람을 만나는 모임이나 회의를 즐기기에 항상 바쁜 일상을 보내는 것이 특징입니다. \n\n모든 일에 호기심도 많고 관심도 많아 평소에 안 해본 것에 도전하는 것을 즐기는 편입니다.  특성으로 몸과 마음이 항상 분주하고 호기심이 많습니다. 또 모든것에 의심이 많고 보수적인 성향이 강합니다. 처음 그 문을 열기는 쉽지 않지만 한번 열고나면 신뢰하며 적극적입니다. \n\n술토의 기운이 강한 사람은 이중성도 강한 편입니다. 겉과 속이 완전히 다른 모습을 보이기 때문에 겉으로는 웃고 있을지라도 속으로는 실리를 챙기고 있을 수 있기 때문에 겉만 보고 판단하는것이 아닌 속까지 파악해야되는 경우가 많습니다.\n\n-직업\n분해, 조립, 수리, 가공업, 산업, 무기\n\n-장점\n기본적으로 성실하고 신념이 투철합니다. \n술토가 많으면 해외 유학, 상사원으로 나가면 크게 능력 발휘를 할 수 있습니다. \n\n-단점\n결실과 거리가 멀어 실속이 없는 편입니다.\n굴러들어 온 복을 자기 발로 걷어차 버리기도 합니다. \n인생의 길을 잃어 불안하고 공허해지곤 합니다.",
};

const birthJiji12:SajuMyungsikInfo = {
    classTitle: "해",
    classChineseChar: "亥",
    classOhang: "수",
    classEumyang: "양",
    classBearing: "",
    classColor: "검정색",
    classSeason: "겨울",
    description: "겉은 음의 기운이나 속은 양의 기운으로 외유내강형",
    characteristics: "총명, 예지력, 소심, 검소, 인자함",
    detailTitle: "해수(亥水)란?",
    detailSubTitleMeaning: "지지 중 열두번째 지지인 돼지에 해당한다",
    detailSubTitleCharacteristics: "친화력 끝판왕, 우직함 속 성실함",
    detailResult:
        "해수는 포근하고 온순하지만, 욕심이 많은 것이 특징입니다. 당장 자신에게 필요가 없더라도 일단 받고 보지만 정작 정리가 안돼 나중엔 자신이 갖고 있는지조차 모르는 경우가 많습니다. \n\n해수는 음의 기운이 강하기 때문에 정신적인 분야에서 두각을 보입니다. 종교나 교육, 복지 등에 관심이 있거나 정반대인 유흥에 관심이 많은 경우가 많습니다. \n\n대체로 머리가 좋아 수학, 과학분야는 물론 외국어를 익히는 능력도 뛰어난 편입니다. 다만, 의지가 약해 꼭 해야되는 경우가 아니면 먼저 움직이지 않아 주변에서 동기부여를 해주거나 자기 스스로 발전할 수 있는 태도를 갖추는것이 중요합니다.\n\n-직업\n가수, 정치인, 예술가, 연예인, 의사, 의료업, 해외 관련직\n\n-장점\n다른 사람과 베풀고 나눠 먹는 것을 즐깁니다. \n명석하여 수학, 과학, 외국어 습득 능력이 탁월합니다. \n특유의 통찰력과 예지력이 뛰어납니다. \n\n-단점\n금전관리를 잘 못 하여 소비가 많습니다.\n자기 주도적인 성향이 약해 실천 의지가 약합니다. ",
};

const birthMyOhang1:SajuMyungsikInfo = {
    classTitle: "나의오행",
    classChineseChar: "木",
    classOhang: "",
    classEumyang: "",
    classBearing: "",
    classColor: "",
    classSeason: "",
    description: "굵고 곧은 나무와 같이 생명을 상징\n끈질기고 활기찬 것이 특징",
    characteristics: "",
    detailTitle: "",
    detailSubTitleMeaning: "",
    detailSubTitleCharacteristics: "",
    detailResult: "",
};

const birthMyOhang2:SajuMyungsikInfo = {
    classTitle: "나의오행",
    classChineseChar: "火",
    classOhang: "",
    classEumyang: "",
    classBearing: "",
    classColor: "",
    classSeason: "",
    description: `타오르는 불꽃처럼 열정과 자신감이 가득\n 적극적이나 때론 다혈질적인 것이 특징`,
    characteristics: "",
    detailTitle: "",
    detailSubTitleMeaning: "",
    detailSubTitleCharacteristics: "",
    detailResult: "",
};

const birthMyOhang3:SajuMyungsikInfo = {
    classTitle: "나의오행",
    classChineseChar: "土",
    classOhang: "",
    classEumyang: "",
    classBearing: "",
    classColor: "",
    classSeason: "",
    description: `우직하고 끈기가 있으며 중립적인 성형이 강함\n 융합을 추구하지만, 때론 고집이 세 못 말린다`,
    characteristics: "",
    detailTitle: "",
    detailSubTitleMeaning: "",
    detailSubTitleCharacteristics: "",
    detailResult: "",
};

const birthMyOhang4:SajuMyungsikInfo = {
    classTitle: "나의오행",
    classChineseChar: "金",
    classOhang: "",
    classEumyang: "",
    classBearing: "",
    classColor: "",
    classSeason: "",
    description: `맺고 끊음이 확실하고 절제력이 있다\n 때론 칼날과 같이 날카로운 것이 특징`,
    characteristics: "",
    detailTitle: "",
    detailSubTitleMeaning: "",
    detailSubTitleCharacteristics: "",
    detailResult: "",
};

const birthMyOhang5:SajuMyungsikInfo = {
    classTitle: "나의오행",
    classChineseChar: "水",
    classOhang: "",
    classEumyang: "",
    classBearing: "",
    classColor: "",
    classSeason: "",
    description: "물과 같이 깊고 푸른 성향으로 총명하고\n지혜롭다. 속을 알 수 없는 경향이 많다",
    characteristics: "",
    detailTitle: "",
    detailSubTitleMeaning: "",
    detailSubTitleCharacteristics: "",
    detailResult: "",
};

export default SajuMyungsikData;
