import { ReactElement, useEffect } from "react";
import css from "./what_is_ohang_guide.module.css";
import { JeomsinPageUIProps } from "@Routes/PageRoutes";
import { JeomsinPageType } from "@Components/compose";

const OhangGuideData = {
  火: "타오르는 불꽃처럼 열정과 자신감이 가득\n적극적이나 때론 다혈질적인 것이 특징",
  木: "굵고 곧은 나무와 같이 생명을 상징\n끈질기고 활기찬 것이 특징",
  土: "우직하고 끈기가 있으며 중립적인 성형이 강함\n융합을 추구하지만, 때론 고집이 세 못 말린다",
  金: "맺고 끊음이 확실하고 절제력이 있다\n때론 칼날과 같이 날카로운 것이 특징",
  水: "물과 같이 깊고 푸른 성향으로 총명하고\n지혜롭다. 속을 알 수 없는 경향이 많다",
};

const Ohangs = {
  fire: "火",
  tree: "木",
  land: "土",
  gold: "金",
  water: "水",
} as const;

type Ohang = keyof typeof Ohangs;

const OhangDescription = {
  火: "타오르는 불꽃처럼 열정과 자신감이 가득\n적극적이나 때론 다혈질적인 것이 특징",
  木: "굵고 곧은 나무와 같이 생명을 상징\n끈질기고 활기찬 것이 특징",
  土: "우직하고 끈기가 있으며 중립적인 성형이 강함\n융합을 추구하지만, 때론 고집이 세 못 말린다",
  金: "맺고 끊음이 확실하고 절제력이 있다\n때론 칼날과 같이 날카로운 것이 특징",
  水: "물과 같이 깊고 푸른 성향으로 총명하고\n지혜롭다. 속을 알 수 없는 경향이 많다",
};

function OhangButton(ohang: Ohang): ReactElement {
  let classNm = "";
  let ohangNm: keyof typeof OhangDescription = "火";
  switch (ohang) {
    case "fire":
      classNm = `${css.red_colored}`;
      ohangNm = Ohangs[ohang];
      break;
    case "tree":
      classNm = `${css.blue_colored}`;
      ohangNm = Ohangs[ohang];
      break;
    case "land":
      classNm = `${css.yellow_colored}`;
      ohangNm = Ohangs[ohang];
      break;
    case "gold":
      classNm = `${css.gray_colored}`;
      ohangNm = Ohangs[ohang];
      break;
    case "water":
      classNm = `${css.dark_colored}`;
      ohangNm = Ohangs[ohang];
      break;
  }

  return (
    <div key={ohang} className={css.ohang_card_and_description_h_stack}>
      <div
        className={`${css.ohang_button_container} ${classNm} ${css.ohang_card_title}`}
      >
        {<span>{ohangNm}</span>}
      </div>
      <div className={css.ohang_descript_text_container}>
        <span className={css.ohang_description_text}>
          {OhangDescription[ohangNm]}
        </span>
      </div>
    </div>
  );
}

function WhatIsOhangGuide({ headerType }: JeomsinPageUIProps): ReactElement {
  useEffect(() => {
    headerType({
      type: JeomsinPageType.Sub,
      title: "음양 오행이란?",
      pageBackEvent: () => {
        window.history.back();
      },
    });
  }, [headerType]);

  const ohangTypes: Ohang[] = ["fire", "tree", "land", "gold", "water"];

  return (
    <div className={`header-type-sub ${css.wrapper}`}>
      <div className={`${css.ohang_v_stack}`}>
        <div className={`${css.height_250}`}>
          <img
            className={`${css.fill_width_and_height}`}
            src="images/ohang_detail_info_screen/3.0x/ohang.png"
            alt="ohang guide"
          />
          <div className={`${css.anchored_to_top_left}`}>
            <span className={css.font_blue}>상생 (相生)</span>
            <span className={css.font_red}>상극 (相克)</span>
          </div>
        </div>
        <div className={`${css.title}`}>
          <span className={`${css.bolded}`}>상세 </span>
          <span>해석</span>
        </div>
        {ohangTypes.map((ohang) => OhangButton(ohang))}
      </div>
    </div>
  );
}

export default WhatIsOhangGuide;
