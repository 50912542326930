import { useEffect, useState } from "react";
import { Box, Stack } from "@mui/material";

const DestinyChartGuide = (clickHandler: () => void, isShown: boolean) => {
  const guideImageWidth = 320;
  const guideImageSpacing = 20;

  const [currentGuidePosition, setCurrentGuidePosition] = useState(0);
  const [firstImagePositionX, setFirstImagePositionX] = useState<
    number | undefined
  >(undefined);

  useEffect(() => {
    const stack = document.querySelector(".scrollable");
    const scrollable = stack?.querySelector(".guide-image-1");

    const scroll = (e: any) => {
      if (scrollable) {
        if (firstImagePositionX) {
          const rect = scrollable.getBoundingClientRect();
          const position = Math.round(
            Math.abs(
              (rect.left - firstImagePositionX) /
                (guideImageWidth + guideImageSpacing)
            )
          );

          if (currentGuidePosition === position) {
          } else {
            console.log("Set!");
            setCurrentGuidePosition(position);
          }
        } else {
          const rect = scrollable.getBoundingClientRect();
          setFirstImagePositionX(rect.left);
        }
      }
    };

    stack?.addEventListener("scroll", scroll, false);

    return () => {
      stack?.removeEventListener("scroll", scroll, false);
    };
  });

  return isShown ? (
    <Stack
      sx={{
        position: "absolute",
        top: "0%",
        left: "0%",
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.62)",
        alignItems: "center",
        justifyContent: "center",
        zIndex:9999
      }}
      spacing="25px"
    >
      <Box sx={{
        position: "absolute",
        left: "calc(180px + 50%)",
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
      }}>
      <Box sx={{ width: "100%", position: "relative" }}>
        <Box
          sx={{
            width: `${guideImageWidth}px`,
            height: `${guideImageWidth * (556 / 320)}px`,
          }}
        />
        <Box
          sx={{
            width: `${guideImageWidth}px`,
            height: `${guideImageWidth * (556 / 320)}px`,
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Stack
            className="scrollable"
            direction="row"
            style={{
              overflow: "scroll",
              scrollSnapType: "x mandatory",
              msOverflowStyle: "none",
              scrollbarWidth: "none",
              width: "100%",
              height: "100%",
            }}
            spacing={`${guideImageSpacing}px`}
          >
            {["1", "2", "3", "4", "5"]
              .map((num) =>
                "images/saju_myungsik_guide/3.0x/guide"
                  .concat(num)
                  .concat(".png")
              )
              .map((src, index, _) => (
                <img
                  key={src}
                  className={`guide-image-${index + 1}`}
                  style={{
                    scrollSnapAlign: "start",
                    objectFit: "contain",
                    width: "100%",
                    height: "100%",
                  }}
                  src={src}
                  alt={`Guide ${index + 1}`}
                />
              ))}
          </Stack>
        </Box>
      </Box>

      <Box
        style={{
          display: "inline-flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Stack direction="row" spacing="7px" sx={{ padding:"10px" }} >
          {[0, 1, 2, 3, 4].map((index) => (
            <Box key={`Position${index}`} style={{ position: "relative" }}>
              <Box
                sx={{
                  width: "10px",
                  height: "10px",
                  borderRadius: "500px",
                  borderColor: "white",
                  borderWidth: "1px",
                  borderStyle: "solid",
                }}
              />
              <Box
                sx={{
                  width: "10px",
                  height: "10px",
                  borderRadius: "500px",
                  backgroundColor:
                    currentGuidePosition === index ? "yellow" : "clear",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              />
            </Box>
          ))}
        </Stack>
      </Box>
      <button
        style={{
          outline: "none",
          border: "none",
          backgroundColor: "transparent",
        }}
        onClick={() => {
          setCurrentGuidePosition(0);
          clickHandler();
        }}
      >
        <span className="ts20 color-white bold6">건너 뛰기</span>
      </button>
      </Box>
    </Stack>
  ) : (
    <></>
  );
};

export default DestinyChartGuide;
