import {
  JeomsinRequest,
  JeomsinDomain,
  JeomsinEndPoint,
  RequestProps,
  JeomsinMethod,
} from "@Network/network";

interface Response {
  drmwork_data: DrmworkData;
}

interface DrmworkData {
  menu1: Menu1;
}

interface Menu1 {
  title: string;
  category1: Category1;
  category2: Category2;
}

interface Category1 {
  jiji: string;
  text: string;
}

interface Category2 {
  text0: string;
  text1: string;
  text2: string;
  text3: string;
  text4: string;
}

const apiGetDayJiji2022 = async (
  cate: number,
  depth: number
): Promise<Menu1 | undefined> => {
  let request: RequestProps = {
    method: JeomsinMethod.Post,
    domain: JeomsinDomain.JeomsinXml,
    endpoint: JeomsinEndPoint.GetDayJiji2022,
    params: {
      cate: cate,
    },
  };

  let result: Response | undefined;
  try {
    result = (await JeomsinRequest(request)) as Response;
  } catch (e) {
    console.log(`Error while fetching ${e}`);
    return undefined;
  }

  return result?.drmwork_data.menu1;
};

export default apiGetDayJiji2022;
