import { Box, Stack } from "@mui/material";
import { getGroupName } from "@Storage/data_model/relation_info_model";
import { UserInfo } from "@Storage/data_types";
import { useEffect, useRef, useState } from "react";

const IosStylePicker = (
  data: UserInfo[],
  selectionHandler: (selection: UserInfo) => void,
  prepareUI: boolean
) => {
  const CARD_HEIGHT = 25;
  const SPACING = 0;

  const [selectionPosition, setSelectionPosition] = useState(0);
  const [selected, setSelected] = useState(0);

  const placeholder = (
    <div
      style={{
        height: `${CARD_HEIGHT}px`,
      }}
    />
  );

  const spacing = <div style={{ width: "100%", height: `${SPACING}px` }} />;

  const displayable = data.map((value, index) => {
    const userInfo = value;
    const distanceFromSelected = index - selectionPosition;
    let rotationDeg = -30 * distanceFromSelected;
    let scale = 1 / (Math.abs(distanceFromSelected) / 10 + 1);

    const text = userInfo.name + " (" +getGroupName(userInfo.group_type ?? '0') + ")";

    if (rotationDeg < -90) {
      rotationDeg = -90;
    } else if (rotationDeg > 90) {
      rotationDeg = 90;
    }

    if (selected === index) {
      rotationDeg = 0;
    }

    const yOffset = distanceFromSelected * (CARD_HEIGHT / 3);

    return (
      <div key={index} style={{ width: "100%" }}>
        <div
          style={{
            position: "relative",
            scrollSnapAlign: "center none",
            height: `${CARD_HEIGHT}px`,
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "50%",
              transform: "translateY(-50%)",
              width: "100%",
            }}
          >
            <div
              style={{
                transform: `rotateX(${rotationDeg}deg)`,
              }}
              className={selected === index ? "selected" : "unselected"}
            >
              <div
                style={{
                  transform: `translateY(${yOffset}px)`,
                }}
              >
                <div style={{ transform: `scale(${scale})` }}>
                  <span>{text}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        {spacing}
      </div>
    );
  });

  const scrollRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleScroll = () => {
      if (scrollRef.current) {
        const scrollAmount = scrollRef.current.scrollTop;
        const positioning = scrollAmount / (CARD_HEIGHT + SPACING);
        const index = Math.round(positioning);
        setSelectionPosition(positioning);

        if (selected !== index) {
          setSelected(index);
          selectionHandler(data[index]);
        }
      }
    };

    const scrollContainer = scrollRef.current;
    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener("scroll", handleScroll);
      }
    };
  }, [selected, selectionPosition, data, selectionHandler]);

  useEffect(() => {
    if (prepareUI === false) {
      return;
    }

    const scrollContainer = scrollRef.current;
    if (scrollContainer) {
      const scrollAmount = scrollContainer.scrollTop;
      const positioning = scrollAmount / (CARD_HEIGHT + SPACING);
      const index = Math.round(positioning);
      const disparity = Math.abs(selected - index);

      if (disparity >= 1) {
        scrollContainer.scrollTo({
          top: selected * (CARD_HEIGHT + SPACING),
        });
      }
    }
  }, [prepareUI]);

  return (
    <div style={{ position: "relative" }}>
      <style>
        {`
          .unselected, .selected {
            transition: opacity 0.1s ease;
          }

          .selected {
            opacity: 1;
          }

          .unselected {
            opacity: 0.5;
          }
          `}
      </style>
      <div
        ref={scrollRef}
        style={{
          width: "100%",
          scrollSnapType: "y mandatory",
          height: "175px",
          overflowY: "scroll",
          textAlign: "center",
          msOverflowStyle: "none",
          scrollbarWidth: "none",
        }}
      >
        {placeholder}
        {spacing}
        {placeholder}
        {spacing}
        {placeholder}
        {spacing}
        {displayable}
        {placeholder}
        {spacing}
        {placeholder}
        {spacing}
        {placeholder}
      </div>
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "0%",
          width: "100%",
          height: `${CARD_HEIGHT + SPACING + 10}px`,
          transform: "translateY(-50%)",
          backgroundColor: "rgba(0, 0, 0, 0.05)",
          borderRadius: "5px",
          userSelect: "none",
          pointerEvents: "none",
        }}
      />
    </div>
  );
};

export default IosStylePicker;
