import { FC } from "react";
import css from "./counselor_view.module.css"
import { Box, Stack } from "@mui/material";
import { CounselorInfo, Counselor } from "@Network/request/api_get_counselor_new_v3";

// export interface CounselorInfo {
//   mem_nick: string;
//   use_count: string;
//   coupon_check_state: boolean;
//   is_tab_mental_new: boolean;
// }

// export interface Counselor {
//   idx: number;
//   MENU_ID: number;
//   CO_NO: number;
//   CO_Name: string;
//   CO_Url: string;
//   CO_History: string;
//   CO_Info: string;
//   ARS_NO: string;
//   ARS_NO2: string;
//   yn_freecall: string;
//   freecall_cnt: string,
//   conn_state: number;
//   CO_Field: string;
//   recomm: string;
//   yn_popular: boolean,
//   price: number,
//   CO_badge: number;
//   CO_gen: string;
//   rv_cnt: number;
//   r_cnt: number,
//   yn_new: string;
//   push_state: string;
//   coupon_time: string;
//   yn_favorite: string;
//   download_able: string;
//   coupon_point: string;
//   counselor_coupon_no: string;
//   coupon_s_date: string;
//   coupon_e_date: string;
//   coupon_name: string;
//   rv_avg: string,
//   score1: number;
//   score2: number;
//   score3: number;
//   score4: number;
//   score5: number;
//   rv_avg_v2: string,
//   total_counseling_cnt: number;
//   total_time: string;
//   re_counseling_rate: string;
//   ars_record_sample_url: string;
//   ars_greeting: string;
// }

type CounselorProps = {
  conunselorInfo: CounselorInfo
  counselor: Counselor,
  onClick: (counselor: Counselor) => void
}


const CounselorView: FC<CounselorProps> = ( props ) => {
  const hashTags = props.counselor.CO_History.split("|");
  let payCounselBtnTextTitle = "바로 상담";
  let counselStateIcon = "";
  let counselButtonDisabled = false;

  switch (props.counselor.conn_state ?? 0) {
    case 1:
      //비회원 로직
      if (props.counselor.yn_freecall 
        && props.counselor.yn_freecall != "" 
        && props.counselor.yn_freecall == 'Y') 
      {
        payCounselBtnTextTitle = '3분 무료 상담';
      }
      break;
    case 2:
      payCounselBtnTextTitle = '부재중';
      counselStateIcon = css.state_consulting
      counselButtonDisabled = true;
      break;
    case 3:
      payCounselBtnTextTitle = '상담중';
      counselStateIcon = css.state_consulting
      counselButtonDisabled = true;
      break;
    default:
      break;
  }

  return (
    <Stack className={css.counselor_wrap}>
      <div className={css.counselor_img_wrap}>
        <img src={props.counselor.CO_Url} alt="Counselor Profile" />
        <div className={css.state_wrap}>
          <div className={css.state_circle_wrap}>
            <span className={`${css.state_circle} ${counselStateIcon}`}></span>
          </div>
        </div>
      </div>
      <div className={css.counselor_info_wrap}>
        <span className={css.counselor_name}>
          {props.counselor.CO_Name}
        </span>
        <span className={css.counselor_number}>
          No.{props.counselor.CO_NO}
        </span>
      </div>
      <span className={css.counselor_tags}>
        {hashTags.map((hashTag, index, _) => (
          <>
            #
            {`${hashTag}${
              index === hashTags.length - 1 ? "" : " "
            }`}
          </>
        ))}
      </span>
      <span className={css.counselor_discription}>
        {props.counselor.CO_Info}
      </span>
      <button className={css.counseling_button} disabled={counselButtonDisabled}>{payCounselBtnTextTitle}</button>
    </Stack>
  );
}

export default CounselorView;