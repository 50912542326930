import { FC } from "react";
import css from "./row_of_texts.module.css";
import { ReactElement } from "react";

interface RowOfTextsProps {
  contents: ReactElement[];
  dividerOpacity?: string;
  dividerColor?: string;
  dividerRelativeHeight?: string;
  showDivider?: boolean;
  className?: string;
}

function hexToRGBA(hex = "", alpha = 1) {
  const hexValue = hex.replace(/^#/, "");
  let r = 0,
    g = 0,
    b = 0,
    a = alpha;

  if ([3, 4].includes(hexValue.length)) {
    r = parseInt(hexValue[0] + hexValue[0], 16);
    g = parseInt(hexValue[1] + hexValue[1], 16);
    b = parseInt(hexValue[2] + hexValue[2], 16);
    a =
      hexValue.length === 4
        ? parseInt(hexValue[3] + hexValue[3], 16) / 255
        : alpha;
  } else if ([6, 8].includes(hexValue.length)) {
    r = parseInt(hexValue.slice(0, 2), 16);
    g = parseInt(hexValue.slice(2, 4), 16);
    b = parseInt(hexValue.slice(4, 6), 16);
    a =
      hexValue.length === 8 ? parseInt(hexValue.slice(6, 8), 16) / 255 : alpha;
  }

  return `rgba(${r}, ${g}, ${b}, ${a})`;
}

function calculateCenterY(relativeHeight: number) {
  if (relativeHeight === 1) return 0;
  return (1 / relativeHeight) * 0.5 - 0.5;
}

function percentToDecimal(percentStr: string) {
  if (typeof percentStr !== "string" || !percentStr.endsWith("%")) {
    throw new Error('Input must be a percentage string ending with "%"');
  }

  // Remove the "%" sign and convert to number
  const value = parseFloat(percentStr.slice(0, -1));

  // Divide by 100 to get the decimal equivalent
  return value / 100;
}

const RowOfTexts: FC<RowOfTextsProps> = ({
  contents,
  dividerOpacity = "50%",
  dividerColor = "#000000",
  dividerRelativeHeight = "50%",
  showDivider = false,
  className,
}) => {
  return (
    <div className={`${className} ${css.container}`}>
      {contents.map((content, index) => (
        <div className={css.item} key={index}>
          {content}
          {showDivider && index !== contents.length - 1 && (
            <div className={css.divider}>
              <div
                style={{
                  height: dividerRelativeHeight,
                  width: "100%",
                  backgroundColor: hexToRGBA(
                    dividerColor,
                    percentToDecimal(dividerOpacity)
                  ),
                  position: "relative",
                  transform: `translateY(${
                    calculateCenterY(percentToDecimal(dividerRelativeHeight)) *
                    100
                  }%)`,
                }}
              ></div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default RowOfTexts;
