type groupInfo = {
  groupName:string,
  groupNumber:string
}

const gourps:groupInfo[] = [
  {groupName:'본인', groupNumber:'0'},
  {groupName:'아버지', groupNumber:'1'},
  {groupName:'어머니', groupNumber:'2'},
  {groupName:'배우자', groupNumber:'3'},
  {groupName:'아들', groupNumber:'4'},
  {groupName:'딸', groupNumber:'5'},
  {groupName:'형제', groupNumber:'6'},
  {groupName:'자매', groupNumber:'7'},
  {groupName:'애인', groupNumber:'8'},
  {groupName:'동기', groupNumber:'9'},
  {groupName:'친구', groupNumber:'10'},
  {groupName:'기타', groupNumber:'11'},
  {groupName:'선택안함', groupNumber:'12'},
  {groupName:'할머니', groupNumber:'13'},
  {groupName:'할아버지', groupNumber:'14'},
  {groupName:'선배', groupNumber:'15'},
  {groupName:'후배', groupNumber:'16'}
];

export const getGroupName = (groupNumber:string) => {
  const result = gourps.filter((value) => 
    value.groupNumber == groupNumber
  );

  return result[0].groupName;
}

export const familyTitle = [
  ['할머니', '13'],
  ['할아버지', '14'],
  ['아버지', '1'],
  ['어머니', '2'],
  ['배우자', '3'],
  ['아들', '4'],
  ['딸', '5'],
  ['형제', '6'],
  ['자매', '7'],
];
export const companyTitle = [
  ['선배', '15'],
  ['동기', '9'],
  ['후배', '16'],
];
export const relationshipTitle = [
  ['애인', '8'],
  ['친구', '10'],
];
export const noneTitle = [
  ['선택안함', '12'],
  ['기타', '11'],
];

export const relationGroups = [
    [
      '본인',
      '0',
  ],
    [
      '아버지',
      '1',
  ],
    [
      '어머니',
      '2',
  ],
    [
      '배우자',
      '3',
  ],
    [
      '아들',
      '4',
  ],
    [
      '딸',
      '5',
  ],
    [
      '형제',
      '6',
  ],
    [
      '자매',
      '7',
  ],
    [
      '애인',
      '8',
  ],
    [
      '동기',
      '9',
  ],
    [
      '친구',
      '10',
  ],
    [
      '기타',
      '11',
  ],
    [
      '선택안함',
      '12',
  ],
    [
      '할머니',
      '13',
  ],
    [
      '할아버지',
      '14',
  ],
    [
      '선배',
      '15',
  ],
    [
      '후배',
      '16',
  ],
  ];

export const relationAlls = [
  '본인',
  '아버지',
  '어머니',
  '배우자',
  '아들',
  '딸',
  '형제',
  '자매',
  '애인',
  '동료',
  '친구',
  '기타',
  '선택안함',
  '할머니',
  '할아버지',
  '선배',
  '후배',
];

export const relations = [
  '아버지',
  '어머니',
  '배우자',
  '아들',
  '딸',
  '형제',
  '자매',
  '애인',
  '동료',
  '친구',
  '기타',
  '선택안함',
  '할머니',
  '할아버지',
  '선배',
  '후배',
];