import '../css/saju_info_modify.css';

import Box from '@mui/material/Box';
import { JeomsinInputBox, InputBoxType, ExtraButtonType, BottomNavigationType, JeomsinPageType } from '@Components/compose';
import { useEffect, useRef, useState } from 'react';
import { JeomsinPageUIProps } from '@Routes/PageRoutes';
import { UserInfo } from '@Storage/data_types';
import apiInfoNew from '@Network/request/api_info_new';
import SajuInfo from '@Storage/data_model/saju_info_model';
import CookieManager from '@Storage/cookie_manager';
import CookieKeys from '@Storage/cookie_keys';
import { useNavigate } from 'react-router';

function SajuInfoModify({ headerType,bottomNavParam }: JeomsinPageUIProps) {
  const navigate = useNavigate();
  const isNameValidate = useRef(false);
  const isGenderValidate = useRef(false);
  const isBirthDayValidate = useRef(false);
  const isBirthTimeValidate = useRef(false);
  const isRelationValidate = useRef(false);

  const calendarType = useRef(-1);
  const isLeapMonth = useRef(false);

  const [isButtonDisabled, setButtonDisabled] = useState(true);

  const [userInfo, setUserInfo] = useState<UserInfo>({
    name: "",
    gender: "",
    birthYear: "",
    birthMonth: "",
    birthDay: "",
    birthTime: "",
    birthMinute: "",
    calendarType: -1,
    calendarTypeText: "",
    isLeapMonth: false,
    isLeapMonthText: "",
  });

  useEffect(() => {
    headerType({type: JeomsinPageType.Sub, title:"사주정보 입력"});
    bottomNavParam({
      type:BottomNavigationType.Button, 
      title:"저장하기",
      onClick() { 
        saveNewSajuInfo();
        const list = CookieManager.GetJSON(CookieKeys.user_info_list);
        console.log(`List.length : ${list.length}`)
        navigate(-1);
      },
      buttonDisable: isButtonDisabled
    });
  }, [isButtonDisabled]);

  const checkEnableSave = () => {
    console.log(`test ${(isNameValidate.current 
      && isGenderValidate.current 
      && isBirthDayValidate.current 
      && isBirthTimeValidate.current 
      && isRelationValidate.current)}`)
    if(
      isNameValidate.current 
      && isGenderValidate.current 
      && isBirthDayValidate.current 
      && isBirthTimeValidate.current 
      && isRelationValidate.current
    ){
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }

  const saveNewSajuInfo = async () => {
    const response = await apiInfoNew(userInfo);
    const mySajuInfo = SajuInfo(response);

    userInfo.saju_info = mySajuInfo;

    const userList:UserInfo[] = CookieManager.GetJSON(CookieKeys.user_info_list);
    userList.push(userInfo);

    CookieManager.SetJSON(CookieKeys.user_info_list, userList);
  }

  //material
  return (
    <Box className="saju-info-modify-wrap header-type-sub">
      <Box className="main-contents">
        <Box className="mb40">
          <JeomsinInputBox
            type={InputBoxType.Text}
            label='이름'
            helpText="한글 최대 6자"
            errorText='이름은 최대 6자까지 입력 가능합니다.'
            maxLength={6}
            inputRegexp={/^[ㄱ-ㅎ|가-힣]/}
            resultRegexp={/^[가-힣ᆞㆍᆢ]+$/}
            onValidate={ (isValidate, value) => {
              isNameValidate.current = isValidate;
              if(isValidate){
                setUserInfo({
                  name: value,
                  gender: userInfo.gender,
                  birthYear: userInfo.birthYear,
                  birthMonth: userInfo.birthMonth,
                  birthDay: userInfo.birthDay,
                  birthTime: userInfo.birthTime,
                  birthMinute: userInfo.birthMinute,
                  calendarType: userInfo.calendarType,
                  calendarTypeText: userInfo.calendarTypeText,
                  isLeapMonth: userInfo.isLeapMonth,
                  isLeapMonthText: userInfo.isLeapMonthText
                });

                checkEnableSave()
              }
            }}
          />
        </Box>
        <Box className="mb40">
          <JeomsinInputBox
            type={InputBoxType.Select}
            label='성별'
            onSelectBoxClick={ (event) => {
              let selectedVal = event.currentTarget.value;
              if(selectedVal) {
                setUserInfo({
                  name: userInfo.name,
                  gender: selectedVal,
                  birthYear: userInfo.birthYear,
                  birthMonth: userInfo.birthMonth,
                  birthDay: userInfo.birthDay,
                  birthTime: userInfo.birthTime,
                  birthMinute: userInfo.birthMinute,
                  calendarType: userInfo.calendarType,
                  calendarTypeText: userInfo.calendarTypeText,
                  isLeapMonth: userInfo.isLeapMonth,
                  isLeapMonthText: userInfo.isLeapMonthText
                });

                isGenderValidate.current = true;
                checkEnableSave();
              } else {
                isGenderValidate.current = false;
              }
            }}
          />
        </Box>
        <Box className="mb40">
          <JeomsinInputBox
            type={InputBoxType.Date}
            label='생년월일'
            subLabel='사주분석은 만 나이로 적용돼요.'
            helpText='생년월일 8자리'
            errorText='다시 입력해 주세요.'
            hintText='19950505'
            onValidate={ (isValidate, value, extraValue) => {
              if(extraValue) {
                calendarType.current = extraValue["calendarType"];
                if(calendarType.current == 0) { //양력
                  isLeapMonth.current = false; //평달
                } else { //음력
                  isLeapMonth.current = extraValue["isLeapMonth"];
                }
              }
              
              if(isValidate && calendarType.current > -1) { //생년월일 입력 및 양력,음력 선택
                const ymd = value.split(".");
                setUserInfo({
                  name: userInfo.name,
                  gender: userInfo.gender,
                  birthYear: ymd[0],
                  birthMonth: ymd[1],
                  birthDay: ymd[2],
                  birthTime: userInfo.birthTime,
                  birthMinute: userInfo.birthMinute,
                  calendarType: calendarType.current,
                  calendarTypeText: (calendarType.current == 0 ? "양력" : "음력"),
                  isLeapMonth: isLeapMonth.current,
                  isLeapMonthText: (isLeapMonth.current ? "윤달" : "평달"),
                });


                isBirthDayValidate.current = true;
                checkEnableSave();
              } else {
                isBirthDayValidate.current = false;
              }
            }}
          />
        </Box>
        <Box className="mb40">
          <JeomsinInputBox
            type={InputBoxType.Time}
            label='태어난 시간'
            onValidate={ (isValidate, value) => {
              isBirthTimeValidate.current = isValidate;
              const timeAndMinute = value.split(":");

              if(isValidate){
                setUserInfo({
                  name: userInfo.name,
                  gender: userInfo.gender,
                  birthYear: userInfo.birthYear,
                  birthMonth: userInfo.birthMonth,
                  birthDay: userInfo.birthDay,
                  birthTime: (timeAndMinute.length < 2) ? "0" : timeAndMinute[0],
                  birthMinute: (timeAndMinute.length < 2) ? "0" : timeAndMinute[1],
                  calendarType: userInfo.calendarType,
                  calendarTypeText: userInfo.calendarTypeText,
                  isLeapMonth: userInfo.isLeapMonth,
                  isLeapMonthText: userInfo.isLeapMonthText
                });

                checkEnableSave();
              }
            }}
          />
        </Box>
        <Box className="mb40">
          <JeomsinInputBox
            type={InputBoxType.Button}
            label='관계'
            onReturnValue={(value) => {
              isRelationValidate.current = true;
              setUserInfo({
                name: userInfo.name,
                gender: userInfo.gender,
                birthYear: userInfo.birthYear,
                birthMonth: userInfo.birthMonth,
                birthDay: userInfo.birthDay,
                birthTime: userInfo.birthTime,
                birthMinute: userInfo.birthMinute,
                calendarType: userInfo.calendarType,
                calendarTypeText: userInfo.calendarTypeText,
                isLeapMonth: userInfo.isLeapMonth,
                isLeapMonthText: userInfo.isLeapMonthText,
                group_type:value
              });
              checkEnableSave();
            }}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default SajuInfoModify;
