import "./App.css";
import Box from "@mui/material/Box";
import JeomsinAsidePage from "@Pages/aside_page/views/aside_page";
import {
  JeomsinHeader,
  JeomsinPageType,
  JeomsinBottomNavigation,
  BottomNavigationType,
  BottomNavigationProps,
  HeaderProps,
} from "@Components/compose";
import { SetStateAction, useEffect, useRef, useState } from "react";
import PageRoutes from "@Routes/PageRoutes";
import { BrowserRouter } from "react-router-dom";
import apiGetSNSContentCount from "@Network/request/api_get_sns_content_count";
import useSharedInfoStore from "@Network/shared_info";
import apiGetTalismanPage from "@Network/request/api_get_talisman_page";

function App() {
  const [headerType, setHeaderType] = useState<HeaderProps>({
    type: JeomsinPageType.Fortune,
  });
  const [bottomNavParam, setBottomNavParam] = useState<BottomNavigationProps>({
    type: BottomNavigationType.Tab,
  });

  const { setSnsCount, setTalismanPageResponse } = useSharedInfoStore();

  useEffect(() => {
    const initialize = async () => {
      const newSNSCount = await apiGetSNSContentCount();
      const newTalismanPageResponse = await apiGetTalismanPage();
      setSnsCount(newSNSCount);
      setTalismanPageResponse(newTalismanPageResponse);
    };

    initialize();
  }, []);

  return (
    <Box className="main-container">
      <JeomsinAsidePage />
      <Box className="main-page">
        <BrowserRouter>
          <JeomsinHeader
            type={headerType.type}
            title={headerType.title}
            pageBackEvent={headerType.pageBackEvent}
            fontSizeButtonPressed={headerType.fontSizeButtonPressed}
            shareButtonPressed={headerType.shareButtonPressed}
          />
          <PageRoutes
            headerType={setHeaderType}
            bottomNavParam={setBottomNavParam}
          />
          <JeomsinBottomNavigation
            type={bottomNavParam.type}
            title={bottomNavParam.title}
            boldedPart={bottomNavParam.boldedPart}
            buttonDisable={bottomNavParam.buttonDisable}
            hide={bottomNavParam.hide}
            onClick={bottomNavParam.onClick}
          />
        </BrowserRouter>
      </Box>
    </Box>
  );
}

export default App;
