import "./bottom_navigation.css"
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Box from "@mui/material/Box";
import BottomButton from "../../basic/bottom_button/bottom_button";

export type BottomNavigationProps = {
    type : BottomNavigationType;
    title? : string;
    boldedPart?: string;
    buttonDisable? : boolean;
    hide? : boolean;
    onClick? : () => void;
}

// Page 추가 시 PageName 추가
enum BottomNavigationType {
    Tab,
    Button,
    None,
}

const JeomsinBottomNavigation:React.FC<BottomNavigationProps>  = ( 
    props
) => {
    console.log(`JeomsinBottomNavigation : ${props.hide}`)
    return (
        <Box>
            {BottomType(props.type, props.title, props.boldedPart, props.buttonDisable, props.hide, props.onClick)}
        </Box>
    );
}

const space_text = "_";

const BottomType = (
    type: BottomNavigationType,
    buttonTitle?: string,
    boldedPart?: string,
    buttonDisable?: boolean,
    hide?:boolean,
    onClick?: () => void
) => {
    var returnValue: JSX.Element;
  
    switch (type) {
        case BottomNavigationType.Tab:
            returnValue = (
                <BottomNavigation className={"botttom-nav" + (hide ? space_text + "none" : "")} showLabels>
                    <BottomNavigationAction label={<Box className="tab-name">운세</Box>} icon={<img src='img/bottom_tab.png'></img>} />
                    <BottomNavigationAction label={<Box className="tab-name">타로</Box>} icon={<img src='img/bottom_tab.png'></img>} />
                    <BottomNavigationAction label={<Box className="tab-name">상담</Box>} icon={<img src='img/bottom_tab.png'></img>} />
                    <BottomNavigationAction label={<Box className="tab-name">점신몰</Box>} icon={<img src='img/bottom_tab.png'></img>} />
                </BottomNavigation>
            );
            break;
        case BottomNavigationType.Button:
            console.log(`hide : ${hide}`);
            returnValue = (
                <BottomButton 
                    className={(hide ? "none" : "")} 
                    title={buttonTitle ? buttonTitle : ""} 
                    boldedPart={boldedPart} 
                    disable={buttonDisable} 
                    onClick={onClick}  
                />
            );
            break;
        case BottomNavigationType.None:
            returnValue = (
                <></>
            );
            break;
        default:
            returnValue = (<span></span>);
            break;

    }

    return returnValue;
};

export { 
    JeomsinBottomNavigation,
    BottomNavigationType
}
